var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./no.en.core.js");
'use strict';var p1,Tpa,q1,u1,w1;p1=function(a,b,c,d,e){this.pattern=a;this.Ec=b;this.T=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264};Tpa=function(a,b){return new p1(a,b,null,null,null)};q1=function(a,b){$CLJS.Cd($CLJS.Wg($CLJS.q(a)),b)||$CLJS.zh.j(a,$CLJS.Yd,b)};$CLJS.r1=function(a){if(null==a||"string"===typeof a)return a;if(a instanceof $CLJS.M||a instanceof $CLJS.r){var b=$CLJS.de(a);return $CLJS.n(b)?[b,"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)}return null};
u1=function(a){a=$CLJS.y($CLJS.Ne.g(s1,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);q1($CLJS.t1,e);d+=1}else if(a=$CLJS.y(a))b=a,$CLJS.vd(b)?(a=$CLJS.ic(b),c=$CLJS.jc(b),b=a,e=$CLJS.D(a),a=c,c=e):(e=$CLJS.z(b),q1($CLJS.t1,e),a=$CLJS.B(b),b=null,c=0),d=0;else break};
w1=function(a){a=$CLJS.y($CLJS.Ne.g(s1,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);q1(v1,e);d+=1}else if(a=$CLJS.y(a))b=a,$CLJS.vd(b)?(a=$CLJS.ic(b),c=$CLJS.jc(b),b=a,e=$CLJS.D(a),a=c,c=e):(e=$CLJS.z(b),q1(v1,e),a=$CLJS.B(b),b=null,c=0),d=0;else break};var v1,y1;$CLJS.g=p1.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.S:null){case "pattern":return this.pattern;case "replacement":return this.Ec;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.ih(b,function(d){return $CLJS.ih(b,$CLJS.lh,""," ","",c,d)},"#inflections.core.Rule{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hz,this.pattern],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iz,this.Ec],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.gs(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hz,$CLJS.iz],null),$CLJS.n(this.G)?$CLJS.oc(this.G):$CLJS.De())};$CLJS.g.O=function(){return this.T};
$CLJS.g.ha=function(){return 2+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1327743444^$CLJS.Mc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.pattern,b.pattern)&&$CLJS.E.g(this.Ec,b.Ec)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Cd(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.iz,null,$CLJS.hz,null],null),null),b)?$CLJS.jk.g($CLJS.Lb($CLJS.Wf.g($CLJS.N,this),this.T),b):new p1(this.pattern,this.Ec,this.T,$CLJS.Be($CLJS.jk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.S:null){case "pattern":case "replacement":return!0;default:return $CLJS.Cd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ce.g?$CLJS.ce.g($CLJS.hz,b):$CLJS.ce.call(null,$CLJS.hz,b))?new p1(c,this.Ec,this.T,this.G,null):$CLJS.n($CLJS.ce.g?$CLJS.ce.g($CLJS.iz,b):$CLJS.ce.call(null,$CLJS.iz,b))?new p1(this.pattern,c,this.T,this.G,null):new p1(this.pattern,this.Ec,this.T,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.bf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Sf($CLJS.hz,this.pattern),new $CLJS.Sf($CLJS.iz,this.Ec)],null),this.G))};
$CLJS.g.P=function(a,b){return new p1(this.pattern,this.Ec,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.ud(b)?this.ma(null,$CLJS.hd(b,0),$CLJS.hd(b,1)):$CLJS.bb($CLJS.mb,this,b)};var s1=function s1(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return s1.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};s1.l=function(a){return $CLJS.cf.g(function(b){return $CLJS.Ne.g(Tpa,b)},$CLJS.Xr(2,2,a))};s1.A=0;s1.B=function(a){return this.l($CLJS.y(a))};
$CLJS.x1=$CLJS.Se(new $CLJS.Rg(null,new $CLJS.h(null,81,["gossip",null,"sadness",null,"space",null,"happiness",null,"blood",null,"silver",null,"cotton",null,"species",null,"mist",null,"paper",null,"education",null,"wine",null,"up",null,"food",null,"sugar",null,"gold",null,"literature",null,"pork",null,"lightning",null,"sheep",null,"shopping",null,"fresh",null,"news",null,"pepper",null,"experience",null,"milk",null,"honey",null,"oxygen",null,"fiction",null,"luck",null,"vinegar",null,"coffee",null,
"ground",null,"peanut",null,"confusion",null,"tennis",null,"power",null,"meat",null,"butter",null,"music",null,"luggage",null,"love",null,"grass",null,"knowledge",null,"time",null,"cheese",null,"jam",null,"thunder",null,"electricity",null,"gum",null,"sunshine",null,"history",null,"snow",null,"money",null,"wool",null,"rice",null,"series",null,"liquid",null,"equipment",null,"pressure",null,"oil",null,"information",null,"steam",null,"chewing",null,"petrol",null,"research",null,"patience",null,"toothpaste",
null,"speed",null,"entertainment",null,"wood",null,"tea",null,"art",null,"washing",null,"forgiveness",null,"traffic",null,"alcohol",null,"ice",null,"homework",null,"fish",null,"air",null],null),null));$CLJS.t1=$CLJS.Se($CLJS.xf);v1=$CLJS.Se($CLJS.xf);y1=$CLJS.Se(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.bb($CLJS.mb,$CLJS.Raa,0<b.length?new $CLJS.w(b.slice(0),0,null):null)}());
u1($CLJS.H([/$/i,"s",/s$/i,"s",/(ax|test)is$/i,"$1es",/(octop|vir)us$/i,"$1i",/(alias|status)$/i,"$1es",/(bu)s$/i,"$1ses",/(buffal|tomat)o$/i,"$1oes",/([ti])um$/i,"$1a",/sis$/i,"ses",/(?:([^f])fe|([lr])f)$/i,"$1$2ves",/(hive)$/i,"$1s",/([^aeiouy]|qu)y$/i,"$1ies",/(x|ch|ss|sh)$/i,"$1es",/(matr|vert|ind)(?:ix|ex)$/i,"$1ices",/([m|l])ouse$/i,"$1ice",/^(ox)$/i,"$1en",/(quiz)$/i,"$1zes"]));
w1($CLJS.H([/s$/i,"",/(ss)$/i,"$1",/(n)ews$/i,"$1ews",/([ti])a$/i,"$1um",/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)(sis|ses)$/i,"$1$2sis",/(^analy)(sis|ses)$/i,"$1sis",/([^f])ves$/i,"$1fe",/(hive)s$/i,"$1",/(tive)s$/i,"$1",/([lr])ves$/i,"$1f",/([^aeiouy]|qu)ies$/i,"$1y",/(s)eries$/i,"$1eries",/(m)ovies$/i,"$1ovie",/(x|ch|ss|sh)es$/i,"$1",/([m|l])ice$/i,"$1ouse",/(bus)(es)?$/i,"$1",/(o)es$/i,"$1",/(shoe)s$/i,"$1",/(cris|ax|test)(is|es)$/i,"$1is",/(octop|vir)(us|i)$/i,"$1us",/(alias|status)(es)?$/i,
"$1",/^(ox)en/i,"$1",/(vert|ind)ices$/i,"$1ex",/(matr)ices$/i,"$1ix",/(quiz)zes$/i,"$1",/(database)s$/i,"$1"]));
$CLJS.ch($CLJS.cf.g(function(a){var b=$CLJS.z(a);a=$CLJS.dd(a);b=$CLJS.r1(b).toLowerCase();a=$CLJS.r1(a).toLowerCase();$CLJS.zh.j($CLJS.x1,$CLJS.kk,$CLJS.r1(b).toLowerCase());$CLJS.zh.j($CLJS.x1,$CLJS.kk,$CLJS.r1(a).toLowerCase());w1($CLJS.H([$CLJS.fh(["^",a,"$"].join("")),b]));u1($CLJS.H([$CLJS.fh(["^",b,"$"].join("")),a]));$CLJS.zh.j(y1,$CLJS.Yd,b);return $CLJS.zh.j(y1,$CLJS.Yd,a)},new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,["amenity","amenities"],null),new $CLJS.P(null,2,5,$CLJS.Q,
["child","children"],null),new $CLJS.P(null,2,5,$CLJS.Q,["cow","kine"],null),new $CLJS.P(null,2,5,$CLJS.Q,["foot","feet"],null),new $CLJS.P(null,2,5,$CLJS.Q,["louse","lice"],null),new $CLJS.P(null,2,5,$CLJS.Q,["mailman","mailmen"],null),new $CLJS.P(null,2,5,$CLJS.Q,["man","men"],null),new $CLJS.P(null,2,5,$CLJS.Q,["mouse","mice"],null),new $CLJS.P(null,2,5,$CLJS.Q,["move","moves"],null),new $CLJS.P(null,2,5,$CLJS.Q,["ox","oxen"],null),new $CLJS.P(null,2,5,$CLJS.Q,["person","people"],null),new $CLJS.P(null,
2,5,$CLJS.Q,["sex","sexes"],null),new $CLJS.P(null,2,5,$CLJS.Q,["tooth","teeth"],null),new $CLJS.P(null,2,5,$CLJS.Q,["woman","women"],null)],null)));