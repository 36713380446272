var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var NK,OK,PK,oja,pja,TK,qja,WK,XK,YK,ZK,rja,$K,aL,cL,dL,QK,eL,sja,fL,tja,gL,hL,iL,uja,jL,kL,vja,lL,wja,mL,nL,xja,pL,yja,zja,Aja,rL,sL,tL,uL,vL,wL,xL,yL,Bja,zL,AL,BL,DL,EL,Cja,FL,GL,HL,IL,Dja,JL,KL,LL,ML,Eja,NL,OL,PL,QL,Fja,RL,Gja,SL,TL,UL,Hja,VL,WL,XL,YL,$L,aM,Ija,bM,cM,Jja,dM,eM,fM,gM,hM,Kja,iM,Lja,jM,kM,mM,Mja,nM,oM,pM,Nja,sM,tM,Oja,Pja,uM,wM,xM,yM,Qja,BM,CM,Rja,Sja,Tja,DM,EM,FM,GM,HM,IM,Uja,JM,Vja,KM,LM,NM,PM,QM,RM,SM,Wja,Xja,UM,Yja,Zja,VM,WM,XM,YM,ZM,$ja,$M,aka,bka,aN,bN,cN,dN,eN,
cka,dka,eka,fN,fka,gN,gka,iN,jN,hka,lN,mN,nN,oN,ika,rN,jka,kka,sN,tN,uN,lka,vN,wN,xN,yN,mka,AN,BN,CN,DN,EN,nka,FN,GN,oka,HN,IN,JN,KN,LN,MN,ON,pka,PN,qka,QN,RN,SN,rka,ska,tka,UN,VN,WN,uka,XN,YN,ZN,$N,vka,wka,bO,xka,yka,cO,dO,eO,fO,gO,zka,hO,iO,jO,kO,Aka,lO,mO,nO,oO,qO,Bka,rO,sO,Cka,tO,uO,vO,Dka,wO,Eka,yO,Fka,zO,AO,BO,CO,DO,EO,FO,Gka,Hka,Ika,GO,Jka,HO,IO,Kka,Lka,JO,KO,LO,Mka,MO,Nka,NO,Oka,Pka,OO,Qka,Rka,Ska,RO,SO,Tka,TO,UO,WO,Uka,XO,Vka,YO,ZO,$O,Wka,aP,bP,cP,dP,Xka,fP,gP,Yka,hP,iP,jP,kP,lP,mP,nP,oP,
Zka,pP,qP,$ka,rP,ala,sP,tP,uP,bla,vP,wP,cla,dla,xP,yP,zP,ela,fla,AP,gla,hla,ila,CP,jla,EP,kla,lla,FP,GP,mla,nla,ola,HP,IP,JP,KP,LP,MP,NP,pla,OP,PP,qla,QP,rla,sla,tla,RP,SP,TP,ula,UP,VP,vla,WP,wla,XP,xla,yla,ZP,$P,aQ,bQ,cQ,zla,dQ,eQ,Ala,fQ,gQ,hQ,iQ,jQ,kQ,lQ,mQ,Bla,nQ,oQ,qQ,rQ,sQ,tQ,uQ,vQ,wQ,xQ,yQ,zQ,Cla,AQ,BQ,CQ,DQ,EQ,Dla,FQ,GQ,HQ,IQ,Ela,KQ,Fla,Gla,MQ,Hla,NQ,Ila,OQ,Jla,Kla,PQ,Lla,QQ,SQ,TQ,Mla,Nla,XQ,YQ,Ola,Pla,ZQ,$Q,aR,bR,cR,Qla,dR,eR,gR,hR,Rla;
$CLJS.MK=function(a){var b=$CLJS.In.g(a,null),c=$CLJS.jE(b,$CLJS.Cr,function(d){return $CLJS.Om(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Mj,b,$CLJS.jj,k,$CLJS.Ir,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
NK=function(a,b){return $CLJS.sd(a)?$CLJS.J.g(a,b):a};OK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Rm($CLJS.In.g($CLJS.Mj.h(a),null));if($CLJS.n(b)){var e=NK($CLJS.Fs.h(b),c);$CLJS.n(e)?(e=$CLJS.Gp(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:NK($CLJS.us.h(b),c)}return null};PK=function(a,b){return $CLJS.qd(a)||$CLJS.nl(a)?$CLJS.J.g(a,b):$CLJS.rd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
oja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Mj),d=$CLJS.J.g(a,$CLJS.Xi);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Ir,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.Gs),l=$CLJS.J.j(b,$CLJS.Ds,$CLJS.Bs),m=OK(a,$CLJS.Ip.h(c),k,b);if($CLJS.n(m))return m;m=OK(a,$CLJS.Ur.h(c),k,b);if($CLJS.n(m))return m;m=OK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=OK(a,function(){var t=$CLJS.un.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=OK(a,$CLJS.Ip.h(c),
l,b);if($CLJS.n(m))return m;m=OK(a,$CLJS.Ur.h(c),l,b);if($CLJS.n(m))return m;d=OK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=OK(a,function(){var t=$CLJS.un.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?OK(a,e.h?e.h($CLJS.zs):e.call(null,$CLJS.zs),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?OK(a,e.h?e.h($CLJS.zs):e.call(null,$CLJS.zs),l,b):f};
pja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Uk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Mj),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.Gs);l=$CLJS.J.j(l,$CLJS.Ds,$CLJS.Bs);k=$CLJS.Ip.h(k);f=NK($CLJS.Cs.h(k),f);f=$CLJS.n(f)?f:NK($CLJS.Cs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,oja(b,c)],null)};
$CLJS.RK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.jj);a=$CLJS.J.g(b,$CLJS.Ir);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.Ci),f=$CLJS.J.j(d,$CLJS.jda,pja);return $CLJS.n(a)?$CLJS.bb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ci,m);l=e.h?e.h(l):e.call(null,l);return QK(k,c,t,l)},null,a):null};
TK=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.ZC),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.ZC,$CLJS.SK,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.UK=function(a){return TK($CLJS.Yj,a,function(){return $CLJS.kE.h(a)})};qja=function(){var a=VK;return TK($CLJS.Cr,a,function(){var b=$CLJS.kE.h(a),c=$CLJS.MK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
WK=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};XK=function(a){if($CLJS.ud(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,WK(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qq,WK(c)],null);default:return WK(a)}}else return WK(a)};
YK=function(a,b){var c=$CLJS.ud(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
ZK=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(YK,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ui,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,XK(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,XK(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Xr(2,2,b))}())],null)};rja=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
$K=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.Ai,rja,$CLJS.us,["valid instance of one of these MBQL clauses: ",$CLJS.is(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
aL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LK],null)],null)};$CLJS.bL={};cL={};dL={};$CLJS.SK=function SK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=SK.j?SK.j(k,f,c):SK.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
QK=function QK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=PK(b,c);f=$CLJS.n(a)?a:$CLJS.rd(b)?$CLJS.xf:$CLJS.td(b)?$CLJS.N:$CLJS.fd(b);return $CLJS.n($CLJS.n(c)?$CLJS.As.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=PK(f,c),d=QK.v?QK.v(b,l,k,d):QK.call(null,b,l,k,d),$CLJS.sl(c)&&$CLJS.rd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.yd(f)?b:$CLJS.Wf.g($CLJS.fd(f),b)),null==f||$CLJS.nl(f)?$CLJS.R.j(f,c,d):$CLJS.qd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.V,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.sd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),QK.v?QK.v(f,b,c,d):QK.call(null,f,b,c,d)):$CLJS.n($CLJS.As.h($CLJS.ld(f)))?$CLJS.Yd.g(f,d):$CLJS.ud($CLJS.Be(f))?f:$CLJS.kd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.As,!0],null))};eL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);sja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
fL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);tja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);gL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);hL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);iL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
uja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);jL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);kL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);vja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);lL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
wja=new $CLJS.M(null,"from","from",1815293044);mL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);nL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);xja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.oL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);pL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
yja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);zja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.qL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Aja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);rL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
sL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);tL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);uL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);vL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);wL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
xL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);yL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Bja=new $CLJS.M(null,"lon-max","lon-max",1590224717);zL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);AL=new $CLJS.r(null,"stddev","stddev",775056588,null);BL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.CL=new $CLJS.M(null,"snippet","snippet",953581994);DL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);EL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Cja=new $CLJS.M(null,"lat-field","lat-field",-830652957);FL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);GL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
HL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);IL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Dja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);JL=new $CLJS.M("location","country","location/country",1666636202);KL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
LL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);ML=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Eja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);NL=new $CLJS.M(null,"unary","unary",-989314568);OL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
PL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);QL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Fja=new $CLJS.M(null,"lon-min","lon-min",-787291357);RL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Gja=new $CLJS.M(null,"match","match",1220059550);SL=new $CLJS.r(null,"count-where","count-where",2025939247,null);TL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
UL=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Hja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);VL=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);WL=new $CLJS.r(null,"sum","sum",1777518341,null);XL=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);YL=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.ZL=new $CLJS.M("date","range","date/range",1647265776);$L=new $CLJS.r(null,"between","between",-1523336493,null);aM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Ija=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);bM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);cM=new $CLJS.r(null,"field","field",338095027,null);Jja=new $CLJS.M(null,"segment-id","segment-id",1810133590);
dM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);eM=new $CLJS.r(null,"not-null","not-null",313812992,null);fM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);gM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);hM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Kja=new $CLJS.M(null,"template-tag","template-tag",310841038);
iM=new $CLJS.M(null,"invalid","invalid",412869516);Lja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);jM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);kM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.lM=new $CLJS.M(null,"context","context",-830191113);mM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Mja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);nM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);oM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);pM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.qM=new $CLJS.M(null,"parameters","parameters",-1229919748);Nja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.rM=new $CLJS.M(null,"card","card",-1430355152);sM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);tM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Oja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Pja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);uM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.vM=new $CLJS.M("date","month-year","date/month-year",1948031290);
wM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);xM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);yM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.zM=new $CLJS.M(null,"joins","joins",1033962699);Qja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.AM=new $CLJS.M(null,"source-field","source-field",933829534);BM=new $CLJS.r(null,"Field","Field",430385967,null);
CM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Rja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Sja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Tja=new $CLJS.M(null,"items","items",1031954938);DM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);EM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
FM=new $CLJS.M(null,"more","more",-2058821800);GM=new $CLJS.M(null,"first-clause","first-clause",-20953491);HM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);IM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Uja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);JM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Vja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);KM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);LM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.MM=new $CLJS.M(null,"widget-type","widget-type",1836256899);NM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.OM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
PM=new $CLJS.r(null,"is-null","is-null",-356519403,null);QM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);RM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);SM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Wja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.TM=new $CLJS.M(null,"required","required",1807647006);Xja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
UM=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Yja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Zja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);VM=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);WM=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);XM=new $CLJS.M("string","contains","string/contains",1602423827);YM=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
ZM=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);$ja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);$M=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);aka=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);bka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
aN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);bN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);cN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);dN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);eN=new $CLJS.r(null,"share","share",1051097594,null);
cka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);dka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);eka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);fN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);fka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
gN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);gka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.hN=new $CLJS.M(null,"collection","collection",-683361892);iN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);jN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.kN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);hka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);lN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);mN=new $CLJS.r(null,"metric","metric",2049329604,null);nN=new $CLJS.r(null,"concat","concat",-467652465,null);oN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.pN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
ika=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.qN=new $CLJS.M("date","relative","date/relative",25987732);rN=new $CLJS.M("location","city","location/city",-1746973325);jka=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);kka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);sN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);tN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
uN=new $CLJS.M("number","between","number/between",97700581);lka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);vN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);wN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);xN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);yN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.zN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);mka=new $CLJS.M(null,"metric-id","metric-id",-686486942);AN=new $CLJS.r(null,"*","*",345799209,null);BN=new $CLJS.r(null,"+","+",-740910886,null);CN=new $CLJS.r(null,"-","-",-471816912,null);DN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);EN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);nka=new $CLJS.M(null,"question","question",-1411720117);FN=new $CLJS.r(null,"asc","asc",1997386096,null);
GN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);oka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);HN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);IN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);JN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);KN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);LN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
MN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.NN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);ON=new $CLJS.M("string","ends-with","string/ends-with",302681156);pka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);PN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);qka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
QN=new $CLJS.r(null,"and","and",668631710,null);RN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);SN=new $CLJS.r(null,"round","round",-645002441,null);rka=new $CLJS.M(null,"to","to",192099007);$CLJS.TN=new $CLJS.M("date","single","date/single",1554682003);ska=new $CLJS.M(null,"action-id","action-id",-1727958578);tka=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);UN=new $CLJS.r(null,"exp","exp",1378825265,null);
VN=new $CLJS.r(null,"Filter","Filter",-424893332,null);WN=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);uka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);XN=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);YN=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);ZN=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
$N=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.aO=new $CLJS.M(null,"source-table","source-table",-225307692);vka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);wka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);bO=new $CLJS.r(null,"floor","floor",-772394748,null);xka=new $CLJS.M(null,"middleware","middleware",1462115504);
yka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);cO=new $CLJS.M(null,"requires-features","requires-features",-101116256);dO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);eO=new $CLJS.M(null,"clause-name","clause-name",-996419059);fO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);gO=new $CLJS.r(null,"now","now",-9994004,null);
zka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);hO=new $CLJS.r(null,"not","not",1044554643,null);iO=new $CLJS.r(null,"avg","avg",1837937727,null);jO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);kO=new $CLJS.M(null,"max-results","max-results",-32858165);Aka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);lO=new $CLJS.r(null,"case","case",-1510733573,null);
mO=new $CLJS.r(null,"distinct","distinct",-148347594,null);nO=new $CLJS.r(null,"get-second","get-second",-425414791,null);oO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.pO=new $CLJS.M(null,"join-alias","join-alias",1454206794);qO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Bka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
rO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);sO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Cka=new $CLJS.M(null,"original","original",-445386197);tO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);uO=new $CLJS.r(null,"abs","abs",1394505050,null);vO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Dka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
wO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Eka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.xO=new $CLJS.M(null,"date","date",-1463434462);yO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Fka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);zO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
AO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);BO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);CO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);DO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);EO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);FO=new $CLJS.r(null,"or","or",1876275696,null);
Gka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Hka=new $CLJS.M(null,"constraints","constraints",422775616);Ika=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);GO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Jka=new $CLJS.M(null,"csv-download","csv-download",2141432084);HO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
IO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Kka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Lka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);JO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);KO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
LO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Mka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);MO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Nka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);NO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Oka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Pka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);OO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Qka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Rka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Ska=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.PO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.QO=new $CLJS.M(null,"database","database",1849087575);RO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);SO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Tka=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);TO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
UO=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.VO=new $CLJS.M(null,"expressions","expressions",255689909);WO=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Uka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);XO=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Vka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);YO=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);ZO=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);$O=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Wka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
aP=new $CLJS.r(null,"get-day","get-day",1768100384,null);bP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);cP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);dP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.eP=new $CLJS.M(null,"native","native",-613060878);Xka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);fP=new $CLJS.M(null,"page","page",849072397);
gP=new $CLJS.r(null,"length","length",-2065447907,null);Yka=new $CLJS.M(null,"dashboard","dashboard",-631747508);hP=new $CLJS.r(null,"get-week","get-week",752472178,null);iP=new $CLJS.r(null,"get-month","get-month",1271156796,null);jP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);kP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);lP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
mP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);nP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);oP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Zka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);pP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);qP=new $CLJS.r(null,"substring","substring",-1513569493,null);
$ka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);rP=new $CLJS.M(null,"internal","internal",-854870097);ala=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);sP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);tP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
uP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);bla=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);vP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);wP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);cla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
dla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);xP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);yP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);zP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
ela=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);fla=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);AP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.BP=new $CLJS.M(null,"template-tags","template-tags",1853115685);gla=new $CLJS.M(null,"public-question","public-question",629369976);hla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
ila=new $CLJS.M(null,"binary","binary",-1802232288);CP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);jla=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.DP=new $CLJS.M(null,"source-query","source-query",198004422);EP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);kla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
lla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);FP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);GP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);mla=new $CLJS.M(null,"executed-by","executed-by",-739811161);nla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
ola=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);HP=new $CLJS.M(null,"amount","amount",364489504);IP=new $CLJS.r(null,"percentile","percentile",1039342775,null);JP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);KP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);LP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
MP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);NP=new $CLJS.r(null,"trim","trim",-1880116002,null);pla=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);OP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);PP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);qla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
QP=new $CLJS.M("string","\x3d","string/\x3d",983744235);rla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);sla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);tla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);RP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);SP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
TP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);ula=new $CLJS.M(null,"lat-min","lat-min",1630784161);UP=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);VP=new $CLJS.r(null,"inside","inside",-681932758,null);vla=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);WP=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
wla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);XP=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);xla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);yla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.YP=new $CLJS.M(null,"card-id","card-id",-1770060179);ZP=new $CLJS.M(null,"variadic","variadic",882626057);
$P=new $CLJS.r(null,"upper","upper",1886775433,null);aQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);bQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);cQ=new $CLJS.r(null,"optional","optional",-600484260,null);zla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);dQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
eQ=new $CLJS.M(null,"sugar","sugar",-73788488);Ala=new $CLJS.M(null,"lat-max","lat-max",841568226);fQ=new $CLJS.r(null,"power","power",702679448,null);gQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);hQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);iQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
jQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);kQ=new $CLJS.r(null,"median","median",-2084869638,null);lQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);mQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Bla=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);nQ=new $CLJS.M(null,"y","y",-1757859776);oQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.pQ=new $CLJS.M(null,"binning","binning",1709835866);qQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);rQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);sQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);tQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);uQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);vQ=new $CLJS.M(null,"b","b",1482224470);
wQ=new $CLJS.M(null,"a","a",-2123407586);xQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);yQ=new $CLJS.r(null,"replace","replace",853943757,null);zQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Cla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);AQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
BQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);CQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);DQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);EQ=new $CLJS.r(null,"segment","segment",675610331,null);Dla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);FQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
GQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);HQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);IQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Ela=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.JQ=new $CLJS.M(null,"order-by","order-by",1527318070);KQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Fla=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Gla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.LQ=new $CLJS.M(null,"condition","condition",1668437652);MQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Hla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);NQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Ila=new $CLJS.M(null,"card-name","card-name",-2035606807);OQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Jla=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Kla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);PQ=new $CLJS.r(null,"log","log",45015523,null);Lla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
QQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.RQ=new $CLJS.M(null,"database_type","database_type",-54700895);SQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);TQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.UQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.VQ=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.WQ=new $CLJS.M("date","all-options","date/all-options",549325958);
Mla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Nla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);XQ=new $CLJS.M("location","state","location/state",-114378652);YQ=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Ola=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Pla=new $CLJS.M(null,"lon-field","lon-field",517872067);
ZQ=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);$Q=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);aR=new $CLJS.M(null,"numeric","numeric",-1495594714);bR=new $CLJS.r(null,"variable","variable",1359185035,null);cR=new $CLJS.r(null,"lower","lower",-1534114948,null);Qla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
dR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);eR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.fR=new $CLJS.M(null,"limit","limit",-1355822363);gR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);hR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Rla=new $CLJS.M(null,"pulse","pulse",-244494476);var iR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pD],null),jR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yD],null),kR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lD],null),lR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hD],null),mR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tD],null),nR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TJ],null),Tla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null),oR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.GH],null),pR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KH],null),qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BH],null),rR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.bi,null,$CLJS.Vj,null,$CLJS.Zi,null,$CLJS.Oh,null,$CLJS.ri,null,$CLJS.ej,null,$CLJS.Kj,null,$CLJS.oj,null,$CLJS.Wh,null,$CLJS.mj,null,$CLJS.ci,null,$CLJS.mi,null],null),null),sR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Rj,null,$CLJS.QF,null,$CLJS.Oh,null,$CLJS.gi,null,$CLJS.TF,null,$CLJS.Vi,null,$CLJS.Fi,null],null),null),
tR=$CLJS.Ws.g(rR,sR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"date bucketing unit"],null)],null),rR),Vla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"time bucketing unit"],null)],null),sR),uR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"datetime bucketing unit"],null)],null),tR),vR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null),Wla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,
1,[$CLJS.us,"temporal extract unit"],null),$CLJS.UF,$CLJS.mi,$CLJS.Kj,$CLJS.WH,$CLJS.jI,$CLJS.kI,$CLJS.oj,$CLJS.ri,$CLJS.gi,$CLJS.Vi,$CLJS.RF],null),Xla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"datetime-diff unit"],null),$CLJS.QF,$CLJS.Fi,$CLJS.Rj,$CLJS.Vj,$CLJS.Zi,$CLJS.ej,$CLJS.bi,$CLJS.Wh],null),wR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"temporal-extract week extraction mode"],null),$CLJS.QH,$CLJS.XH,$CLJS.aI],null),xR=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Fi,$CLJS.Rj,$CLJS.Vj,$CLJS.Zi,$CLJS.ej,$CLJS.bi,$CLJS.Wh],null),ER,Zla,MR,NR,OR,PR,QR,RR,SR,FT,ama,GT,bma,cma,HT,dma,ema,fma;$CLJS.Y(PL,ZK($CLJS.$A,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Qu],null),$CLJS.ii],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,xR],null)])));var yR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PL],null);
$CLJS.Y(tO,ZK($CLJS.ZA,$CLJS.H(["n",$CLJS.ii,"unit",xR])));var Yla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tO],null);
$CLJS.Y(jM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"valid :absolute-datetime clause",$CLJS.Ai,function(a){if($CLJS.Sa(YK($CLJS.UH,a)))a=iM;else{a=$CLJS.dd(a);var b=$CLJS.UK(oR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.xO:$CLJS.YH}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[iM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xO,ZK($CLJS.UH,
$CLJS.H(["date",oR,"unit",Ula]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,ZK($CLJS.UH,$CLJS.H(["datetime",pR,"unit",uR]))],null)],null));var zR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jM],null);$CLJS.Y(yM,ZK($CLJS.Fx,$CLJS.H(["time",qR,"unit",Vla])));var AR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null),BR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.us,"date or datetime literal"],null),zR,pR,oR],null);
$CLJS.Y(jL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.us,"time literal"],null),AR,qR],null));$CLJS.Y(ZQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.us,"temporal literal"],null),BR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jL],null)],null));var CR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZQ],null);
$CLJS.Y(qla,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof zR?new $CLJS.Cc(function(){return zR},$CLJS.kd(Rja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,rP,$CLJS.Yi,$CLJS.ak,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",69,$CLJS.UH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(zR)?zR.H:null])):null));return $CLJS.n(a)?a:$O}(),zR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof yR?new $CLJS.Cc(function(){return yR},$CLJS.kd(Mla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,
$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",29,$CLJS.$A,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.Sj,$CLJS.V($CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Qu],null),$CLJS.ii],null)),$CLJS.di,$CLJS.V($CLJS.wj,$CLJS.V(cQ,PN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(yR)?yR.H:null])):null));return $CLJS.n(a)?a:rO}(),yR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof AR?new $CLJS.Cc(function(){return AR},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,rP,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.V($CLJS.wj,pla),$CLJS.di,$CLJS.V($CLJS.wj,ika)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(AR)?AR.H:null])):null));return $CLJS.n(a)?a:QQ}(),AR],null)])));
$CLJS.Y(OQ,ZK($CLJS.jj,$CLJS.H(["value",$CLJS.vr,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,iR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cC,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,jR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.gr,kR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,uR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,iR],null)],null)],null)],null)])));var DR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.Y(yN,ZK($CLJS.qA,$CLJS.H(["expression-name",iR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.Hj],null)])));
ER=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null);
Zla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.AD);c=$CLJS.J.g(c,$CLJS.iD);return $CLJS.E.g(b,$CLJS.iD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.AD);c=$CLJS.J.g(c,$CLJS.zD);return $CLJS.E.g(b,$CLJS.zD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.us,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"binning strategy"],null),$CLJS.iD,$CLJS.zD,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),lR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.zD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.fl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.Al)],null)],null)],null)],null)));$CLJS.FR=function FR(a){switch(arguments.length){case 1:return FR.h(arguments[0]);case 2:return FR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.FR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tA);b=$CLJS.J.g(b,$CLJS.nF);return $CLJS.FR.g(a,b)};$CLJS.FR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.Ok)?rR:$CLJS.Dz(a,$CLJS.Tk)?sR:$CLJS.Dz(a,$CLJS.Qk)?tR:null:null;return $CLJS.n(a)?$CLJS.Cd(a,b):!0};$CLJS.FR.A=2;
$CLJS.Y(dQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.us,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,jR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,uR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,iR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,Zla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.FR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.AD)],null)],null));
$CLJS.Y(FQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(ZK($CLJS.hF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,nR,iR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null)],null)]))));var GR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);
$CLJS.Y(GO,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof ER?new $CLJS.Cc(function(){return ER},$CLJS.kd(KM,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.VQ,$CLJS.V($CLJS.wj,RL),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,$CLJS.Hj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:uM}(),ER],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof GR?new $CLJS.Cc(function(){return GR},
$CLJS.kd(bN,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.jq,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],["0.39.0",$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",51,$CLJS.hF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:cM}(),GR],null)])));
$CLJS.HR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GO],null);$CLJS.Y(eL,ZK($CLJS.XE,$CLJS.H(["aggregation-clause-index",$CLJS.ii,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.Hj],null)])));var IR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eL],null);
$CLJS.Y(LL,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof IR?new $CLJS.Cc(function(){return IR},$CLJS.kd(nla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oQ,"metabase/mbql/schema.cljc",23,$CLJS.XE,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XE,Ola,$CLJS.V($CLJS.wj,$CLJS.ii),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,$CLJS.Hj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(IR)?IR.H:null])):null));return $CLJS.n(a)?a:oQ}(),IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof ER?new $CLJS.Cc(function(){return ER},
$CLJS.kd(KM,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.VQ,$CLJS.V($CLJS.wj,RL),
$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,$CLJS.Hj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:uM}(),ER],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof GR?new $CLJS.Cc(function(){return GR},$CLJS.kd(bN,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.jq,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,
$CLJS.ei,$CLJS.Jj,$CLJS.Oj],["0.39.0",$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",51,$CLJS.hF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:cM}(),GR],null)])));
var JR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LL],null),KR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.ks,null,$CLJS.yF,null,$CLJS.zE,null,$CLJS.EF,null,$CLJS.GE,null,$CLJS.kF,null,$CLJS.QE,null,$CLJS.qs,null,$CLJS.wF,null,$CLJS.jF,null,$CLJS.FF,null],null),null),LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IL],null);
$CLJS.Y(lL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.Ai,function(a){return"string"===typeof a?$CLJS.Cj:$CLJS.n(YK(KR,a))?EL:$CLJS.n(YK($CLJS.jj,a))?$CLJS.jj:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,$CLJS.Cj],null),new $CLJS.P(null,2,5,$CLJS.Q,[EL,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.HR],null)],null));MR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lL],null);
NR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.CE,null,$CLJS.iI,null,$CLJS.Qq,null,$CLJS.cB,null,$CLJS.dI,null,$CLJS.VE,null,$CLJS.$r,null,$CLJS.yE,null,$CLJS.gI,null,$CLJS.aB,null,$CLJS.kF,null,$CLJS.BF,null,$CLJS.eI,null,$CLJS.mF,null,$CLJS.EE,null,$CLJS.SH,null,$CLJS.Pq,null,$CLJS.HE,null,$CLJS.QE,null,$CLJS.MH,null,$CLJS.Pw,null,$CLJS.TH,null,$CLJS.bI,null,$CLJS.YA,null,$CLJS.DF,null,$CLJS.fF,null],null),null);
OR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Nq,null,$CLJS.Hq,null,$CLJS.hr,null,$CLJS.jr,null,$CLJS.Jq,null,$CLJS.xF,null,$CLJS.mr,null,$CLJS.Tj,null,$CLJS.Lq,null],null),null);PR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.vi,null,$CLJS.vF,null,$CLJS.qF,null,$CLJS.iF,null,$CLJS.AF,null,$CLJS.CF,null,$CLJS.ME,null,$CLJS.TE,null,$CLJS.qL,null,$CLJS.NE,null,$CLJS.uF,null,$CLJS.oF,null,$CLJS.Ij,null,$CLJS.Rw,null,$CLJS.bF,null,$CLJS.cF,null,$CLJS.tF,null],null),null);
QR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.ZH,null,$CLJS.OH,null,$CLJS.eB,null,$CLJS.Pq,null,$CLJS.bB,null],null),null);RR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WP],null);SR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);$CLJS.TR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xP],null);$CLJS.UR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);
$CLJS.Y(iQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"numeric expression argument",$CLJS.Ai,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.n(YK(NR,a))?$N:$CLJS.n(YK(PR,a))?$CLJS.XE:$CLJS.n(YK($CLJS.jj,a))?$CLJS.jj:$CLJS.hF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.fl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$N,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,$CLJS.UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hF,
$CLJS.HR],null)],null));var VR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null);
$CLJS.Y(sP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"datetime expression argument",$CLJS.Ai,function(a){return $CLJS.n(YK(PR,a))?$CLJS.XE:$CLJS.n(YK($CLJS.jj,a))?$CLJS.jj:$CLJS.n(YK(QR,a))?DM:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,$CLJS.UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[DM,$CLJS.TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,BR,$CLJS.HR],null)],null)],null));
var WR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sP],null);
$CLJS.Y(WO,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"expression argument",$CLJS.Ai,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.xd(a)?$CLJS.ur:$CLJS.n(YK(OR,a))?kP:$CLJS.n(YK(NR,a))?$N:$CLJS.n(YK(QR,a))?DM:"string"===typeof a?$CLJS.Cj:$CLJS.n(YK(KR,a))?EL:$CLJS.n(YK($CLJS.jj,a))?$CLJS.jj:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.fl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,$CLJS.ur],null),new $CLJS.P(null,2,5,$CLJS.Q,[kP,SR],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$N,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[DM,$CLJS.TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,$CLJS.Cj],null),new $CLJS.P(null,2,5,$CLJS.Q,[EL,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.HR],null)],null));var YR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WO],null);$CLJS.Y(UO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.us,"numeric expression arg or interval"],null),Yla,VR],null));
var ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UO],null);$CLJS.Y(wL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"int greater than zero or numeric expression",$CLJS.Ai,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,lR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,RR],null)],null));var $la=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wL],null);
$CLJS.Y(EM,ZK($CLJS.kF,$CLJS.H(["a",YR,"b",YR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,YR],null)])));var $R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null);$CLJS.Y(iL,ZK($CLJS.zE,$CLJS.H(["s",MR,"start",$la,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,VR],null)])));var aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iL],null);$CLJS.Y(lP,ZK($CLJS.Pw,$CLJS.H(["s",MR])));var bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lP],null);$CLJS.Y(KL,ZK($CLJS.wF,$CLJS.H(["s",MR])));
var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KL],null);$CLJS.Y(OL,ZK($CLJS.FF,$CLJS.H(["s",MR])));var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OL],null);$CLJS.Y(gM,ZK($CLJS.yF,$CLJS.H(["s",MR])));var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gM],null);$CLJS.Y(SM,ZK($CLJS.ks,$CLJS.H(["s",MR])));var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);$CLJS.Y(BL,ZK($CLJS.qs,$CLJS.H(["s",MR])));var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BL],null);
$CLJS.Y(hQ,ZK($CLJS.EF,$CLJS.H(["s",MR,"match",$CLJS.Cj,"replacement",$CLJS.Cj])));var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);$CLJS.Y(dR,ZK($CLJS.GE,$CLJS.H(["a",MR,"b",MR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,MR],null)])));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dR],null);$CLJS.Y(HN,ZK($CLJS.jF,$CLJS.H(["s",MR,"pattern",$CLJS.Cj])));var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HN],null);
$CLJS.Y(DQ,ZK($CLJS.Pq,$CLJS.H(["x",ZR,"y",ZR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,ZR],null)])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);$CLJS.Y(BQ,ZK($CLJS.$r,$CLJS.H(["x",VR,"y",ZR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,ZR],null)])));var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);$CLJS.Y(AQ,ZK($CLJS.BF,$CLJS.H(["x",VR,"y",VR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,VR],null)])));var mS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);
$CLJS.Y(CQ,ZK($CLJS.Qq,$CLJS.H(["x",VR,"y",VR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,VR],null)])));var nS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);$CLJS.Y(TO,ZK($CLJS.fF,$CLJS.H(["x",VR])));var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TO],null);$CLJS.Y(LP,ZK($CLJS.CE,$CLJS.H(["x",VR])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.Y(gR,ZK($CLJS.mF,$CLJS.H(["x",VR])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gR],null);$CLJS.Y(YN,ZK($CLJS.HE,$CLJS.H(["x",VR])));
var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YN],null);$CLJS.Y(dO,ZK($CLJS.EE,$CLJS.H(["x",VR,"y",VR])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.Y(AO,ZK($CLJS.VE,$CLJS.H(["x",VR])));var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);$CLJS.Y(xL,ZK($CLJS.yE,$CLJS.H(["x",VR])));var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xL],null);$CLJS.Y(ZO,ZK($CLJS.DF,$CLJS.H(["x",VR])));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZO],null);
$CLJS.Y(XL,ZK($CLJS.YA,$CLJS.H(["datetime-x",WR,"datetime-y",WR,"unit",Xla])));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XL],null);$CLJS.Y(oM,ZK($CLJS.cB,$CLJS.H(["datetime",WR,"unit",Wla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,wR],null)])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);$CLJS.Y(fO,ZK($CLJS.TH,$CLJS.H(["date",WR])));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null);$CLJS.Y(HO,ZK($CLJS.iI,$CLJS.H(["date",WR])));
var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);$CLJS.Y(kL,ZK($CLJS.MH,$CLJS.H(["date",WR])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kL],null);$CLJS.Y(eR,ZK($CLJS.aB,$CLJS.H(["date",WR,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,wR],null)])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eR],null);$CLJS.Y(sN,ZK($CLJS.bI,$CLJS.H(["date",WR])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.Y(BO,ZK($CLJS.eI,$CLJS.H(["date",WR])));
var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BO],null);$CLJS.Y(TL,ZK($CLJS.gI,$CLJS.H(["datetime",WR])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TL],null);$CLJS.Y(xN,ZK($CLJS.dI,$CLJS.H(["datetime",WR])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);$CLJS.Y(ML,ZK($CLJS.SH,$CLJS.H(["datetime",WR])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ML],null);$CLJS.Y(cN,ZK($CLJS.OH,$CLJS.H(["datetime",WR,"to",vR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,vR],null)])));
var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null),JS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"datetime arithmetic unit"],null),$CLJS.TF,$CLJS.QF,$CLJS.Fi,$CLJS.Rj,$CLJS.Vj,$CLJS.Zi,$CLJS.ej,$CLJS.bi,$CLJS.Wh],null);$CLJS.Y(hL,ZK($CLJS.bB,$CLJS.H(["datetime",WR,"amount",VR,"unit",JS])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hL],null);
$CLJS.Y(FL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return ZK(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.ZH));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FL],null);$CLJS.Y(gL,ZK($CLJS.eB,$CLJS.H(["datetime",WR,"amount",VR,"unit",JS])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gL],null);
$CLJS.Y(xP,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BN,"metabase/mbql/schema.cljc",51,$CLJS.Pq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Pq,$CLJS.xD,$CLJS.V($CLJS.wj,RO),nQ,$CLJS.V($CLJS.wj,RO),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,RO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:BN}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},
$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[WM,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",67,$CLJS.bB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.YH,$CLJS.V($CLJS.wj,$Q),HP,$CLJS.V($CLJS.wj,
MP),$CLJS.di,$CLJS.V($CLJS.wj,UP)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:IO}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof MS?new $CLJS.Cc(function(){return MS},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,
$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[WM,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",72,$CLJS.eB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eB,$CLJS.YH,$CLJS.V($CLJS.wj,$Q),HP,$CLJS.V($CLJS.wj,MP),$CLJS.di,$CLJS.V($CLJS.wj,UP)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(MS)?MS.H:null])):null));
return $CLJS.n(a)?a:YQ}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.kd(wla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.OH,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CM,"metabase/mbql/schema.cljc",71,$CLJS.OH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OH,$CLJS.YH,$CLJS.V($CLJS.wj,$Q),rka,$CLJS.V($CLJS.wj,qQ),wja,$CLJS.V($CLJS.wj,$CLJS.V(cQ,qQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:CM}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&
"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof LS?new $CLJS.Cc(function(){return LS},$CLJS.kd(Zka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZH,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gO,"metabase/mbql/schema.cljc",
45,$CLJS.ZH,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZH],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:gO}(),LS],null)])));$CLJS.NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DO],null);$CLJS.Y(mL,ZK($CLJS.mr,$CLJS.H(["first-clause",$CLJS.NS,"second-clause",$CLJS.NS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,$CLJS.NS],null)])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mL],null);
$CLJS.Y(ZM,ZK($CLJS.hr,$CLJS.H(["first-clause",$CLJS.NS,"second-clause",$CLJS.NS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,$CLJS.NS],null)])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);$CLJS.Y(HL,ZK($CLJS.jr,$CLJS.H(["clause",$CLJS.NS])));
var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HL],null),RS=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,3,[$CLJS.us,":field or :expression reference or :relative-datetime",$CLJS.Fs,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.Ai,function(a){return $CLJS.n(YK($CLJS.$A,a))?$CLJS.$A:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,yR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.HR],null)],null);
$CLJS.Y(uP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.us,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hr,$CLJS.ur,$CLJS.fl,$CLJS.Cj,CR,RS,YR,DR],null)],null));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);
$CLJS.Y(MN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"order comparable",$CLJS.Ai,function(a){return $CLJS.n(YK($CLJS.jj,a))?$CLJS.jj:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hr,$CLJS.fl,$CLJS.Cj,CR,YR,RS],null)],null)],null));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MN],null);
$CLJS.Y(tQ,ZK($CLJS.Tj,$CLJS.H(["field",SS,"value-or-field",SS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,SS],null)])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);$CLJS.Y(xQ,ZK($CLJS.xF,$CLJS.H(["field",SS,"value-or-field",SS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,SS],null)])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);$CLJS.Y(sQ,ZK($CLJS.Lq,$CLJS.H(["field",TS,"value-or-field",TS])));
var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sQ],null);$CLJS.Y(rQ,ZK($CLJS.Hq,$CLJS.H(["field",TS,"value-or-field",TS])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null);$CLJS.Y(hM,ZK($CLJS.Nq,$CLJS.H(["field",TS,"value-or-field",TS])));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hM],null);$CLJS.Y(nM,ZK($CLJS.Jq,$CLJS.H(["field",TS,"value-or-field",TS])));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);$CLJS.Y(cP,ZK($CLJS.JE,$CLJS.H(["field",TS,"min",TS,"max",TS])));
var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);$CLJS.Y(EP,ZK($CLJS.PE,$CLJS.H(["lat-field",TS,"lon-field",TS,"lat-max",TS,"lon-min",TS,"lat-min",TS,"lon-max",TS])));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EP],null);$CLJS.Y(RM,ZK($CLJS.IE,$CLJS.H(["field",$CLJS.HR])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.Y(GN,ZK($CLJS.UE,$CLJS.H(["field",$CLJS.HR])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GN],null);$CLJS.Y(iN,ZK($CLJS.KE,$CLJS.H(["field",$CLJS.HR])));
var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null);$CLJS.Y(pL,ZK($CLJS.RE,$CLJS.H(["field",$CLJS.HR])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pL],null),fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null);$CLJS.Y(vN,ZK($CLJS.ZE,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,fT],null)])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null);
$CLJS.Y(oO,ZK($CLJS.sF,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,fT],null)])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null);$CLJS.Y(gN,ZK($CLJS.gF,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,fT],null)])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null);$CLJS.Y(XO,ZK($CLJS.$E,$CLJS.H(["field",MR,"string-or-field",MR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,fT],null)])));
var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XO],null);$CLJS.Y(yL,ZK($CLJS.dB,$CLJS.H(["field",$CLJS.HR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.ii,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Lj,$CLJS.Qu,$CLJS.Vw,$CLJS.OE],null)],null),"unit",xR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null)])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yL],null);
$CLJS.Y(nP,ZK($CLJS.FE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null),iR],null)])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nP],null);
$CLJS.Y(wM,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},$CLJS.kd(gQ,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QN,"metabase/mbql/schema.cljc",15,$CLJS.mr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mr,GM,$CLJS.V($CLJS.wj,VN),yO,$CLJS.V($CLJS.wj,VN),QM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,VN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:QN}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof PS?new $CLJS.Cc(function(){return PS},
$CLJS.kd(CO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",14,$CLJS.hr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hr,GM,$CLJS.V($CLJS.wj,VN),yO,$CLJS.V($CLJS.wj,VN),QM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,VN))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:FO}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},$CLJS.kd(oP,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hO,"metabase/mbql/schema.cljc",15,$CLJS.jr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,$CLJS.UQ,$CLJS.V($CLJS.wj,VN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:hO}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==
typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",13,$CLJS.Lq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Lq,$CLJS.hF,$CLJS.V($CLJS.wj,IM),SP,$CLJS.V($CLJS.wj,IM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:JN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.kd(NQ,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",14,$CLJS.Nq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nq,$CLJS.hF,$CLJS.V($CLJS.wj,IM),SP,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:IN}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&
"undefined"!==typeof dL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KN,"metabase/mbql/schema.cljc",13,$CLJS.Hq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.hF,$CLJS.V($CLJS.wj,
IM),SP,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:KN}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(GQ,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],
[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",14,$CLJS.Jq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jq,$CLJS.hF,$CLJS.V($CLJS.wj,IM),SP,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:RN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&
"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.kd(KO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",13,$CLJS.Tj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Tj,$CLJS.hF,$CLJS.V($CLJS.wj,SQ),SP,$CLJS.V($CLJS.wj,SQ),vP,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,SQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:LN}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},$CLJS.kd(YL,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,
$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tM,"metabase/mbql/schema.cljc",14,$CLJS.xF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xF,$CLJS.hF,$CLJS.V($CLJS.wj,SQ),SP,$CLJS.V($CLJS.wj,SQ),vP,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,SQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(VS)?VS.H:null])):null));
return $CLJS.n(a)?a:tM}(),VS],null)])));
$CLJS.Y(DO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"valid filter expression",$CLJS.Ai,function(a){return $CLJS.n(YK(QR,a))?$CLJS.YH:$CLJS.n(YK(NR,a))?aR:$CLJS.n(YK(KR,a))?$CLJS.Cj:$CLJS.n(YK(OR,a))?$CLJS.ur:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,$CLJS.TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[aR,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,SR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$K($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},$CLJS.kd(gQ,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",
15,$CLJS.mr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mr,GM,$CLJS.V($CLJS.wj,VN),yO,$CLJS.V($CLJS.wj,VN),QM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,VN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:QN}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof PS?new $CLJS.Cc(function(){return PS},$CLJS.kd(CO,new $CLJS.h(null,1,[$CLJS.Dj,
!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",14,$CLJS.hr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hr,GM,$CLJS.V($CLJS.wj,VN),yO,$CLJS.V($CLJS.wj,VN),QM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,VN))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(PS)?
PS.H:null])):null));return $CLJS.n(a)?a:FO}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},$CLJS.kd(oP,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hO,"metabase/mbql/schema.cljc",15,$CLJS.jr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,$CLJS.UQ,$CLJS.V($CLJS.wj,VN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:hO}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.kd(KO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,
$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",13,$CLJS.Tj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Tj,$CLJS.hF,$CLJS.V($CLJS.wj,SQ),SP,$CLJS.V($CLJS.wj,SQ),vP,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,SQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(US)?US.H:null])):
null));return $CLJS.n(a)?a:LN}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},$CLJS.kd(YL,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tM,"metabase/mbql/schema.cljc",14,$CLJS.xF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xF,$CLJS.hF,$CLJS.V($CLJS.wj,SQ),SP,$CLJS.V($CLJS.wj,SQ),vP,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,SQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:tM}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},
$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",13,$CLJS.Lq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Lq,$CLJS.hF,$CLJS.V($CLJS.wj,IM),SP,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:JN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KN,"metabase/mbql/schema.cljc",13,$CLJS.Hq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.hF,$CLJS.V($CLJS.wj,IM),SP,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:KN}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.kd(NQ,new $CLJS.h(null,
1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",14,$CLJS.Nq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nq,$CLJS.hF,$CLJS.V($CLJS.wj,IM),SP,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(YS)?YS.H:null])):
null));return $CLJS.n(a)?a:IN}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(GQ,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RN,"metabase/mbql/schema.cljc",14,$CLJS.Jq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jq,$CLJS.hF,$CLJS.V($CLJS.wj,IM),SP,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:RN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(Rka,new $CLJS.h(null,
1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$L,"metabase/mbql/schema.cljc",19,$CLJS.JE,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JE,$CLJS.hF,$CLJS.V($CLJS.wj,IM),$CLJS.vi,$CLJS.V($CLJS.wj,IM),$CLJS.Ij,$CLJS.V($CLJS.wj,IM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:$L}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.kd(Tka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XP,"metabase/mbql/schema.cljc",23,$CLJS.ZE,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZE,$CLJS.hF,$CLJS.V($CLJS.wj,RP),sM,$CLJS.V($CLJS.wj,RP),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,mP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:XP}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof hT?new $CLJS.Cc(function(){return hT},
$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xM,"metabase/mbql/schema.cljc",21,$CLJS.sF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sF,$CLJS.hF,$CLJS.V($CLJS.wj,RP),sM,$CLJS.V($CLJS.wj,RP),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,mP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:xM}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof iT?new $CLJS.Cc(function(){return iT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JM,"metabase/mbql/schema.cljc",20,$CLJS.gF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gF,$CLJS.hF,$CLJS.V($CLJS.wj,RP),sM,$CLJS.V($CLJS.wj,RP),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,mP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:JM}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==
typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof jT?new $CLJS.Cc(function(){return jT},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nL,"metabase/mbql/schema.cljc",36,$CLJS.$E,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.$E,$CLJS.hF,$CLJS.V($CLJS.wj,RP),sM,$CLJS.V($CLJS.wj,RP),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,mP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:nL}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(tla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),
$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",26,$CLJS.PE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.PE,Cja,$CLJS.V($CLJS.wj,IM),Pla,$CLJS.V($CLJS.wj,IM),Ala,$CLJS.V($CLJS.wj,IM),Fja,$CLJS.V($CLJS.wj,IM),ula,$CLJS.V($CLJS.wj,IM),Bja,$CLJS.V($CLJS.wj,
IM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:VP}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(hka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",28,$CLJS.KE,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,$CLJS.hF,$CLJS.V($CLJS.wj,BM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:qO}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&
"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",29,$CLJS.RE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,$CLJS.hF,$CLJS.V($CLJS.wj,BM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:dP}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(Fla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PM,"metabase/mbql/schema.cljc",27,$CLJS.IE,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IE,$CLJS.hF,$CLJS.V($CLJS.wj,BM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:PM}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&
"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(tka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eM,"metabase/mbql/schema.cljc",28,$CLJS.UE,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UE,$CLJS.hF,$CLJS.V($CLJS.wj,BM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:eM}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof kT?new $CLJS.Cc(function(){return kT},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",33,$CLJS.dB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dB,$CLJS.hF,$CLJS.V($CLJS.wj,BM),$CLJS.Sj,$CLJS.V($CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.ii,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Lj,$CLJS.Qu,$CLJS.Vw,$CLJS.OE],null)],null)),$CLJS.di,$CLJS.V($CLJS.wj,PN),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,Wka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:CP}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof lT?new $CLJS.Cc(function(){return lT},$CLJS.kd(Bka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EQ,"metabase/mbql/schema.cljc",27,$CLJS.FE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,Jja,$CLJS.V($CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,kla,RL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:EQ}(),lT],null)]))],null)],null));
$CLJS.Y(pP,ZK($CLJS.QE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.h(null,1,[$CLJS.us,":case subclause"],null),$CLJS.NS,YR],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.us,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),YR],null)],null)],null)])));
var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);
$CLJS.Y(WP,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BN,"metabase/mbql/schema.cljc",51,$CLJS.Pq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Pq,$CLJS.xD,$CLJS.V($CLJS.wj,RO),nQ,$CLJS.V($CLJS.wj,RO),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,RO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:BN}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof lS?new $CLJS.Cc(function(){return lS},
$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CN,"metabase/mbql/schema.cljc",51,$CLJS.$r,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$r,$CLJS.xD,$CLJS.V($CLJS.wj,MP),
nQ,$CLJS.V($CLJS.wj,RO),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,RO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:CN}(),lS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL?new $CLJS.Cc(function(){return mS},$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,eO,$CLJS.Ei,$CLJS.Yi,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.BF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BF,$CLJS.xD,$CLJS.V($CLJS.wj,MP),nQ,$CLJS.V($CLJS.wj,MP),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,MP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof nS?new $CLJS.Cc(function(){return nS},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AN,"metabase/mbql/schema.cljc",51,$CLJS.Qq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Qq,$CLJS.xD,$CLJS.V($CLJS.wj,MP),nQ,$CLJS.V($CLJS.wj,MP),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,MP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(nS)?nS.H:null])):null));return $CLJS.n(a)?a:AN}(),nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof $R?new $CLJS.Cc(function(){return $R},
$CLJS.kd(zP,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",58,$CLJS.kF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kF,wQ,$CLJS.V($CLJS.wj,sL),vQ,$CLJS.V($CLJS.wj,
sL),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,sL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n($R)?$R.H:null])):null));return $CLJS.n(a)?a:OP}(),$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof bS?new $CLJS.Cc(function(){return bS},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,
$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.V($CLJS.wj,RP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(bS)?bS.H:null])):null));return $CLJS.n(a)?a:gP}(),bS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof pS?new $CLJS.Cc(function(){return pS},$CLJS.kd(Vja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bO,"metabase/mbql/schema.cljc",55,$CLJS.fF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF,$CLJS.xD,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:bO}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},$CLJS.kd(Pja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),
$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QL,"metabase/mbql/schema.cljc",54,$CLJS.CE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,$CLJS.xD,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(qS)?
qS.H:null])):null));return $CLJS.n(a)?a:QL}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",55,$CLJS.mF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,$CLJS.xD,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:SN}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&
"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",53,$CLJS.HE,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.HE,$CLJS.xD,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:uO}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},$CLJS.kd(yla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,
aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",69,$CLJS.EE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.EE,$CLJS.xD,$CLJS.V($CLJS.wj,MP),nQ,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:fQ}(),tS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof uS?new $CLJS.Cc(function(){return uS},$CLJS.kd(wka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wN,"metabase/mbql/schema.cljc",68,$CLJS.VE,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VE,$CLJS.xD,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:wN}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof vS?new $CLJS.Cc(function(){return vS},$CLJS.kd(zla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),
$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",67,$CLJS.yE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,$CLJS.xD,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(vS)?
vS.H:null])):null));return $CLJS.n(a)?a:UN}(),vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.kd(Ija,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",67,$CLJS.DF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.xD,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:PQ}(),wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&
"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},$CLJS.kd(DL,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",61,$CLJS.QE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.QE,$CLJS.bw,$CLJS.V($CLJS.wj,VM),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,EO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:lO}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.kd(Lja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,
$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YA,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",65,$CLJS.YA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YA,Yja,$CLJS.V($CLJS.wj,$Q),Zja,$CLJS.V($CLJS.wj,$Q),$CLJS.di,$CLJS.V($CLJS.wj,Eka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:vO}(),xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.kd(hla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",71,$CLJS.cB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cB,$CLJS.YH,$CLJS.V($CLJS.wj,$Q),$CLJS.di,$CLJS.V($CLJS.wj,Ela),$CLJS.ki,$CLJS.V($CLJS.wj,$CLJS.V(cQ,hR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:OO}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.kd(pka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mM,"metabase/mbql/schema.cljc",71,$CLJS.TH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TH,$CLJS.xO,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:mM}(),zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.kd(eka,new $CLJS.h(null,1,[$CLJS.Dj,!0],
null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",74,$CLJS.iI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iI,$CLJS.xO,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:MO}(),AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",72,$CLJS.MH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MH,$CLJS.xO,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:iP}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==
typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.kd(Dla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",
71,$CLJS.aB,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aB,$CLJS.xO,$CLJS.V($CLJS.wj,$Q),$CLJS.ki,$CLJS.V($CLJS.wj,$CLJS.V(cQ,hR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:hP}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.Dj,
!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",70,$CLJS.bI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bI,$CLJS.xO,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:aP}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",78,$CLJS.eI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eI,$CLJS.xO,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:SO}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==
typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.kd(Dka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tL,"metabase/mbql/schema.cljc",
71,$CLJS.gI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gI,$CLJS.YH,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:tL}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.kd(lla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,
$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",73,$CLJS.dI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dI,$CLJS.YH,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(GS)?GS.H:null])):null));
return $CLJS.n(a)?a:TQ}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,
[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",73,$CLJS.SH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SH,$CLJS.YH,$CLJS.V($CLJS.wj,$Q)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:nO}(),HS],null)])));
$CLJS.Y(IL,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof aS?new $CLJS.Cc(function(){return aS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qP,"metabase/mbql/schema.cljc",59,$CLJS.zE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zE,$CLJS.lx,$CLJS.V($CLJS.wj,RP),$CLJS.vu,$CLJS.V($CLJS.wj,aka),$CLJS.Pw,$CLJS.V($CLJS.wj,$CLJS.V(cQ,MP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(aS)?aS.H:null])):null));return $CLJS.n(a)?a:qP}(),aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof cS?new $CLJS.Cc(function(){return cS},
$CLJS.kd(Bla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",54,$CLJS.wF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wF,$CLJS.lx,$CLJS.V($CLJS.wj,RP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(cS)?cS.H:null])):null));return $CLJS.n(a)?a:NP}(),cS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof eS?new $CLJS.Cc(function(){return eS},$CLJS.kd(Gka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",55,$CLJS.yF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,$CLJS.lx,$CLJS.V($CLJS.wj,RP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:wP}(),eS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&
"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof dS?new $CLJS.Cc(function(){return dS},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",
55,$CLJS.FF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.lx,$CLJS.V($CLJS.wj,RP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(dS)?dS.H:null])):null));return $CLJS.n(a)?a:zO}(),dS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},$CLJS.kd(uja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,
$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",57,$CLJS.EF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EF,$CLJS.lx,$CLJS.V($CLJS.wj,RP),Gja,$CLJS.V($CLJS.wj,$CLJS.Cj),$CLJS.iz,$CLJS.V($CLJS.wj,$CLJS.Cj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:yQ}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},$CLJS.kd(fla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",55,$CLJS.qs,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.lx,$CLJS.V($CLJS.wj,RP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:cR}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==
typeof dL&&"undefined"!==typeof fS?new $CLJS.Cc(function(){return fS},$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",55,$CLJS.ks,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ks,$CLJS.lx,$CLJS.V($CLJS.wj,RP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:$P}(),fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},$CLJS.kd(Vka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,
$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",56,$CLJS.GE,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GE,wQ,$CLJS.V($CLJS.wj,RP),vQ,$CLJS.V($CLJS.wj,RP),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,RP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:nN}(),iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},$CLJS.kd(ala,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.VO,"null",$CLJS.qj,"null"],
null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kM,"metabase/mbql/schema.cljc",74,$CLJS.jF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jF,$CLJS.lx,$CLJS.V($CLJS.wj,RP),$CLJS.hz,$CLJS.V($CLJS.wj,$CLJS.Cj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:kM}(),jS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==
typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof $R?new $CLJS.Cc(function(){return $R},$CLJS.kd(zP,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.VO,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
OP,"metabase/mbql/schema.cljc",58,$CLJS.kF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kF,wQ,$CLJS.V($CLJS.wj,sL),vQ,$CLJS.V($CLJS.wj,sL),FM,$CLJS.V($CLJS.wj,$CLJS.V($CLJS.yi,sL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n($R)?$R.H:null])):null));return $CLJS.n(a)?a:OP}(),$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},
$CLJS.kd(DL,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",61,$CLJS.QE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QE,$CLJS.bw,$CLJS.V($CLJS.wj,VM),
$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,EO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:lO}(),mT],null)])));
$CLJS.nT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,":field or :expression reference or expression",$CLJS.Ai,function(a){return $CLJS.n(YK(NR,a))?aR:$CLJS.n(YK(KR,a))?$CLJS.Cj:$CLJS.n(YK(OR,a))?$CLJS.ur:$CLJS.n(YK(QR,a))?$CLJS.YH:$CLJS.n(YK($CLJS.QE,a))?$CLJS.QE:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[aR,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,SR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,$CLJS.TR],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.HR],null)],null);$CLJS.Y(HQ,ZK($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.HR],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);$CLJS.Y(wO,ZK($CLJS.iF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.HR],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wO],null);$CLJS.Y(GL,ZK($CLJS.bF,$CLJS.H(["field-or-expression",$CLJS.nT])));
var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GL],null);$CLJS.Y(zQ,ZK($CLJS.CF,$CLJS.H(["field-or-expression",$CLJS.nT])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);$CLJS.Y(fN,ZK($CLJS.ME,$CLJS.H(["field-or-expression",$CLJS.nT])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);$CLJS.Y(PP,ZK($CLJS.tF,$CLJS.H(["field-or-expression",$CLJS.nT])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.Y(aN,ZK($CLJS.vi,$CLJS.H(["field-or-expression",$CLJS.nT])));
var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null);$CLJS.Y(NM,ZK($CLJS.Ij,$CLJS.H(["field-or-expression",$CLJS.nT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);$CLJS.Y(HM,ZK($CLJS.AF,$CLJS.H(["field-or-expression",$CLJS.nT,"pred",$CLJS.NS])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);$CLJS.Y(YM,ZK($CLJS.qF,$CLJS.H(["pred",$CLJS.NS])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.Y(GP,ZK($CLJS.oF,$CLJS.H(["pred",$CLJS.NS])));
var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GP],null);$CLJS.Y(dM,ZK($CLJS.vF,$CLJS.H(["field-or-expression",$CLJS.nT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dM],null);$CLJS.Y(fL,ZK($CLJS.NE,$CLJS.H(["field-or-expression",$CLJS.nT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fL],null);$CLJS.Y(jN,ZK($CLJS.uF,$CLJS.H(["field-or-expression",$CLJS.nT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null);$CLJS.Y(XN,ZK($CLJS.TE,$CLJS.H(["field-or-expression",$CLJS.nT,"percentile",VR])));
var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XN],null);$CLJS.Y(JP,ZK($CLJS.cF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JJ],null),iR],null)])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);
$CLJS.Y(FP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"unnamed aggregation clause or numeric expression",$CLJS.Ai,function(a){return $CLJS.n(YK(NR,a))?$N:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$N,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.kd($ka,
new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iO,"metabase/mbql/schema.cljc",60,$CLJS.bF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:iO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.kd(lka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",64,$CLJS.CF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:mQ}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==
typeof dL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mO,"metabase/mbql/schema.cljc",65,$CLJS.ME,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:mO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.kd(vla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,
$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.rF,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AL,"metabase/mbql/schema.cljc",76,$CLJS.vF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:AL}(),zT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.kd(Qka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WL,"metabase/mbql/schema.cljc",60,$CLJS.tF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:WL}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,
$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Nr,"metabase/mbql/schema.cljc",60,$CLJS.vi,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(uT)?uT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Nr}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof vT?new $CLJS.Cc(function(){return vT},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Rr,"metabase/mbql/schema.cljc",60,$CLJS.Ij,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Rr}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&
"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.kd($ja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mN,"metabase/mbql/schema.cljc",18,$CLJS.cF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,mka,$CLJS.V($CLJS.wj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.hr,qka,RL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:mN}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,
$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eN,"metabase/mbql/schema.cljc",62,$CLJS.oF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,$CLJS.Cn,$CLJS.V($CLJS.wj,VN)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:eN}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.kd(Eja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SL,"metabase/mbql/schema.cljc",68,$CLJS.qF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,$CLJS.Cn,$CLJS.V($CLJS.wj,VN)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:SL}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.kd(Jla,new $CLJS.h(null,1,[$CLJS.Dj,!0],
null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",66,$CLJS.AF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.AF,aQ,$CLJS.V($CLJS.wj,TP),$CLJS.Cn,$CLJS.V($CLJS.wj,VN)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:jO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},$CLJS.kd(DL,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,
new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",61,$CLJS.QE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QE,$CLJS.bw,$CLJS.V($CLJS.wj,VM),$CLJS.Si,$CLJS.V($CLJS.wj,$CLJS.V(cQ,EO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:lO}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&
"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.kd(ola,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.dF,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",
68,$CLJS.uF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:kQ}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,
eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.dF,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",72,$CLJS.TE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TE,aQ,$CLJS.V($CLJS.wj,TP),$CLJS.TE,$CLJS.V($CLJS.wj,MP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(CT)?CT.H:null])):
null));return $CLJS.n(a)?a:IP}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vL,"metabase/mbql/schema.cljc",77,$CLJS.NE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NE,aQ,$CLJS.V($CLJS.wj,TP)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:vL}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},$CLJS.kd(Aja,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,
$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",74,$CLJS.iF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,$CLJS.hF,$CLJS.V($CLJS.wj,$CLJS.V(cQ,BM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:WN}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([cO,$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,eQ,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.BE,"null"],null),null),
$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.hi,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.hF,$CLJS.V($CLJS.wj,$CLJS.V(cQ,BM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:$CLJS.hi}(),oT],null)]))],null)],null));
$CLJS.Y(YO,ZK($CLJS.qL,$CLJS.H(["aggregation",FP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.us,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Br,!0],null),iR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),iR],null)],null)])));
$CLJS.Y(bQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.us,"aggregation clause or numeric expression",$CLJS.Ai,function(a){return $CLJS.n(YK($CLJS.qL,a))?$CLJS.qL:UM}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[UM,FP],null)],null));$CLJS.Y(zL,ZK($CLJS.eF,$CLJS.H(["field",JR])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zL],null);$CLJS.Y(ZN,ZK($CLJS.YE,$CLJS.H(["field",JR])));
FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZN],null);
ama=$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.kd(sla,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
FN,"metabase/mbql/schema.cljc",15,$CLJS.eF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,$CLJS.hF,$CLJS.V($CLJS.wj,uQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:FN}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,
$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",16,$CLJS.YE,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,$CLJS.hF,$CLJS.V($CLJS.wj,uQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:AP}(),FT],null)]));
GT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Lj,$CLJS.CL,$CLJS.rM,$CLJS.AE,$CLJS.Qs,$CLJS.Qj,$CLJS.xO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,iR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,iR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),iR],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,GT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.CL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oL,iR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PO,lR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),lR],null)],null)],null);
cma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,GT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.rM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YP,lR],null)],null)],null);
HT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,GT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null);dma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);
ema=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,HT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.AE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AE,GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,$CLJS.Ki,$CLJS.vr],
null)],null)],null)],null)],null);$CLJS.IT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.xO,null,$CLJS.Qs,null,$CLJS.ur,null,$CLJS.Qj,null],null),null);fma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Lj],null),$CLJS.IT);
$CLJS.Y(tP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.Ai,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AE,ema],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CL,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rM,cma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.co,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,HT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,fma],null)],null)],null)],null)],null));
var JT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,iR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,iR],null)],null)],null),gma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,JT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.vr],null)],null)],null),KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bP],null),LT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.Ai,function(a){return $CLJS.n($CLJS.dD($CLJS.sd,
$CLJS.eP)(a))?$CLJS.eP:$CLJS.jD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,JT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eP,$CLJS.vr],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,KT],null)],null),MT=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,iR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cC,jR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ska,iR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.zN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,kR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.Hj],null)],null)],null),NT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,Tla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,new $CLJS.h(null,1,[$CLJS.us,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),hma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Lj],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.eE,null,$CLJS.OD,null,$CLJS.YD,null,$CLJS.bE,null],null),null)),OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);
$CLJS.Y(dN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),NT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LQ,$CLJS.NS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),hma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.us,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lj,$CLJS.wx,$CLJS.cz],null),OT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),iR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NN,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,MT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.dD($CLJS.zz($CLJS.aO,$CLJS.DP),$CLJS.Oe($CLJS.dD($CLJS.aO,$CLJS.DP)))],null)],null));var ima=$CLJS.Q,PT;var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null)],null);
if($CLJS.rd(QT)&&$CLJS.E.g($CLJS.z(QT),$CLJS.gj)){var RT=$CLJS.y(QT);$CLJS.z(RT);var ST=$CLJS.B(RT),jma=$CLJS.sd($CLJS.z(ST))?ST:$CLJS.ae(null,ST),TT=$CLJS.y(jma),kma=$CLJS.z(TT),lma=$CLJS.B(TT);PT=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,$CLJS.R.j(kma,$CLJS.vi,1)],null),lma)}else PT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,QT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(fM,new $CLJS.P(null,3,5,ima,[$CLJS.mr,PT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"All join aliases must be unique."],null),function(a){return $CLJS.KK($CLJS.ff($CLJS.Ta,$CLJS.cf.g($CLJS.TD,a)))}],null)],null));var mma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fM],null);
$CLJS.Y(jQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.h(null,1,[$CLJS.us,"Distinct, non-empty sequence of Field clauses"],null),aL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),$CLJS.HR],null))],null));
$CLJS.Y(bP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),LT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),NT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),$CLJS.UR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,
[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),$CLJS.HR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,iR,$CLJS.nT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),OT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.NS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fR,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),aL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),ama],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[fP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[fP,lR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tja,lR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,MT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Kl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DP,$CLJS.aO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,
new $CLJS.h(null,1,[$CLJS.us,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.WE);b=$CLJS.J.g(b,$CLJS.PD);return $CLJS.od($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.UT=$CLJS.zg([$CLJS.zF,$CLJS.xO,$M,LM,ON,XQ,uN,$CLJS.WQ,$CLJS.Qs,tN,JL,QP,MQ,$CLJS.ZL,IQ,XM,$CLJS.TN,rN,$CLJS.Mi,$CLJS.qN,rL,$CLJS.vM,$CLJS.pN,sO,$CLJS.ur,$CLJS.Qj,lN],[new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.zF,null,$CLJS.xO,null,$CLJS.Qs,null,$CLJS.ur,null,$CLJS.Qj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.zF,null,$CLJS.xO,null,$CLJS.WQ,null,$CLJS.TN,null,$CLJS.Mi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Xi,$CLJS.Cj,$CLJS.sD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$M,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,aR,$CLJS.sD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.zF,null,LM,null,$CLJS.Qs,null,$CLJS.Mi,null,rL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,$CLJS.Cj,$CLJS.sD,NL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[ON,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[XQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,
aR,$CLJS.sD,ila,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[uN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.WQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,aR,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.zF,null,LM,null,$CLJS.Qs,null,$CLJS.Mi,null,rL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,aR,$CLJS.sD,NL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[tN,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[JL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,$CLJS.Cj,$CLJS.sD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.zF,null,XQ,null,JL,null,QP,null,rN,null,$CLJS.Mi,null,rL,null,$CLJS.Qj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,$CLJS.Cj,$CLJS.sD,NL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[MQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,null,$CLJS.ZL,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Xi,$CLJS.Cj,$CLJS.sD,NL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[IQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,$CLJS.Cj,$CLJS.sD,NL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[XM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.zF,null,$CLJS.xO,null,$CLJS.WQ,null,$CLJS.TN,null,$CLJS.Mi,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[rN,null],null),null)],null),new $CLJS.h(null,1,[EN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Mi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,null,$CLJS.qN,null],null),null)],null),new $CLJS.h(null,1,[EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[rL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,null,$CLJS.vM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.xO,EN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.WQ,
null,$CLJS.pN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,aR,$CLJS.sD,ZP,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[sO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.ur,EN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.zF,null,$CLJS.Mi,null,$CLJS.ur,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.Cj,EN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.zF,null,XQ,null,JL,null,QP,null,rN,null,$CLJS.Mi,null,rL,null,$CLJS.Qj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Xi,
aR,$CLJS.sD,NL,EN,new $CLJS.Rg(null,new $CLJS.h(null,1,[lN,null],null),null)],null)]);$CLJS.Y(lQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.UT)));$CLJS.Y(VL,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.UT)));
$CLJS.Y(yP,ZK(Kja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,iR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,iR],null)],null)],null)])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yP],null);$CLJS.Y(uL,ZK($CLJS.AE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.HR,VT],null)])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uL],null);$CLJS.Y(pM,ZK(oN,$CLJS.H(["target",VT])));
var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null),nma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.HR,$K($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof WT?new $CLJS.Cc(function(){return WT},$CLJS.kd(Ika,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,
1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",21,$CLJS.AE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AE,$CLJS.lI,$CLJS.V($CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,BM,DN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:jP}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=eO.h($CLJS.ld("undefined"!==typeof $CLJS.bL&&
"undefined"!==typeof cL&&"undefined"!==typeof dL&&"undefined"!==typeof XT?new $CLJS.Cc(function(){return XT},$CLJS.kd(Xka,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,eO,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,aM,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.kd(KP,new $CLJS.h(null,1,[$CLJS.Jj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",20,oN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[oN,
$CLJS.lI,$CLJS.V($CLJS.wj,DN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:bR}(),XT],null)]))],null),VK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bM],null);
$CLJS.Y(bM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.dD($CLJS.zz($CLJS.eP,$CLJS.DE),$CLJS.Oe($CLJS.dD($CLJS.eP,$CLJS.DE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.eP);var d=$CLJS.J.g(c,$CLJS.DE);c=$CLJS.J.g(c,$CLJS.Xi);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.us,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$J],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.UJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lj,$CLJS.DE,$CLJS.eP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),KT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,dma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),iR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lI,new $CLJS.h(null,1,[$CLJS.Br,!0],null),nma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jj,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Br,!0],null),iR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),
iR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),vR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Hka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[kO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[UL,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,kO);b=$CLJS.J.g(b,UL);return $CLJS.Sa($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[xka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[rla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[vja,new $CLJS.h(null,1,[$CLJS.Br,
!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[jla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[xja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[xla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.ur],
null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.Lj,$CLJS.lj,Uka,$CLJS.hN,Wja,Rla,Yka,nka,Jka,fka,Nja,jka,gla,vka,Hja,Xja,Qla,Nla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[mla,new $CLJS.h(null,1,[$CLJS.Br,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ska,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,Sla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ila,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,iR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[uka,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,$CLJS.vr,$CLJS.vr],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[oka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,$CLJS.vr,$CLJS.vr],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.vr],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Oe($CLJS.NN)],
null)],null));var YT=$CLJS.UK(VK);(function(){var a=qja();return function(b){if($CLJS.n(YT.h?YT.h(b):YT.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.RK(b);throw $CLJS.Uh($CLJS.fD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Cka,b],null));}})();