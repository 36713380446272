var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Xga,Yga,Zga,$ga,aha,bha,cha,LF,dha,eha,fha,gha,hha,iha,jha,kha,NF,lha;$CLJS.HF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Xga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.IF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Yga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Zga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.JF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$ga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.KF=new $CLJS.M(null,"display-info","display-info",-816930907);aha=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);bha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
cha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);LF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);dha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);eha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);fha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);gha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
hha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);iha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);jha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.MF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);kha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);NF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.OF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);lha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.wE($CLJS.Rw,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)],null)],null)]));$CLJS.wE($CLJS.iF,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)],null)],null)]));
$CLJS.uE($CLJS.bF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.uE($CLJS.ME,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)]));$CLJS.uE($CLJS.qF,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));
$CLJS.uE($CLJS.Ij,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)]));$CLJS.nE($CLJS.Ij,$CLJS.$D);$CLJS.uE($CLJS.uF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.nE($CLJS.uF,$CLJS.$D);$CLJS.uE($CLJS.vi,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)]));$CLJS.nE($CLJS.vi,$CLJS.$D);
$CLJS.Y(LF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.us,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.uE($CLJS.TE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LF],null)]));$CLJS.nE($CLJS.TE,$CLJS.$D);
$CLJS.uE($CLJS.oF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));$CLJS.uE($CLJS.vF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.uE($CLJS.tF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));
$CLJS.uE($CLJS.CF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.nE($CLJS.tF,$CLJS.$D);$CLJS.nE($CLJS.CF,$CLJS.$D);$CLJS.uE($CLJS.AF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));$CLJS.nE($CLJS.AF,$CLJS.$D);
$CLJS.uE($CLJS.NE,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.Y(NF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.hr,kha,iha,bha,Yga,Xga,aha,cha,dha,eha,hha,fha,lha,jha,Zga,gha,$CLJS.hl],null));$CLJS.Y($CLJS.IF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NF],null)],null));
$CLJS.PF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.aF,$CLJS.Rw,$CLJS.JF,!1,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Count of rows"),$CLJS.SE,$CLJS.HD("Count"),$CLJS.Is,$CLJS.HD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.tF,$CLJS.HF,$CLJS.Fj,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Sum of ..."),$CLJS.SE,$CLJS.HD("Sum"),$CLJS.Is,$CLJS.HD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.bF,$CLJS.HF,$CLJS.Fj,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Average of ..."),$CLJS.SE,$CLJS.HD("Average"),$CLJS.Is,$CLJS.HD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.uF,$CLJS.HF,$CLJS.Fj,$CLJS.JF,!0,$CLJS.MF,$CLJS.dF,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Median of ..."),$CLJS.SE,$CLJS.HD("Median"),$CLJS.Is,$CLJS.HD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.ME,$CLJS.HF,$CLJS.vr,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Number of distinct values of ..."),$CLJS.SE,$CLJS.HD("Distinct values"),$CLJS.Is,$CLJS.HD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.CF,$CLJS.HF,$CLJS.Fj,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Cumulative sum of ..."),
$CLJS.SE,$CLJS.HD("Sum"),$CLJS.Is,$CLJS.HD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.aF,$CLJS.iF,$CLJS.JF,!1,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Cumulative count of rows"),$CLJS.SE,$CLJS.HD("Count"),$CLJS.Is,$CLJS.HD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.vF,$CLJS.HF,$CLJS.Fj,$CLJS.JF,
!0,$CLJS.MF,$CLJS.rF,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Standard deviation of ..."),$CLJS.SE,$CLJS.HD("SD"),$CLJS.Is,$CLJS.HD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.vi,$CLJS.HF,$CLJS.Li,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Minimum of ..."),$CLJS.SE,$CLJS.HD("Min"),$CLJS.Is,$CLJS.HD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.Ij,$CLJS.HF,$CLJS.Li,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.kD,$CLJS.HD("Maximum of ..."),$CLJS.SE,$CLJS.HD("Max"),$CLJS.Is,$CLJS.HD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y($ga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.OF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Lj],null),$CLJS.cf.h($CLJS.aF),$CLJS.PF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.Ki],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.ur],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.MF,$CLJS.Ki],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KF,$CLJS.id],null)],null));