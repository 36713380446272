var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var QV,SV,UV,VV,Yma,XV,YV,PV,ZV,bW,Zma,$ma,ana,bna,dW,eW,gW,hW,iW,jW,kW,lW,dna,ena,nW,fna,oW,gna,qW,hna,ina,jna,kna,lna,MV;$CLJS.NV=function(a,b){if("string"===typeof b)return MV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.OV=function(a,b,c){var d=$CLJS.Ll(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Cb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
QV=function(a){var b=PV;return $CLJS.Iz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.RV=function(a,b){return $CLJS.Iz(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};SV=function(a){if($CLJS.id(a))return a;throw $CLJS.Uh("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.si,a],null));};
$CLJS.TV=function(a,b,c){return $CLJS.sd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,X=G;G=$CLJS.Yd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[X,G],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.Yd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):$CLJS.rd(c)?$CLJS.sk.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),c):c};UV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.de(a):b;return $CLJS.n(b)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
VV=function(a,b){var c=$CLJS.rd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Yma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.ud(c)&&1<=$CLJS.D(c))try{var e=$CLJS.xk.j(c,0,1);if($CLJS.ud(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.xk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Ta,l)))},$CLJS.H([b]))};
XV=function(a,b){for(;;)switch(b=$CLJS.Kk.h($CLJS.ff($CLJS.Ta,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),WV.h?WV.h(a):WV.call(null,a);default:if($CLJS.n($CLJS.Me($CLJS.Qe(VV,a),b))){var c=a;b=Yma(a,b);a=c}else{c=$CLJS.cf.g(WV,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};YV=function(a){var b=$CLJS.rd(a);return b?(b=!$CLJS.uf(a))?$CLJS.zz($CLJS.be,$CLJS.Va)($CLJS.z(a)):b:b};
PV=function(a){return $CLJS.n($CLJS.zz($CLJS.be,$CLJS.Va)(a))?$CLJS.mh.h($CLJS.Gz(UV(a).toLowerCase(),/_/,"-")):a};ZV=function(a,b){var c=YV(b);return $CLJS.n(c)?(b=PV($CLJS.z(b)),$CLJS.pd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};
bW=function(a){a=$V.g?$V.g(a,aW):$V.call(null,a,aW);var b=$CLJS.n($CLJS.tA.h(a))?$CLJS.tk.j(a,$CLJS.tA,$CLJS.mh):a;b=$CLJS.n($CLJS.nF.h(a))?$CLJS.tk.j(b,$CLJS.nF,$CLJS.mh):b;return $CLJS.n($CLJS.pQ.h(a))?$CLJS.tk.j(b,$CLJS.pQ,function(c){return $CLJS.n($CLJS.AD.h(c))?$CLJS.tk.j(c,$CLJS.AD,$CLJS.mh):c}):b};
Zma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Pd,$CLJS.Xi,PV,$CLJS.MM,PV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ae([a,b]);b=$V.g?$V.g(b,aW):$V.call(null,b,aW);return d.call(c,b,a)})};
$ma=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=PV(c);var d=Zma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Xi.h(a),$CLJS.AE)&&$CLJS.Sa($CLJS.MM.h(a))?$CLJS.R.j(a,$CLJS.MM,$CLJS.zF):a};ana=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=UV(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j($ma(b),$CLJS.T,c)],null)}),a)};
bna=function(a){a=$V.h?$V.h(a):$V.call(null,a);return cW.h?cW.h(a):cW.call(null,a)};dW=function(a){return $CLJS.OV($CLJS.OV($CLJS.bb(function(b,c){return $CLJS.OV(b,c,$CLJS.mh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.cC,$CLJS.wga,$CLJS.zN,cna,$CLJS.Yy,$CLJS.di],null)),$CLJS.fB,bna),$CLJS.OM,$CLJS.qU)};eW=function(a){return $CLJS.Ad(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,null],null):a};gW=function(a){return fW.h(eW(a))};
hW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return WV($CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(fW,b)))};iW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,gW(b)],null),$CLJS.cf.g(fW,c))};jW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,gW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
kW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,gW(a)],null)};lW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,fW.h(a)],null)};dna=function(a){return $CLJS.yf($CLJS.Hl(cW,mW(a)))};ena=function(a){for(;;)if($CLJS.n(YV(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Be($CLJS.sk.g(eW,a))};
nW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.AE);return $CLJS.n(b)?$CLJS.tk.j(a,$CLJS.AE,fW):a};
fna=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,nW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,
2,5,$CLJS.Q,[l,nW(f)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};oW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.BP);return $CLJS.n(b)?$CLJS.tk.j(a,$CLJS.BP,fna):a};gna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.eP);b=$CLJS.Sa(a)?pW.h?pW.h(b):pW.call(null,b):b;return $CLJS.n(a)?oW(b):b};qW=function(a){return $CLJS.pd(a)?$CLJS.y(a):null!=a};
hna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.DE);var c=$CLJS.J.g(b,$CLJS.qM),d=$CLJS.J.g(b,$CLJS.NN),e=$CLJS.J.g(b,$CLJS.eP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.NN);var l=$CLJS.SK($CLJS.jk.g(f,$CLJS.NN),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.NN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.tk.j(l,$CLJS.DE,pW):l,t=$CLJS.n(c)?$CLJS.tk.j(m,$CLJS.qM,$CLJS.Qe($CLJS.sk,cW)):m,u=$CLJS.n(e)?$CLJS.tk.j(t,$CLJS.eP,oW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.eP);return $CLJS.n(x)?$CLJS.R.j(cW($CLJS.jk.g(v,
$CLJS.eP)),$CLJS.eP,x):cW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Th($CLJS.fD("Error canonicalizing query: {0}",$CLJS.H([$CLJS.oU(m)])),new $CLJS.h(null,1,[$CLJS.DE,a],null),m);throw A;}};
ina=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.WE);c=$CLJS.J.g(c,$CLJS.PD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.rB,$CLJS.y($CLJS.ff($CLJS.Ta,function k(e,f){try{if($CLJS.ud(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ce(l,$CLJS.hF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,m,$CLJS.jk.g(t,$CLJS.nF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.vU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.rW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.PD],null),$CLJS.qk.g($CLJS.yf,$CLJS.Qe($CLJS.rk,b)))):a};
jna=function(a){try{return ina(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Th($CLJS.HD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.DE,a],null),b);}throw c;}};
kna=function(a,b){var c=$CLJS.Wf.g($CLJS.fd(a),function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Yd.g(b,G);C=sW.g?sW.g(C,K):sW.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Yd.g(b,v);return sW.g?sW.g(A,C):sW.call(null,A,C)}();if(null!=u)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};lna=function(a,b){a=$CLJS.sk.g(function(c){var d=$CLJS.Yd.g(b,tW);return sW.g?sW.g(c,d):sW.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Ta,a))?a:null};
MV=function MV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ae(f,new $CLJS.ie(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),MV.g?MV.g(a,k):MV.call(null,a,k)):null},null,null))};
$CLJS.rW=function rW(a){switch(arguments.length){case 3:return rW.j(arguments[0],arguments[1],arguments[2]);case 4:return rW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return rW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return rW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return rW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.rW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.rW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.rW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.rW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.rW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.rW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.rW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.rW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.rW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ne.l($CLJS.rW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ne.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.rW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.rW.A=6;
var WV=function WV(a){for(;;){if($CLJS.sd(a))return $CLJS.hs(a,WV);if($CLJS.yd(a))a=$CLJS.yf(a);else if($CLJS.ud(a)){if($CLJS.Sa($CLJS.Me($CLJS.Ta,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.ql(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.dd(m);continue;case "and":return XV($CLJS.hr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.jr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));case "or":return XV($CLJS.mr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));default:return a}}else return a;case "and":return XV($CLJS.mr,t);case "or":return XV($CLJS.hr,t);default:return $CLJS.sk.g(WV,a)}}else return a}},mna=new $CLJS.M(null,"value_field","value_field",-980977878),uW=new $CLJS.M(null,"ascending","ascending",-988350486),
vW=new $CLJS.M(null,"named","named",-422393479),wW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),xW=new $CLJS.M(null,"descending","descending",-24766135),yW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),nna=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),zW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),AW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),ona=new $CLJS.M(null,"rows","rows",850049680),pna=
new $CLJS.M(null,"special-fn","special-fn",1290649344),tW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),aW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),qna=new $CLJS.M(null,"label_field","label_field",-1573182765),rna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),sna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),cna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var BW,tna=$CLJS.Se($CLJS.N),una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.Se($CLJS.N),xna=$CLJS.J.j($CLJS.N,$CLJS.Ui,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));BW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.qk.g(PV,$CLJS.z),xna,tna,una,vna,wna);BW.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,b],null);return null!=a?$CLJS.Yd.g(b,a):b});
BW.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,b instanceof $CLJS.M?UV(b):b],null);a=$CLJS.Be($CLJS.RV(new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.tA,null,$CLJS.nF,null,$CLJS.pQ,null],null),null),bW(a)));return $CLJS.n(a)?$CLJS.Yd.g(b,a):b});
BW.m(null,$CLJS.xU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Yd.g(BW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xU,$V.g?$V.g(b,aW):$V.call(null,b,aW),PV(c)],null)});BW.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,bW(a)],null)});
BW.m(null,zW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[zW,b instanceof $CLJS.M?UV(b):b,$CLJS.mh.h(a)],null)});BW.m(null,yW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[yW,$V.g?$V.g(b,aW):$V.call(null,b,aW),$CLJS.Yr,PV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[yW,$V.g?$V.g(b,aW):$V.call(null,b,aW),PV(c)],null)});
BW.m(null,$CLJS.dB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Yd.g(BW.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,b,c,d],null)),$V.g?$V.g(a,aW):$V.call(null,a,aW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,$V.g?$V.g(b,aW):$V.call(null,b,aW),$CLJS.Ad(c)?c:PV(c),PV(d)],null)});
BW.m(null,$CLJS.$A,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$A,b,PV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,$CLJS.Qu],null)});BW.m(null,$CLJS.ZA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZA,b,PV(a)],null)});
BW.m(null,$CLJS.bB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,$V.g?$V.g(b,aW):$V.call(null,b,aW),c,PV(a)],null)});BW.m(null,$CLJS.eB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.eB,$V.g?$V.g(b,aW):$V.call(null,b,aW),c,PV(a)],null)});
BW.m(null,$CLJS.aB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aB,$V.g?$V.g(b,aW):$V.call(null,b,aW),PV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,$V.g?$V.g(b,aW):$V.call(null,b,aW)],null)});
BW.m(null,$CLJS.cB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,$V.g?$V.g(b,aW):$V.call(null,b,aW),PV(c),PV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cB,$V.g?$V.g(b,aW):$V.call(null,b,aW),PV(c)],null)});
BW.m(null,$CLJS.YA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YA,$V.g?$V.g(b,aW):$V.call(null,b,aW),$V.g?$V.g(c,aW):$V.call(null,c,aW),PV(a)],null)});BW.m(null,$CLJS.jj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jj,b,a],null)});
BW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[PV(a)],null),$CLJS.cf.h(function(c){return $V.g?$V.g(c,aW):$V.call(null,c,aW)}),b)});
var CW=function CW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Va)(a))){var c=PV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.vi,null,$CLJS.Qq,null,$CLJS.vF,null,$CLJS.qF,null,$CLJS.$r,null,$CLJS.iF,null,$CLJS.AF,null,$CLJS.BF,null,$CLJS.ME,null,$CLJS.TE,null,$CLJS.Pq,null,$CLJS.NE,null,$CLJS.uF,null,$CLJS.oF,null,$CLJS.Ij,null,$CLJS.Rw,null,$CLJS.bF,null,$CLJS.tF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(YV(a))?(a=$CLJS.z(a),CW.h?CW.h(a):CW.call(null,
a)):null},yna=new $CLJS.h(null,8,[$CLJS.Xi,PV,$CLJS.eP,function(a){a=QV(a);return $CLJS.y($CLJS.BP.h(a))?$CLJS.tk.j(a,$CLJS.BP,ana):a},$CLJS.DE,new $CLJS.h(null,6,[$CLJS.XE,function DW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Va)(a)))return PV(a);if($CLJS.n(ZV(vW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[vW,DW.h?DW.h(a):DW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(YV(a))?CW($CLJS.dd(a)):null)?$CLJS.sk.g(DW,a):$V.g?$V.g(a,
aW):$V.call(null,a,aW)},$CLJS.VO,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[UV(u),$V.g?$V.g(t,aW):$V.call(null,t,aW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[UV(l),$V.g?$V.g(f,aW):$V.call(null,f,aW)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.JQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n(YV(t))?BW.h(t):$CLJS.Zd(BW.h($CLJS.Zd(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}l=$CLJS.z(e);return $CLJS.ae($CLJS.n(YV(l))?BW.h(l):$CLJS.Zd(BW.h($CLJS.Zd(l))),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.DP,function(a){a=QV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.eP);if($CLJS.n(b))return a=$CLJS.uU(a,new $CLJS.h(null,1,[$CLJS.eP,$CLJS.DE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eP],null),a=$V.g?$V.g(a,b):$V.call(null,a,b),$CLJS.uU(a,new $CLJS.h(null,1,[$CLJS.DE,$CLJS.eP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return $V.g?$V.g(a,b):$V.call(null,a,b)},$CLJS.NN,new $CLJS.h(null,1,[tW,dW],null),$CLJS.zM,new $CLJS.h(null,1,[tW,function(a){a=$V.g?$V.g(a,$CLJS.DE):$V.call(null,a,$CLJS.DE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.AD),d=$CLJS.J.g(b,$CLJS.PD);a=$CLJS.J.g(b,$CLJS.TD);b=$CLJS.n(c)?$CLJS.tk.j(b,$CLJS.AD,PV):b;d=$CLJS.n($CLJS.zz($CLJS.be,$CLJS.Va)(d))?$CLJS.tk.j(b,$CLJS.PD,PV):b;return $CLJS.n(a)?$CLJS.tk.j(d,$CLJS.TD,UV):d}],null)],null),$CLJS.Gx,
new $CLJS.h(null,1,[$CLJS.kN,$CLJS.Pd],null),$CLJS.qM,new $CLJS.h(null,1,[tW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Xi),d=$CLJS.J.g(b,$CLJS.lI),e=$CLJS.J.g(b,$CLJS.Mi);a=$CLJS.J.g(b,wW);b=$CLJS.n(e)?$CLJS.tk.j(b,$CLJS.Mi,UV):b;c=$CLJS.n(c)?$CLJS.tk.j(b,$CLJS.Xi,PV):b;d=$CLJS.n(d)?$CLJS.tk.j(c,$CLJS.lI,function(f){return $V.g?$V.g(f,aW):$V.call(null,f,aW)}):c;d=$CLJS.n(a)?$CLJS.rW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[wW,qna],null),function(f){return $V.g?$V.g(f,aW):$V.call(null,f,aW)}):d;return $CLJS.n(a)?
$CLJS.rW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[wW,mna],null),function(f){return $V.g?$V.g(f,aW):$V.call(null,f,aW)}):d}],null),$CLJS.lM,function(a){return null==a?null:PV(a)},$CLJS.NN,new $CLJS.h(null,1,[tW,dW],null),AW,PV],null),$V=function $V(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return $V.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$V.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.Bz(yna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=PV(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,$V.l(x,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=PV(u);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[u,$V.l(m,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),u)]))],null),k($CLJS.Hc(l)))}return null}},null,null)}(a)}()):$CLJS.n(YV(a))?BW.h(a):$CLJS.rd(a)?$CLJS.sk.g(function(e){return $V.l(e,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),tW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.fD("Error normalizing form: {0}",$CLJS.H([$CLJS.oU(d)])),new $CLJS.h(null,3,[$CLJS.Hi,a,$CLJS.Wk,c,pna,b],null),d);}throw e;}};$V.A=1;$V.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var fW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ui,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(YV(f))?$CLJS.z(f):null},e,a,b,c,d)}();fW.m(null,$CLJS.Oh,function(a){return a});
fW.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(ZV($CLJS.hF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return fW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,$CLJS.Be($CLJS.Jk.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,$CLJS.Be(a)],null)});
fW.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.od(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,b,a],null)});fW.m(null,$CLJS.SJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(YV(a))?fW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,null],null)});
fW.m(null,zW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,new $CLJS.h(null,1,[$CLJS.tA,a],null)],null)});fW.m(null,rna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=gW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=gW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,$CLJS.R.j(c,$CLJS.AM,b)],null)});
fW.m(null,sna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=gW(a);return $CLJS.Ne.v($CLJS.CU,a,$CLJS.R,$CLJS.H([$CLJS.pO,b]))});
fW.m(null,yW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=gW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.tA);$CLJS.Sa(d)||$CLJS.FR.g(d,b)?a=$CLJS.Ne.v($CLJS.CU,a,$CLJS.R,$CLJS.H([$CLJS.nF,b])):(c=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.mbql.util",c))&&(b=$CLJS.fD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv(),b):$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),fW.h(new $CLJS.P(null,3,5,$CLJS.Q,[yW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
fW.m(null,$CLJS.xU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=gW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,$CLJS.R.j(e,$CLJS.pQ,$CLJS.Jk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.AD,c],null),$CLJS.n(d)?$CLJS.Ae([c,d]):null,a])))],null)});
for(var EW=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.hr,$CLJS.jr],null)),FW=null,GW=0,HW=0;;)if(HW<GW){var IW=FW.X(null,HW);fW.m(null,IW,function(){return function(a){return hW(a)}}(EW,FW,GW,HW,IW));HW+=1}else{var JW=$CLJS.y(EW);if(JW){var KW=JW;if($CLJS.vd(KW)){var LW=$CLJS.ic(KW),zna=$CLJS.jc(KW),Ana=LW,Bna=$CLJS.D(LW);EW=zna;FW=Ana;GW=Bna}else{var MW=$CLJS.z(KW);fW.m(null,MW,function(){return function(a){return hW(a)}}(EW,FW,GW,HW,MW,KW,JW));EW=$CLJS.B(KW);FW=null;GW=0}HW=0}else break}
fW.m(null,$CLJS.PE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,gW(a),gW(b)],null),c)});fW.m(null,$CLJS.dB,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=gW(a);a=$CLJS.n(VV($CLJS.hF,a))?$CLJS.CU.l(c,$CLJS.jk,$CLJS.H([$CLJS.nF])):c;return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dB,a],null),b)});
for(var NW=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZE,$CLJS.sF,$CLJS.gF,$CLJS.$E,$CLJS.Tj,$CLJS.xF,$CLJS.Lq,$CLJS.Nq,$CLJS.Hq,$CLJS.Jq,$CLJS.KE,$CLJS.RE,$CLJS.IE,$CLJS.UE,$CLJS.JE],null)),OW=null,PW=0,QW=0;;)if(QW<PW){var RW=OW.X(null,QW);fW.m(null,RW,function(){return function(a){return iW(a)}}(NW,OW,PW,QW,RW));QW+=1}else{var SW=$CLJS.y(NW);if(SW){var TW=SW;if($CLJS.vd(TW)){var UW=$CLJS.ic(TW),Cna=$CLJS.jc(TW),Dna=UW,Ena=$CLJS.D(UW);NW=Cna;OW=Dna;PW=Ena}else{var VW=$CLJS.z(TW);fW.m(null,VW,
function(){return function(a){return iW(a)}}(NW,OW,PW,QW,VW,TW,SW));NW=$CLJS.B(TW);OW=null;PW=0}QW=0}else break}fW.m(null,ona,function(){return null});fW.m(null,$CLJS.qL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qL,fW.h(b),a],null)});
fW.m(null,vW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=fW.h;var e=$CLJS.Q;b=fW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,nna)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.kD,a],null);return c.call(fW,new $CLJS.P(null,3,5,e,[$CLJS.qL,b,a],null))});
for(var WW=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rw,$CLJS.iF],null)),XW=null,YW=0,ZW=0;;)if(ZW<YW){var $W=XW.X(null,ZW);fW.m(null,$W,function(){return function(a){return jW(a)}}(WW,XW,YW,ZW,$W));ZW+=1}else{var aX=$CLJS.y(WW);if(aX){var bX=aX;if($CLJS.vd(bX)){var cX=$CLJS.ic(bX),Fna=$CLJS.jc(bX),Gna=cX,Hna=$CLJS.D(cX);WW=Fna;XW=Gna;YW=Hna}else{var dX=$CLJS.z(bX);fW.m(null,dX,function(){return function(a){return jW(a)}}(WW,XW,YW,ZW,dX,bX,aX));WW=$CLJS.B(bX);XW=null;YW=0}ZW=0}else break}
for(var eX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bF,$CLJS.CF,$CLJS.ME,$CLJS.vF,$CLJS.tF,$CLJS.vi,$CLJS.Ij,$CLJS.uF,$CLJS.NE],null)),fX=null,gX=0,hX=0;;)if(hX<gX){var iX=fX.X(null,hX);fW.m(null,iX,function(){return function(a){return kW(a)}}(eX,fX,gX,hX,iX));hX+=1}else{var jX=$CLJS.y(eX);if(jX){var kX=jX;if($CLJS.vd(kX)){var lX=$CLJS.ic(kX),Ina=$CLJS.jc(kX),Jna=lX,Kna=$CLJS.D(lX);eX=Ina;fX=Jna;gX=Kna}else{var mX=$CLJS.z(kX);fW.m(null,mX,function(){return function(a){return kW(a)}}(eX,fX,gX,hX,
mX,kX,jX));eX=$CLJS.B(kX);fX=null;gX=0}hX=0}else break}fW.m(null,$CLJS.TE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,gW(b),a],null)});
for(var nX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oF,$CLJS.qF],null)),oX=null,pX=0,qX=0;;)if(qX<pX){var rX=oX.X(null,qX);fW.m(null,rX,function(){return function(a){return lW(a)}}(nX,oX,pX,qX,rX));qX+=1}else{var sX=$CLJS.y(nX);if(sX){var tX=sX;if($CLJS.vd(tX)){var uX=$CLJS.ic(tX),Lna=$CLJS.jc(tX),Mna=uX,Nna=$CLJS.D(uX);nX=Lna;oX=Mna;pX=Nna}else{var vX=$CLJS.z(tX);fW.m(null,vX,function(){return function(a){return lW(a)}}(nX,oX,pX,qX,vX,tX,sX));nX=$CLJS.B(tX);oX=null;pX=0}qX=0}else break}
fW.m(null,$CLJS.AF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,fW.h(b),fW.h(a)],null)});
fW.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Yd.g(fW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,b],null)),$V.l(a,$CLJS.H([aW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,$CLJS.yf(function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[fW.h(v),fW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[fW.h(m),fW.h(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}(b)}())],null)});
fW.m(null,$CLJS.zE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,fW.h(a),$CLJS.E.g(0,b)?1:fW.h(b)],null),$CLJS.cf.g(fW,c))});
var cW=function cW(a){if($CLJS.td(a))return $CLJS.Od(function(d,e,f){return $CLJS.R.j(d,e,cW.h?cW.h(f):cW.call(null,f))},a,a);if($CLJS.sd(a))return $CLJS.hs(a,cW);if($CLJS.n(YV(a))){var c=function(){try{return fW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Mz($CLJS.Cx);if($CLJS.n($CLJS.Lz("metabase.mbql.normalize",e))){var f=$CLJS.HD("Invalid clause:");f instanceof Error?$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([a])),f):$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([f,
a])),null)}throw $CLJS.Th($CLJS.fD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.oU(d)])),new $CLJS.h(null,1,[$CLJS.UQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Yd.g($CLJS.fd(c),$CLJS.z(c)),$CLJS.cf.h(cW),$CLJS.Hc(c)):c}return $CLJS.yd(a)?$CLJS.sk.g(cW,a):$CLJS.pd(a)?$CLJS.Wf.j($CLJS.fd(a),$CLJS.cf.h(cW),a):a},mW=function mW(a){return function f(d,e){try{if($CLJS.n(function(){var x=SV($CLJS.yd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=SV($CLJS.be);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.ud(e)&&2<=$CLJS.D(e))try{var k=$CLJS.xk.j(e,0,2);if($CLJS.ud(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.dD($CLJS.be,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Qq,null,$CLJS.$r,null,$CLJS.BF,null,$CLJS.Pq,null,
vW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(CW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.eD(mW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.ud(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.xk.j(e,0,1);if($CLJS.ud(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},wX=function wX(a){return function f(d,e){try{var k=SV($CLJS.yd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.ud(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ce(m,$CLJS.eF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,
$CLJS.YE))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,uW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,xW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(m=X,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ce(v,uW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null));throw $CLJS.Z;}catch(U){if(U instanceof Error)if(m=U,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,xW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.eF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,gW(t)],null);throw $CLJS.Z;}catch(oa){if(oa instanceof
Error)if(m=oa,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.YE))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,gW(t)],null);throw $CLJS.Z;}catch(Oa){if(Oa instanceof Error){t=Oa;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Oa;}else throw m;else throw oa;}else throw m;else throw da;}else throw m;else throw U;}else throw m;else throw X;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.ud(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.xk.j(e,0,0);if($CLJS.ud(x)&&0===$CLJS.D(x))try{var A=$CLJS.xk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.Kk.h($CLJS.cf.g(wX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.TV(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.xf,a)},pW=$CLJS.qk.g(cW,function(a){var b=qW($CLJS.XE.h(a))?$CLJS.tk.j(a,$CLJS.XE,dna):a;b=qW($CLJS.WE.h(a))?$CLJS.tk.j(b,$CLJS.WE,ena):b;b=qW($CLJS.PD.h(a))?$CLJS.tk.j(b,$CLJS.PD,$CLJS.Qe($CLJS.sk,eW)):b;b=qW($CLJS.JQ.h(a))?$CLJS.tk.j(b,$CLJS.JQ,wX):b;return qW($CLJS.DP.h(a))?$CLJS.tk.j(b,$CLJS.DP,gna):b}),Ona=new $CLJS.h(null,3,[$CLJS.eP,$CLJS.Pd,$CLJS.DE,new $CLJS.h(null,2,[$CLJS.DP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.eP);if($CLJS.n(b))return a=
$CLJS.uU(a,new $CLJS.h(null,1,[$CLJS.eP,$CLJS.DE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eP],null),a=sW.g?sW.g(a,b):sW.call(null,a,b),$CLJS.uU(a,new $CLJS.h(null,1,[$CLJS.DE,$CLJS.eP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return sW.g?sW.g(a,b):sW.call(null,a,b)},$CLJS.zM,new $CLJS.h(null,1,[tW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return sW.g?sW.g(a,b):sW.call(null,a,b)}],null)],null),AW,$CLJS.Pd],null),sW=function sW(a){switch(arguments.length){case 1:return sW.h(arguments[0]);
case 2:return sW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};sW.h=function(a){return sW.g(a,$CLJS.xf)};sW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.Bz(Ona,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?kna(a,b):$CLJS.rd(a)?lna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Hi,a,$CLJS.Wk,b],null),d);throw d;}};sW.A=2;
$CLJS.xX=function(){var a=$CLJS.qk.l(sW,jna,hna,$CLJS.H([$V]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.fD("Error normalizing query: {0}",$CLJS.H([$CLJS.oU(c)])),new $CLJS.h(null,1,[$CLJS.DE,b],null),c);}throw d;}}}();$CLJS.yX=function yX(a,b){if($CLJS.Sa($CLJS.y(a)))a=(0,$CLJS.xX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.fs(a);b=$CLJS.Ae([$CLJS.ed(a),b]);f=yX.g?yX.g(f,b):yX.call(null,f,b);a=e.call(d,f,$CLJS.ed(a))}return a};