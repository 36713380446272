var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Xqa,Yqa,Zqa,$qa,g3,h3,i3,j3,ara,bra,cra,dra,era,fra,gra,hra,ira,t3,u3,jra,kra,lra,s3,r3,mra,v3;$CLJS.a3=function(a,b){$CLJS.bb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Xqa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.kl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Yqa=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Cd(k,t))l=$CLJS.Hc(m);else{k=$CLJS.ae(l,f($CLJS.Hc(m),$CLJS.Yd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
Zqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.y($CLJS.Bz(c,d)))?c:$CLJS.Ne.M($CLJS.rW,c,d,e,b)};$CLJS.b3=function(a){return(0,$CLJS.ma)($CLJS.Gz(a,$CLJS.Xma,""))};$CLJS.c3=function(a,b){return $CLJS.o0.g(a,b instanceof $CLJS.M?b:$CLJS.di.h(b))};
$CLJS.d3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.sk.g($CLJS.lV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.R1.v(a,b,f,c)}),d);return $CLJS.sk.g(function(f){return $CLJS.R.j(f,$CLJS.IY,$CLJS.Cd(e,f))},c)}return null};$CLJS.e3=function(a){return $CLJS.E.g($CLJS.XA(a),$CLJS.jK)};$qa=function(a,b){return $CLJS.n($CLJS.PD.h(a))?$CLJS.tk.j(a,$CLJS.PD,function(c){return $CLJS.rd(c)?$CLJS.sk.g(function(d){return $CLJS.f3.g?$CLJS.f3.g(d,b):$CLJS.f3.call(null,d,b)},c):c}):a};
g3=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.ud(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.ud(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ce(d,$CLJS.hF))try{var e=$CLJS.F(a,3);if($CLJS.ud(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ce(f,$CLJS.hF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Ta,k)))}else a=null;return a};h3=function(a){if($CLJS.n(g3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};i3=function(a){return $CLJS.n(g3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
j3=function(a,b){var c=$CLJS.f3;if($CLJS.Sa(g3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ne.j(c,a,b)],null)};
ara=function(a,b,c){return $CLJS.od($CLJS.rK.h(a))?a:$CLJS.n(b)?Zqa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rK],null),function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.hF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.yc===t.mf||(t.C?0:$CLJS.Xa($CLJS.ub,t)):$CLJS.Xa($CLJS.ub,t))try{var u=$CLJS.J.j(t,$CLJS.pO,$CLJS.yU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.f3.g?$CLJS.f3.g(k,c):$CLJS.f3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.TV(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.tk.j(a,$CLJS.rK,function(d){return $CLJS.sk.g(function(e){return j3(e,$CLJS.H([c]))},d)})};
$CLJS.f3=function(a,b){var c=$CLJS.XA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.ED(a,$CLJS.CD,$CLJS.H([$CLJS.pO,b]));case "metadata/column":return $CLJS.CD(a,$CLJS.aZ,b);case "mbql/join":return c=$CLJS.f1(a),ara($qa($CLJS.CD(a,$CLJS.TD,b),b),c,b);default:return b=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.join",b))&&$CLJS.Kz("metabase.lib.join",b,$CLJS.xv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.k3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.zz($CLJS.qY,$CLJS.T)(c);a=$CLJS.g1($CLJS.TD.h(a),$CLJS.zz($CLJS.qY,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.qY,e,$CLJS.H([$CLJS.GY,b]))};bra=function(a,b){return $CLJS.Sa(b)?a:$CLJS.sk.g(function(c){var d=i3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.f1(d))?null:j3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.l3=function(a,b){b=bra($CLJS.sk.g($CLJS.mV,b),$CLJS.f1(a));return $CLJS.CD(a,$CLJS.rK,$CLJS.Be(b))};
$CLJS.m3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.cz:$CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.qk.g($CLJS.cf.h($CLJS.lV),function(){var c=$CLJS.f1(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.f3(d,c)}):$CLJS.Pd}()),b));return $CLJS.CD(a,$CLJS.PD,b)};
cra=function(a,b){b=$CLJS.Hl(function(d){return $CLJS.R1.g(d,a)},b);var c=$CLJS.pG($CLJS.zz($CLJS.FZ,$CLJS.GZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.pG($CLJS.FZ,a);if($CLJS.n(b))return b;b=$CLJS.pG($CLJS.GZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};dra=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.Gz(a,/ id$/i,"")):null};
era=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.l2(b)});var d=new $CLJS.uh(function(){return $CLJS.l2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
fra=function(a,b,c){var d=$CLJS.g_.g($CLJS.E.g($CLJS.kj.h(b),$CLJS.cV)?b:a,b),e=$CLJS.n(c)?dra($CLJS.g_.g(a,c)):null,f=era(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
gra=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.E1(l,$CLJS.hF)&&$CLJS.zd($CLJS.R1.v(a,b,l,e)))return $CLJS.f3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.TV(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
hra=function(a,b,c,d,e,f){c=gra(a,b,c,d,f);return function t(l,m){try{if($CLJS.ud(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.E1(u,$CLJS.hF))try{var v=$CLJS.F(m,3);if($CLJS.E1(v,$CLJS.hF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.f1(A),S=$CLJS.f1(x);if($CLJS.Sa($CLJS.n(K)?K:S))return $CLJS.n($CLJS.R1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.f3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.f3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var X=
$CLJS.ED(A,$CLJS.jk,$CLJS.H([$CLJS.pO])),U=$CLJS.ED(x,$CLJS.jk,$CLJS.H([$CLJS.pO])),da=null==$CLJS.R1.v(a,b,X,e);var oa=da?$CLJS.R1.v(a,b,U,e):da;return $CLJS.n(oa)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,U],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,X,x],null)}return m}throw $CLJS.Z;}catch(rb){if(rb instanceof Error){var Oa=rb;if(Oa===$CLJS.Z)throw $CLJS.Z;throw Oa;}throw rb;}else throw $CLJS.Z;}catch(rb){if(rb instanceof Error){Oa=rb;if(Oa===$CLJS.Z)throw $CLJS.Z;throw Oa;}throw rb;}else throw $CLJS.Z;
}catch(rb){if(rb instanceof Error){Oa=rb;if(Oa===$CLJS.Z)return $CLJS.TV(t,l,m);throw Oa;}throw rb;}}($CLJS.xf,c)};ira=function(a,b){var c=$CLJS.DV();$CLJS.a3(c,b);return c(a)};
$CLJS.n3=function(a,b,c){if($CLJS.Cd(c,$CLJS.TD))return c;var d=$CLJS.AV(a,b),e=$CLJS.T_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Ta,function v(t,u){try{if($CLJS.ud(u)&&1<=$CLJS.D(u))try{var x=$CLJS.xk.j(u,0,1);if($CLJS.ud(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ce(A,$CLJS.hF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.vU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.rK.h(c))));f=cra(e,f);var k=ira(fra(a,c,f),$CLJS.Hl($CLJS.TD,$CLJS.zM.h(d))),l=$CLJS.Q_.h($CLJS.Z0.g(a,$CLJS.oK.h(c)));return $CLJS.f3($CLJS.tk.j(c,$CLJS.rK,function(t){return $CLJS.sk.g(function(u){return hra(a,b,u,k,e,l)},t)}),k)};$CLJS.o3=function(a){return $CLJS.rK.h(a)};
$CLJS.p3=function(a){return $CLJS.Jk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.kj,$CLJS.sK,$CLJS.AD,a],null),$CLJS.E.g(a,$CLJS.YD)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.q3=function(a,b){b=$CLJS.z($CLJS.oK.h(b));return $CLJS.n($CLJS.JU.h(b))?$CLJS.a_(a,$CLJS.JU.h(b)):$CLJS.n($CLJS.aO.h(b))?$CLJS.ZZ(a,$CLJS.aO.h(b)):null};
t3=function(a){a=$CLJS.oG(function(d){return $CLJS.GZ(d)?r3:$CLJS.FZ(d)?s3:$CLJS.Ss},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,r3);var c=$CLJS.J.g(b,s3);b=$CLJS.J.g(b,$CLJS.Ss);return $CLJS.bf.l(a,c,$CLJS.H([b]))};u3=function(a,b,c,d){return $CLJS.Sa(c)?d:$CLJS.sk.g(function(e){return $CLJS.n($CLJS.IY.h(e))?$CLJS.c3(e,$CLJS.j2(c)):e},$CLJS.d3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
jra=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.T_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.w_,!1,$CLJS.E_,!1],null))});return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.Bl(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.HY);return $CLJS.n(function(){var m=$CLJS.FZ(k);return m?l:m}())?(f=$CLJS.pG(function(m){return $CLJS.E.g(l,$CLJS.Mi.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,v3,f):null):null}),$CLJS.T_.j(a,b,c)))};kra={};
lra=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);s3=new $CLJS.M(null,"fk","fk",398442651);r3=new $CLJS.M(null,"pk","pk",-771936732);mra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);v3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.H_.m(null,$CLJS.jK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.oK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.aO);b=$CLJS.J.g(b,$CLJS.JU);c=$CLJS.n(c)?$CLJS.kD.h($CLJS.ZZ(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.a_(a,b),a=$CLJS.n(c)?$CLJS.g_.j(a,0,c):$CLJS.k1(b)):a=null;return $CLJS.n(a)?a:$CLJS.HD("Native Query")});$CLJS.O_.m(null,$CLJS.jK,function(a,b,c){a=$CLJS.g_.j(a,b,c);c=$CLJS.TD.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.kD,a],null)});
$CLJS.N_.m(null,$CLJS.jK,function(){throw $CLJS.Uh("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.H_.m(null,$CLJS.sK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.AD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.HD("Left outer join");case "right-join":return $CLJS.HD("Right outer join");case "inner-join":return $CLJS.HD("Inner join");case "full-join":return $CLJS.HD("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.O_.m(null,$CLJS.sK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.AD);c=$CLJS.J.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.F_,$CLJS.$z(e),$CLJS.kD,$CLJS.g_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});
$CLJS.P_.m(null,$CLJS.jK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.TD),k=$CLJS.J.j(e,$CLJS.PD,$CLJS.cz),l=$CLJS.J.g(e,$CLJS.oK);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.C_);if($CLJS.E.g(k,$CLJS.cz))return null;var t="undefined"!==typeof $CLJS.bL&&"undefined"!==typeof kra&&"undefined"!==typeof $CLJS.nra&&"undefined"!==typeof $CLJS.w3?new $CLJS.Cc(function(){return $CLJS.w3},$CLJS.kd(mra,new $CLJS.h(null,1,[$CLJS.Dj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.oK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.wx)?$CLJS.Q_.v(u,-1,$CLJS.md(l),c):function(){return function A(x){return new $CLJS.ie(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.vd(C)){var G=$CLJS.ic(C),K=$CLJS.D(G),S=$CLJS.le(K);a:for(var X=0;;)if(X<K){var U=$CLJS.hd(G,X);U=$CLJS.ED(U,$CLJS.jk,$CLJS.H([$CLJS.pO]));U=$CLJS.e_.j(u,-1,U);S.add(U);X+=1}else{G=!0;break a}return G?$CLJS.oe($CLJS.qe(S),A($CLJS.jc(C))):$CLJS.oe($CLJS.qe(S),null)}S=$CLJS.z(C);S=$CLJS.ED(S,$CLJS.jk,$CLJS.H([$CLJS.pO]));
return $CLJS.ae($CLJS.e_.j(u,-1,S),A($CLJS.Hc(C)))}return null}},null,null)}(k)}();return $CLJS.sk.g(function(v){v=$CLJS.R.j(v,$CLJS.VY,f);v=$CLJS.f3($CLJS.R.l(v,$CLJS.kD,$CLJS.g_.j(a,b,v),$CLJS.H([$CLJS.KJ,$CLJS.DY])),f);return $CLJS.k3(e,m,v)},c)});$CLJS.S_.m(null,$CLJS.jK,function(a,b,c,d){return $CLJS.Q_.v(a,b,$CLJS.R.j(c,$CLJS.PD,$CLJS.wx),d)});
var x3,ora=$CLJS.Se($CLJS.N),pra=$CLJS.Se($CLJS.N),qra=$CLJS.Se($CLJS.N),rra=$CLJS.Se($CLJS.N),sra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ui,$CLJS.gB],null),$CLJS.Ui,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));x3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.XA,sra,ora,pra,qra,rra);x3.m(null,$CLJS.jK,function(a){return a});x3.m(null,$CLJS.cV,function(a){return $CLJS.kV(new $CLJS.h(null,2,[$CLJS.kj,$CLJS.jK,$CLJS.oK,$CLJS.oK.h($CLJS.yV(a))],null))});
x3.m(null,$CLJS.QU,function(a){return $CLJS.kV(new $CLJS.h(null,2,[$CLJS.kj,$CLJS.jK,$CLJS.oK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});x3.m(null,$CLJS.fK,function(a){return $CLJS.kV(new $CLJS.h(null,2,[$CLJS.kj,$CLJS.jK,$CLJS.oK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.JU,$CLJS.Mi.h(a),$CLJS.kj,$CLJS.QU],null)],null)],null))});
x3.m(null,$CLJS.QJ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.aZ);a=$CLJS.J.g(b,lra);b=x3.h(new $CLJS.h(null,3,[$CLJS.kj,$CLJS.QU,$CLJS.uA,new $CLJS.h(null,1,[$CLJS.wD,$CLJS.p.h($CLJS.GD())],null),$CLJS.aO,$CLJS.Mi.h(b)],null));c=$CLJS.n(c)?$CLJS.f3(b,c):b;return $CLJS.n(a)?$CLJS.m3.g?$CLJS.m3.g(c,a):$CLJS.m3.call(null,c,a):c});
$CLJS.y3=function(){function a(d,e){return $CLJS.l3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.C1(x3.h(d),$CLJS.PD,$CLJS.wx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tra=function(){function a(d,e,f){var k=$CLJS.y3.h(f);f=$CLJS.od($CLJS.o3.h?$CLJS.o3.h(k):$CLJS.o3.call(null,k))?function(){var m=$CLJS.q3.g?$CLJS.q3.g(d,k):$CLJS.q3.call(null,d,k);return $CLJS.z3.j?$CLJS.z3.j(d,e,m):$CLJS.z3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.l3(k,f):k;var l=$CLJS.n3(d,e,f);return $CLJS.LV.l(d,e,$CLJS.tk,$CLJS.H([$CLJS.zM,function(m){return $CLJS.Yd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.A3=function(){function a(d,e){return $CLJS.Be($CLJS.J.g($CLJS.AV(d,e),$CLJS.zM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ura=function(){function a(d){d=$CLJS.U0(d);d=$CLJS.dz.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.qk.g($CLJS.ef($CLJS.Qe($CLJS.Cd,d)),$CLJS.cf.h($CLJS.p3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YD,$CLJS.OD,$CLJS.bE,$CLJS.eE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.vra=function(){function a(d,e,f,k){var l=$CLJS.e3(f)?$CLJS.f1(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.qk.g($CLJS.cf.h($CLJS.f1),Xqa(function(t){return $CLJS.pk.g(t,l)})),$CLJS.A3.g(d,e));f=$CLJS.n(k)?k:$CLJS.e3(f)?h3($CLJS.z($CLJS.o3(f))):null;return t3(u3(d,e,f,$CLJS.rk.g(function(t){t=$CLJS.f1(t);return $CLJS.n(t)?$CLJS.Cd(m,t):null},$CLJS.T_.v(d,e,$CLJS.AV(d,e),new $CLJS.h(null,1,[$CLJS.w_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.wra=function(){function a(d,e,f,k,l){k=$CLJS.e3(f)?$CLJS.q3(d,f):f;var m=$CLJS.e3(f)?$CLJS.f1(f):null;f=$CLJS.n(l)?l:$CLJS.e3(f)?i3($CLJS.z($CLJS.o3(f))):null;f=$CLJS.n(f)?$CLJS.Sa(m)?$CLJS.ED(f,$CLJS.jk,$CLJS.H([$CLJS.pO])):f:null;return t3(u3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.KJ,$CLJS.DY);return $CLJS.n(m)?$CLJS.f3(t,m):t},$CLJS.T_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.w_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.xra=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.W1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.W1};return b}();
$CLJS.z3=function(){function a(d,e,f){function k(u,v){u=$CLJS.jk.g(u,$CLJS.m1);v=$CLJS.jk.g(v,$CLJS.m1);return $CLJS.Z2.l($CLJS.U1.h($CLJS.Tj),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Be(Yqa(function(x){return $CLJS.Mi.h(v3.h(x))},jra(d,e,u,v)))}var m=$CLJS.AV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.sk.g(function(v){return k(v,v3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.sk.g(function(u){return k(v3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.yra=$CLJS.cf.h(function(a){return $CLJS.R.j(a,$CLJS.KJ,$CLJS.DY)});
$CLJS.zra=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.e3(k)?h3($CLJS.z($CLJS.o3(k))):null;$CLJS.n(l)?(l=$CLJS.j_.j(e,f,l),l=$CLJS.Bz(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uj,$CLJS.kD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.zV(e,f)){l=$CLJS.A3.g(e,f);var m=$CLJS.od(l);k=m?m:$CLJS.e3(k)?$CLJS.E.g($CLJS.TD.h(k),$CLJS.TD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.UZ(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.UZ(e),k=$CLJS.ZZ(e,k),e=$CLJS.g_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.HD("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Ara=function(){function a(d,e,f,k){f=$CLJS.mV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.di.h(k);e=$CLJS.zV(d,e);l=$CLJS.s0.j(d,e,l);d=$CLJS.s0.j(d,e,m);m=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.di,l)),k);d=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.di,d)),k);f=m?$CLJS.tk.v(f,2,$CLJS.c3,k):f;return d?$CLJS.tk.v(f,3,$CLJS.c3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.K_.m(null,$CLJS.zM,function(a,b){var c=$CLJS.Be($CLJS.A3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.g_.j(a,b,d)},c);return null==c?null:$CLJS.is(" + ",c)});