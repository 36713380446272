var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var yK,AK,CK,FK,IK;$CLJS.wK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.lF)};$CLJS.xK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);yK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.zK=new $CLJS.M(null,"operators","operators",-2064102509);AK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.BK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);CK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.DK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.EK=new $CLJS.M(null,"dimensions","dimensions",-254818097);FK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.GK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.HK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);IK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.JK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(AK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ZF],null)],null)],null));$CLJS.Y(IK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,AK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.yD],null)],null)],null));$CLJS.Y(CK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.hF],null),IK,$CLJS.pD],null));
$CLJS.Y(FK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.hF],null),AK,$CLJS.eK],null));
$CLJS.GF.g($CLJS.hF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.hF],null),AK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.eK,$CLJS.pD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.Ai,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.us,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,FK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,CK],null)],null)],null));$CLJS.nE($CLJS.hF,$CLJS.lF);$CLJS.JD.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.WD});$CLJS.uE($CLJS.qA,$CLJS.H([$CLJS.pD]));$CLJS.JD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.WD});
$CLJS.nE($CLJS.qA,$CLJS.lF);$CLJS.Y(yK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.uD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.pD],null)],null)],null));
$CLJS.GF.g($CLJS.XE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.XE],null),yK,$CLJS.Cj],null));$CLJS.JD.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.WD});$CLJS.nE($CLJS.XE,$CLJS.lF);$CLJS.uE($CLJS.FE,$CLJS.H([$CLJS.$r,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null)],null)]));$CLJS.nE($CLJS.FE,$CLJS.lF);
$CLJS.uE($CLJS.cF,$CLJS.H([$CLJS.$r,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JJ],null)],null)]));$CLJS.nE($CLJS.cF,$CLJS.lF);
$CLJS.Y($CLJS.lF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.LD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.Fs,function(){return["Valid reference, must be one of these clauses: ",$CLJS.is(", ",$CLJS.Cz($CLJS.Dd,$CLJS.Mk.g($CLJS.q($CLJS.gB),$CLJS.lF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.wK(a)}],null)],null));