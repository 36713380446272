var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var zX,Pna,Qna,Rna,CX,Tna,Una,Vna,Wna,EX,FX,GX,IX,Xna,JX,KX,Yna,Zna,$na,LX,Sna,MX,NX,aoa,PX;zX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.AX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.BX=function(a,b){return $CLJS.MK(a)(b,$CLJS.xf,$CLJS.xf)};
Pna=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.XE,b),$CLJS.Xr(2,2,c))};Qna=function(a,b){var c=$CLJS.Ll(a,$CLJS.BP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.hs.g?$CLJS.hs.g(c,b):$CLJS.hs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.BP,b)}return a};Rna=function(a){return $CLJS.bf.g($CLJS.HU(a),$CLJS.IU(a))};
CX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Er,null,$CLJS.Yk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?zX(2,c):zX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.rW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.ud(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.rk.h($CLJS.el),l):$CLJS.sd(k)?$CLJS.Jz($CLJS.Ta,l):l}):$CLJS.jk.g(a,f)};
Tna=function(a){return $CLJS.bb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return CX(b,Sna,d)},a,Rna(a))};
Una=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.oK.h(a),b);a:{var d=c;for(var e=$CLJS.xf;;){var f=$CLJS.z($CLJS.cf.g($CLJS.AX($CLJS.Xi,$CLJS.Uk),$CLJS.ff($CLJS.qk.j(DX,$CLJS.z,$CLJS.Uk),$CLJS.Ir.h($CLJS.BX($CLJS.gV,d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=CX(d,f,k),m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.convert",m))){var t=$CLJS.AA.l($CLJS.H([$CLJS.yy,$CLJS.xh.l($CLJS.H([k]))])),u=$CLJS.AA,v=u.l,x=$CLJS.xh,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.RK($CLJS.BX($CLJS.gV,
d));$CLJS.Kz("metabase.lib.convert",m,$CLJS.pV("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yy,A.call(x,$CLJS.H([C]))])),$CLJS.AA.l($CLJS.H([$CLJS.ny,$CLJS.qV($CLJS.z($CLJS.Zs(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.Yd.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Tna(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.oK.h(a))-1))return a;b+=1}else a=$CLJS.tk.M(a,$CLJS.oK,$CLJS.R,b,d)}};
Vna=function(a){var b=$CLJS.DV();return $CLJS.sk.g(function(c){return $CLJS.E.g($CLJS.TD.h(c),"__join")?$CLJS.tk.j(c,$CLJS.TD,b):c},a)};Wna=function(a){return"string"===typeof $CLJS.aO.h(a)?$CLJS.jk.g($CLJS.R.j(a,$CLJS.JU,$CLJS.BV($CLJS.aO.h(a))),$CLJS.aO):a};EX=function(){return $CLJS.rk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
FX=function(a){return $CLJS.Wf.j($CLJS.N,EX(),a)};GX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.qk.g(EX(),$CLJS.rk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.qi)})),a))};
IX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.HX),c);a=$CLJS.E.g(a,$CLJS.QE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Be(GX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qL,a,b],null):a};Xna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.qk.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.FA)}),$CLJS.cf.h($CLJS.HX)),$CLJS.xV.h(a))};
JX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.oK);a=$CLJS.z($CLJS.bb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.HX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.DP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.NN,Xna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.jU.h(c)],null)},null,a));return $CLJS.n($CLJS.eP.h(a))?$CLJS.uU(a,new $CLJS.h(null,1,[$CLJS.eP,$CLJS.DE],null)):a};
KX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.OV(a,b,$CLJS.qk.g($CLJS.HX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.OV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mr],null),$CLJS.cf.h($CLJS.HX),e)}):d;return $CLJS.uU(a,$CLJS.Ae([b,c]))};Yna=function(a){var b=$CLJS.JU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.jk.g(a,$CLJS.JU),$CLJS.aO,["card__",$CLJS.p.h(b)].join("")):a};
Zna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);$na=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);LX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Sna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);MX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
NX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.OX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);aoa=new $CLJS.M(null,"m","m",1632677161);PX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var DX,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa,koa;$CLJS.QX=$CLJS.N;$CLJS.RX=$CLJS.N;DX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.dV,null,$CLJS.PD,null,$CLJS.zM,null,$CLJS.VO,null,$CLJS.WE,null,$CLJS.JQ,null,$CLJS.XE,null],null),null);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.Se($CLJS.N);foa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ui,$CLJS.gB],null),$CLJS.Ui,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.SX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.XA,foa,boa,coa,doa,eoa);$CLJS.SX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.sd(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.kV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.SX),d))}else b=a;return b});$CLJS.SX.m(null,$CLJS.cV,function(a){return a});
$CLJS.SX.m(null,$CLJS.QU,function(a){var b=$CLJS.SX.h($CLJS.XE.h(a)),c=$CLJS.Be($CLJS.sk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.uV($CLJS.SX.h(t),u)},$CLJS.VO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Ml(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Ml(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.wD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.RX,k=$CLJS.QX;$CLJS.RX=d;$CLJS.QX=e;try{var l=Pna(Wna(a),b,$CLJS.H([$CLJS.VO,c])),m=$CLJS.bb(function(t,u){return $CLJS.Sa($CLJS.J.g(t,u))?t:$CLJS.tk.j(t,u,$CLJS.SX)},l,$CLJS.kk.l(DX,$CLJS.XE,$CLJS.H([$CLJS.VO])));return $CLJS.n($CLJS.zM.h(m))?$CLJS.tk.j(m,$CLJS.zM,Vna):m}finally{$CLJS.QX=k,$CLJS.RX=f}});$CLJS.SX.m(null,$CLJS.XU,function(a){return Qna(a,function(b){return $CLJS.OV(b,$CLJS.AE,$CLJS.SX)})});
$CLJS.SX.m(null,$CLJS.jK,function(a){a=$CLJS.tk.j($CLJS.tk.j(a,$CLJS.rK,$CLJS.SX),$CLJS.oK,$CLJS.SX);var b=$CLJS.n($CLJS.PD.h(a))?$CLJS.tk.j(a,$CLJS.PD,function(c){return $CLJS.ql(c)?$CLJS.sk.g($CLJS.SX,c):$CLJS.mh.h(c)}):a;return $CLJS.Sa($CLJS.TD.h(a))?$CLJS.R.j(b,$CLJS.TD,"__join"):b});$CLJS.SX.m(null,$CLJS.nA,function(a){return $CLJS.sk.g($CLJS.SX,a)});
$CLJS.SX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Xi.h(a))?Una($CLJS.R.j($CLJS.tk.j($CLJS.yV(a),$CLJS.oK,function(b){return $CLJS.sk.g($CLJS.SX,b)}),$CLJS.OX,!0)):$CLJS.hs(a,$CLJS.SX)});$CLJS.SX.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.kV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,b],null))});
$CLJS.SX.m(null,$CLJS.jj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.uU(a,new $CLJS.h(null,3,[$CLJS.cC,$CLJS.tA,$CLJS.zN,$CLJS.zj,$CLJS.RQ,$CLJS.rD],null));var c=$CLJS.R.j;var d=$CLJS.qi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.KD(b));a=c.call($CLJS.R,a,$CLJS.qi,d);return $CLJS.kV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jj,a,b],null))});
$CLJS.SX.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,$CLJS.jk.g(c,$CLJS.Oh),$CLJS.sk.g($CLJS.SX,b)],null);b=$CLJS.kV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.SX.h(a)):b});$CLJS.SX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.kV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.SX.m(null,$CLJS.XE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.RX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.UQ,a],null));return $CLJS.kV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.SX.m(null,$CLJS.qL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.SX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Jk.l($CLJS.H([c,a]))],null),d)});goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.Se($CLJS.N);koa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ui,$CLJS.gB],null),$CLJS.Ui,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.HX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.XA,koa,goa,hoa,ioa,joa);
$CLJS.HX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.sd(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.HX,d);a=GX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.HX,$CLJS.ae(a,d)))}else a=$CLJS.sd(a)?$CLJS.hs(FX(a),$CLJS.HX):
a;return a});for(var TX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[PX,NX],null)),UX=null,VX=0,WX=0;;)if(WX<VX){var loa=UX.X(null,WX);$CLJS.nE(loa,MX);WX+=1}else{var XX=$CLJS.y(TX);if(XX){var YX=XX;if($CLJS.vd(YX)){var ZX=$CLJS.ic(YX),moa=$CLJS.jc(YX),noa=ZX,ooa=$CLJS.D(ZX);TX=moa;UX=noa;VX=ooa}else{var poa=$CLJS.z(YX);$CLJS.nE(poa,MX);TX=$CLJS.B(YX);UX=null;VX=0}WX=0}else break}
for(var $X=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.bF,$CLJS.qF,$CLJS.ME,$CLJS.Ij,$CLJS.uF,$CLJS.vi,$CLJS.TE,$CLJS.oF,$CLJS.vF,$CLJS.tF,$CLJS.AF],null)),aY=null,bY=0,cY=0;;)if(cY<bY){var qoa=aY.X(null,cY);$CLJS.nE(qoa,PX);cY+=1}else{var dY=$CLJS.y($X);if(dY){var eY=dY;if($CLJS.vd(eY)){var fY=$CLJS.ic(eY),roa=$CLJS.jc(eY),soa=fY,toa=$CLJS.D(fY);$X=roa;aY=soa;bY=toa}else{var uoa=$CLJS.z(eY);$CLJS.nE(uoa,PX);$X=$CLJS.B(eY);aY=null;bY=0}cY=0}else break}
for(var gY=$CLJS.y($CLJS.tf([$CLJS.Pq,$CLJS.$r,$CLJS.Qq,$CLJS.BF,$CLJS.QE,$CLJS.kF,$CLJS.HE,$CLJS.DF,$CLJS.yE,$CLJS.VE,$CLJS.CE,$CLJS.fF,$CLJS.mF,$CLJS.EE,$CLJS.ZA,$CLJS.$A,$CLJS.Fx,$CLJS.UH,$CLJS.ZH,$CLJS.OH,$CLJS.aB,$CLJS.TH,$CLJS.MH,$CLJS.bI,$CLJS.gI,$CLJS.dI,$CLJS.SH,$CLJS.iI,$CLJS.bB,$CLJS.eB,$CLJS.GE,$CLJS.zE,$CLJS.EF,$CLJS.UG,$CLJS.jF,$CLJS.Pw,$CLJS.wF,$CLJS.yF,$CLJS.FF,$CLJS.ks,$CLJS.qs],!0)),hY=null,iY=0,jY=0;;)if(jY<iY){var voa=hY.X(null,jY);$CLJS.nE(voa,NX);jY+=1}else{var kY=$CLJS.y(gY);
if(kY){var lY=kY;if($CLJS.vd(lY)){var mY=$CLJS.ic(lY),woa=$CLJS.jc(lY),xoa=mY,yoa=$CLJS.D(mY);gY=woa;hY=xoa;iY=yoa}else{var zoa=$CLJS.z(lY);$CLJS.nE(zoa,NX);gY=$CLJS.B(lY);hY=null;iY=0}jY=0}else break}$CLJS.HX.m(null,MX,function(a){return IX(a)});$CLJS.HX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.qk.g(EX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.HX.h(b)],null)})),a)});
$CLJS.HX.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.sd(b))try{var d=GX(b);b=$CLJS.Q;var e=$CLJS.QX,f=$CLJS.J.j(e,c,LX);if($CLJS.E.g(f,LX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[aoa,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.XE,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qL,k,d],null):k}catch(l){throw c=l,d=$CLJS.oU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.HX.m(null,$CLJS.nA,function(a){return $CLJS.sk.g($CLJS.HX,a)});
$CLJS.HX.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,$CLJS.HX.h(a),GX(b)],null)});
$CLJS.HX.m(null,$CLJS.jj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=GX($CLJS.uU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.cC,$CLJS.zj,$CLJS.zN,$CLJS.rD,$CLJS.RQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jj,a,b],null)});
$CLJS.HX.m(null,$CLJS.jK,function(a){var b=FX(a);a=0==$CLJS.TD.h(a).lastIndexOf("__join",0)?$CLJS.jk.g(b,$CLJS.TD):b;return $CLJS.Jk.l($CLJS.H([$CLJS.hs($CLJS.jk.l(a,$CLJS.oK,$CLJS.H([$CLJS.rK])),$CLJS.HX),KX($CLJS.Kl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rK],null)),$CLJS.rK,$CLJS.LQ),JX(a)]))});
$CLJS.HX.m(null,$CLJS.QU,function(a){var b=$CLJS.XE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Ml(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Ml(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.RX,e=$CLJS.QX;$CLJS.RX=c;$CLJS.QX=b;try{return $CLJS.bb(function(f,k){return $CLJS.OV(f,
k,$CLJS.HX)},KX($CLJS.OV($CLJS.OV(Yna(FX(a)),$CLJS.XE,function(f){return $CLJS.sk.g(IX,f)}),$CLJS.VO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.HX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tV(C),$CLJS.E.g($CLJS.jj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.HX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tV(x),$CLJS.E.g($CLJS.jj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.dV,$CLJS.LE),$CLJS.kk.l(DX,$CLJS.XE,$CLJS.H([$CLJS.dV,$CLJS.VO])))}finally{$CLJS.QX=e,$CLJS.RX=d}});$CLJS.HX.m(null,$CLJS.XU,function(a){return $CLJS.hs(FX(a),$CLJS.HX)});
$CLJS.HX.m(null,$CLJS.cV,function(a){try{var b=FX(a),c=$CLJS.qM.h(b),d=JX(b),e=$CLJS.E.g($CLJS.kj.h($CLJS.ed($CLJS.oK.h(a))),$CLJS.XU)?$CLJS.eP:$CLJS.DE;return $CLJS.Jk.l($CLJS.H([$CLJS.jk.l(b,$CLJS.oK,$CLJS.H([$CLJS.qM,$CLJS.OX])),function(){var k=$CLJS.Ae([$CLJS.Xi,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.qM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.oU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.DE,a],null),f);}});
$CLJS.nY=function(){function a(d,e,f){f=$CLJS.yX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.li,!0])));var k=$CLJS.AV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.XE);k=$CLJS.Wf.j($CLJS.N,$CLJS.Ml(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Ml(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.RX,t=$CLJS.QX;$CLJS.RX=k;$CLJS.QX=l;try{try{return $CLJS.SX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.oU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.DE,d,$CLJS.BK,e,$na,f,Zna,$CLJS.RX],null),u);}}finally{$CLJS.QX=t,$CLJS.RX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();