var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var ZT,$T,aU,cU,dU,eU,fU,gU,hU,iU;ZT=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);$T=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);aU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.bU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);cU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);dU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);eU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
fU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);gU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);hU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);iU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(ZT,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.UT)));$CLJS.Y(cU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Lj,$CLJS.CL,$CLJS.rM,$CLJS.AE,$CLJS.Qs,$CLJS.Qj,$CLJS.xO],null));
$CLJS.Y(fU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.pD,$CLJS.tr],null)],null)],null));
$CLJS.Y(gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.hl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null));
$CLJS.Y(dU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.AE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Br,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.Hj],null)],null)],null)],null));
$CLJS.Y(eU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.CL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oL,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.dK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.UJ],null)],null)],null));
$CLJS.Y(iU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.rM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YP,$CLJS.TJ],null)],null)],null));$CLJS.Y(hU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Lj],null),$CLJS.IT));
$CLJS.Y($T,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hU],null)],null)],null)],null));
$CLJS.Y(aU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.Ai,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.co,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$T],null)],null)],null)],null));
$CLJS.Y($CLJS.bU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,$CLJS.pD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));