var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var l5,Gsa,Hsa,Isa,n5,q5,r5,s5,Jsa,Ksa,v5,t5,u5,y5,z5,Lsa,Msa,A5,Nsa,Osa,Psa,Qsa,Rsa,Ssa,Tsa,C5,Usa,Vsa,Wsa,D5,Xsa,Ysa,E5,Zsa,$sa,ata,bta;l5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=l5($CLJS.J.g(a,b),c),$CLJS.od(c)?$CLJS.jk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.jk.g(a,b)}return a};
Gsa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.Ll(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Cb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Ne.j(l,$CLJS.Cb(v),m)):f}(a,b,c,d)};Hsa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Gsa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.m5=function(a){return $CLJS.wD.h($CLJS.DD(a))};
Isa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.VO,$CLJS.z(b))?$CLJS.uV(d,function(){var f=$CLJS.KV.h(d);return $CLJS.n(f)?f:$CLJS.tV(c)}()):d;return Hsa(a,b,function(f){return $CLJS.yf(function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.E.g($CLJS.m5(C),$CLJS.m5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):
$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.E.g($CLJS.m5(x),$CLJS.m5(c))?e:x,m($CLJS.Hc(t)))}return null}},null,null)}(f)}())})};
n5=function(a,b,c,d){var e=$CLJS.Bz(a,b);if($CLJS.n(e)){var f=$CLJS.m5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.ed(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.Wf.j($CLJS.xf,$CLJS.rk.h($CLJS.qk.g($CLJS.Tg([f]),$CLJS.m5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PD],null))&&$CLJS.Le(function(l){return $CLJS.E1(l,$CLJS.qA)},e)?null:e;if($CLJS.y(e))return $CLJS.SK(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,$CLJS.rK],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.Uh($CLJS.HD("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Cx,$CLJS.FV,$CLJS.rK,$CLJS.Bz(a,b),$CLJS.GV,$CLJS.Bz(a,$CLJS.nd(b)),$CLJS.BK,d,$CLJS.HV,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,$CLJS.PD],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.rW.v(a,$CLJS.nd(b),$CLJS.jk,k):l5(a,b)}return a};$CLJS.o5=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.oK);b=0>b?$CLJS.D(a)+b:b;return b+1<$CLJS.D(a)?b+1:null};
$CLJS.p5=function(a,b){a=$CLJS.LV.l(a,b,$CLJS.jk,$CLJS.H([$CLJS.PD]));return $CLJS.Wf.j($CLJS.xf,$CLJS.rk.h($CLJS.qk.g(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.DY,null,$CLJS.CY,null],null),null),$CLJS.KJ)),$CLJS.Q_.j(a,b,$CLJS.AV(a,b)))};
q5=function(a,b,c,d){var e=$CLJS.N1.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.oG(function(f){return $CLJS.R1.v(a,b,f,d)},c),(e=!$CLJS.Cd(c,null))?(e=$CLJS.N1.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Le(function(f){return $CLJS.N1.g($CLJS.D(f),1)},$CLJS.Pg(c)):e):e):e};
r5=function(a,b){var c=$CLJS.A3.g(a,b),d=$CLJS.bh(0,$CLJS.D(c));return $CLJS.bf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JQ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PD],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.VO],null)],null),function(){return function k(f){return new $CLJS.ie(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.vd(l)){var m=
$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.F(c,x);$CLJS.PD.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,x,$CLJS.PD],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.PD.h(m)instanceof $CLJS.M)l=$CLJS.Hc(l);else return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,u,$CLJS.PD],null),k($CLJS.Hc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ie(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,x,$CLJS.rK],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,u,$CLJS.rK],null),k($CLJS.Hc(l)))}return null}},null,null)}(d)}()]))};
s5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.nF),k=$CLJS.J.g(e,$CLJS.pQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.pG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.nF.h($CLJS.dd(m)),f)&&$CLJS.E.g($CLJS.pQ.h($CLJS.dd(m)),k)&&$CLJS.E.g($CLJS.ed(m),l)},$CLJS.Jl($CLJS.Ar,$CLJS.JQ.h($CLJS.AV(a,b)))))};
Jsa=function(a,b,c,d){c=s5(a,b,c);return $CLJS.n(c)?$CLJS.LV.l(a,b,$CLJS.rW,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.JQ,c,2,1],null),$CLJS.qk.g(function(e){return $CLJS.Q0($CLJS.el,e)},$CLJS.Jk),d])):a};Ksa=function(a,b,c){c=s5(a,b,c);return $CLJS.n(c)?$CLJS.LV.l(a,b,n5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JQ],null),$CLJS.Bz($CLJS.AV(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JQ,c],null)),b])):a};
v5=function(a,b,c,d,e,f){var k=$CLJS.LV.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.m5(e);return $CLJS.pk.g(a,k)?$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ta,function(m,t){try{if($CLJS.ud(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.VO))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.tV(e);K=t5.W?t5.W(k,b,c,$CLJS.qA,$CLJS.N,K):t5.call(null,k,b,c,$CLJS.qA,$CLJS.N,K);return u5.v?u5.v(K,b,c,l):u5.call(null,K,b,c,l)}()],null);throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.XE))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=t5.W?t5.W(k,b,c,$CLJS.XE,$CLJS.N,l):t5.call(null,k,b,c,$CLJS.XE,$CLJS.N,l);return u5.v?u5.v(S,b,c,l):u5.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{if($CLJS.ud(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.WE))return new $CLJS.P(null,
1,5,$CLJS.Q,[u5.v?u5.v(k,b,c,l):u5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.PD))return new $CLJS.P(null,1,5,$CLJS.Q,[u5.v?u5.v(k,b,c,l):u5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){var A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.ud(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ce(C,$CLJS.zM))try{var G=$CLJS.F(t,2);if($CLJS.ce(G,$CLJS.PD))return new $CLJS.P(null,1,5,$CLJS.Q,[u5.v?u5.v(k,b,c,l):u5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw X;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.xf,d)))):k};
t5=function(a,b,c,d,e,f){var k=$CLJS.AV(a,b),l=$CLJS.df(function(t){var u=$CLJS.Bz(k,t);return $CLJS.n(u)?$CLJS.Hl(function(v){return $CLJS.z($CLJS.y($CLJS.ff($CLJS.Ta,function G(A,C){try{if($CLJS.ud(C)&&3===$CLJS.D(C))try{var K=$CLJS.F(C,0);if($CLJS.E.g(K,d))try{var S=$CLJS.F(C,1);if($CLJS.od(e)||$CLJS.P0($CLJS.Wg(e),$CLJS.Wg(S)))try{var X=$CLJS.F(C,2);if($CLJS.E.g(X,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[t,v],null)],null);throw $CLJS.Z;}catch(da){if(da instanceof Error){var U=
da;if(U===$CLJS.Z)throw $CLJS.Z;throw U;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){U=da;if(U===$CLJS.Z)throw $CLJS.Z;throw U;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){U=da;if(U===$CLJS.Z)throw $CLJS.Z;throw U;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){U=da;if(U===$CLJS.Z)return $CLJS.vU(G,A,C);throw U;}throw da;}}($CLJS.xf,v))))},u):null},$CLJS.H([r5(a,b)])),m=$CLJS.Ve($CLJS.ac($CLJS.xf));a=$CLJS.bb(function(t,u){var v=$CLJS.I(u,0,
null);u=$CLJS.I(u,1,null);return v5(t,b,c,v,u,function(x,A,C){try{return n5(x,A,C,b)}catch(K){if(K instanceof Error){A=K;var G=$CLJS.O($CLJS.Nl(A));C=$CLJS.J.g(G,$CLJS.Cx);G=$CLJS.J.g(G,$CLJS.GV);if($CLJS.E.g(C,$CLJS.FV))return m.nd(null,$CLJS.te(m.Lb(null),G)),x;throw A;}throw K;}})},a,l);return $CLJS.bb(function(t,u){return $CLJS.w5.j?$CLJS.w5.j(t,b,u):$CLJS.w5.call(null,t,b,u)},a,$CLJS.dc($CLJS.q(m)))};
u5=function(a,b,c,d){b=$CLJS.o5(c,b);if($CLJS.n(b)){var e=$CLJS.AV(c,b);c=$CLJS.Me(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.KJ),l=$CLJS.J.g(f,$CLJS.y0);return $CLJS.E.g($CLJS.rY,k)&&$CLJS.E.g(d,l)?$CLJS.GY.h(f):null},$CLJS.T_.j(c,b,e));return $CLJS.n(c)?t5(a,b,a,$CLJS.hF,$CLJS.N,c):a}return a};
y5=function(a,b,c,d,e){var f=$CLJS.mV.h(c),k=$CLJS.AV(a,b);c=$CLJS.pG(function(t){var u=$CLJS.Bz(k,t);if($CLJS.n(u)){var v=$CLJS.m5(f);return $CLJS.n($CLJS.Me($CLJS.qk.j($CLJS.Tg([v]),$CLJS.wD,$CLJS.dd),u))?t:null}return null},r5(a,b));var l=(d=$CLJS.E.g($CLJS.EF,d))?$CLJS.mV.h(e):null;e=d?function(t,u,v){return Isa(t,u,v,l)}:function(t,u,v){return n5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WE],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.ed(f),$CLJS.ed(l))?
Jsa(a,b,f,$CLJS.Kl($CLJS.dd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pQ,$CLJS.nF],null))):m?Ksa(a,b,f):a;return $CLJS.n(c)?(a=v5(a,b,a,c,f,e),$CLJS.x5.h?$CLJS.x5.h(a):$CLJS.x5.call(null,a)):a};z5=function(a){return $CLJS.ED(a,$CLJS.R,$CLJS.H([$CLJS.wD,$CLJS.p.h($CLJS.GD())]))};
Lsa=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.qA))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return z5(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.TV(l,f,k);throw u;
}throw v;}}($CLJS.xf,d)}(a)};Msa=function(a,b,c){var d=function(){var l=$CLJS.tV(c);return $CLJS.n(l)?l:$CLJS.T.h($CLJS.DD(c))}(),e=z5($CLJS.uV(c,d));a=$CLJS.tk.j(a,$CLJS.VO,function(l){return $CLJS.sk.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.tV(b),k=$CLJS.qi.h($CLJS.DD(c));return Lsa(a,f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,new $CLJS.h(null,1,[$CLJS.qi,k],null),d],null))};
A5=function(a,b,c){return $CLJS.pU(function(d){return $CLJS.E.g(d,$CLJS.m5(b))?$CLJS.m5(c):d},$CLJS.n($CLJS.tV(b))?Msa(a,b,c):$CLJS.pU(function(d){return $CLJS.E.g(d,b)?c:d},a))};Nsa=function(a,b,c){a=$CLJS.Q_.j(a,b,$CLJS.AV(a,b));b=$CLJS.Q_.j(c,b,$CLJS.AV(c,b));return $CLJS.ff(function(d){return $CLJS.pk.g($CLJS.z(d),$CLJS.dd(d))},$CLJS.cf.j($CLJS.Ar,a,b))};
Osa=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.GY.h(d),f=$CLJS.lV($CLJS.R.j(c,$CLJS.KJ,$CLJS.rY));return $CLJS.cf.g(function(k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,z5(f)],null)},$CLJS.y($CLJS.ff($CLJS.Ta,function t(l,m){try{if($CLJS.ud(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.ce(u,$CLJS.hF))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.P(null,1,5,$CLJS.Q,[m],null);throw $CLJS.Z;}catch(A){if(A instanceof Error){var x=A;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)return $CLJS.vU(t,l,m);throw x;}throw A;}}($CLJS.xf,$CLJS.AV(a,b)))))};Psa=function(a,b,c){var d=$CLJS.qi.h($CLJS.DD(c));$CLJS.n(d)||(d=$CLJS.I0.h?$CLJS.I0.h(c):$CLJS.I0.call(null,c),d=$CLJS.Sa(d)||!$CLJS.sV(c));if($CLJS.n(d))return c;a=$CLJS.M_.j(a,b,c);return $CLJS.ED(c,$CLJS.R,$CLJS.H([$CLJS.qi,a]))};
Qsa=function(a,b){a=$CLJS.tV(a);return $CLJS.n($CLJS.n(a)?$CLJS.Sa($CLJS.tV(b))&&$CLJS.Sa($CLJS.T.h($CLJS.DD(b))):a)?$CLJS.uV(b,a):b};
Rsa=function(a,b,c,d){d=Psa(a,b,Qsa(c,d));c=$CLJS.LV.l(a,b,A5,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.o5(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.df(function(l,m,t){return function(u){return Osa(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([Nsa(a,e,c)]));c=$CLJS.bb(function(l,m,t,u){return function(v,x){var A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.LV.l(v,u,A5,$CLJS.H([A,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Ssa=function(a,b){b=$CLJS.Uk.h(b);if($CLJS.E.g($CLJS.z(b),$CLJS.oK)){var c=$CLJS.dd(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Me(function(k){return $CLJS.Le($CLJS.pl,$CLJS.cf.j($CLJS.E,k,d))?k:null},r5(a,c)):f}();return $CLJS.n(e)?$CLJS.xk.j(b,0,$CLJS.D(e)+2+1):null}return null};
Tsa=function(a,b,c,d){for(var e=Rsa(a,b,c,d);;){var f=$CLJS.BX($CLJS.HK,e),k=$CLJS.Kk.h($CLJS.Hl(function(l){return function(m){return Ssa(l,m)}}(e,f),$CLJS.Ir.h(f)));if($CLJS.y(k))e=$CLJS.bb(function(){return function(l,m){try{return $CLJS.B5.j(l,$CLJS.dd(m),$CLJS.Bz(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.Nl(t)),v=$CLJS.J.g(u,$CLJS.Cx);u=$CLJS.J.g(u,$CLJS.GV);if($CLJS.E.g(v,$CLJS.FV))return m=$CLJS.dd(m),$CLJS.w5.j?$CLJS.w5.j(l,m,u):$CLJS.w5.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?y5(a,b,c,$CLJS.EF,d):e}};C5=function(a,b){return $CLJS.E1(a,$CLJS.hF)&&$CLJS.E.g($CLJS.f1(a),b)};Usa=function(a,b,c){return function k(e,f){try{if(C5(f,b))return $CLJS.f3(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.TV(k,e,f);throw l;}throw m;}}($CLJS.xf,a)};
Vsa=function(a,b,c){var d=$CLJS.zM.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.TD],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.pk.g(e,c):b)){var f=$CLJS.DV();$CLJS.a3(f,$CLJS.cf.g($CLJS.TD,d));c=f(c);return Usa($CLJS.SK(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,b,$CLJS.TD],null),c),e,c)}return a};
Wsa=function(a,b,c){if($CLJS.Ad(c))return c;var d=function(){var e=$CLJS.Tg([c]);return"string"===typeof c?$CLJS.qk.g(e,$CLJS.TD):e}();return $CLJS.Me(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Jl($CLJS.Ar,$CLJS.zM.h($CLJS.AV(a,b))))};
D5=function(a,b,c,d){b=$CLJS.bt.g($CLJS.Wg($CLJS.T_.j(b,c,$CLJS.AV(b,c))),$CLJS.Wg($CLJS.T_.j(a,c,$CLJS.AV(a,c))));return $CLJS.bb(function(e,f){return $CLJS.Ne.M(t5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Xsa=function(a,b,c){a=D5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,new $CLJS.h(null,1,[$CLJS.pO,$CLJS.aZ.h(d)],null),$CLJS.Mi.h(d)],null)});c=$CLJS.o5(a,c);return $CLJS.n(c)?D5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,$CLJS.N,$CLJS.GY.h(d)],null)}):a};
Ysa=function(a,b,c){return $CLJS.Ad(c)?$CLJS.Bz($CLJS.AV(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,c,$CLJS.TD],null)):$CLJS.sd(c)?$CLJS.TD.h(c):c};
E5=function(a,b,c,d){var e=Ysa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.LV)(a,b,function(k){var l=$CLJS.zM.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.CD(k,$CLJS.zM,l)});return(0,$CLJS.LV)(f,b,function(k){return $CLJS.OV(k,$CLJS.zM,function(l){return $CLJS.sk.g(function(m){return $CLJS.n3(f,b,m)},l)})})}(),c=Xsa(c,a,b),$CLJS.x5.h?$CLJS.x5.h(c):$CLJS.x5.call(null,c)):a};
Zsa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ta,function f(d,e){try{if(C5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.vU(f,d,e);throw k;}throw l;}}($CLJS.xf,a))))};$sa=function(a,b){var c=$CLJS.PD.h($CLJS.AV(a,b));if($CLJS.n(c)){var d=$CLJS.Sa($CLJS.Me($CLJS.qk.g($CLJS.AM,$CLJS.DD),c));return d?q5(a,b,c,$CLJS.p5(a,b)):d}return c};
ata=function(a,b){var c=$CLJS.AV(a,b),d=$CLJS.n($sa(a,b))?$CLJS.LV.l(a,b,$CLJS.jk,$CLJS.H([$CLJS.PD])):a;return $CLJS.n($CLJS.zM.h(c))?$CLJS.LV.l(d,b,$CLJS.tk,$CLJS.H([$CLJS.zM,$CLJS.Qe($CLJS.sk,function(e){var f=$CLJS.PD.h(e);var k=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.wx,null,$CLJS.cz,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(q5(a,b,$CLJS.PD.h(e),$CLJS.Q_.j(a,b,$CLJS.R.j(e,$CLJS.PD,$CLJS.wx))))?$CLJS.R.j(e,$CLJS.PD,$CLJS.wx):e})])):d};
$CLJS.x5=function(a){return $CLJS.bb(ata,a,$CLJS.bh(0,$CLJS.D($CLJS.oK.h(a))))};bta=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.F5=new $CLJS.M("lib","hide-bin-bucket?","lib/hide-bin-bucket?",-701033945);var G5;$CLJS.B5=function(){function a(d,e,f){return $CLJS.sd(f)&&$CLJS.E.g($CLJS.kj.h(f),$CLJS.jK)?$CLJS.w5.j?$CLJS.w5.j(d,e,f):$CLJS.w5.call(null,d,e,f):y5(d,e,f,bta,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();G5=$CLJS.kE.h($CLJS.VD);
$CLJS.I5=function(){function a(d,e,f,k){if($CLJS.sd(f)&&$CLJS.E.g($CLJS.kj.h(f),$CLJS.jK))d=$CLJS.H5.v?$CLJS.H5.v(d,e,f,k):$CLJS.H5.call(null,d,e,f,k);else{var l=G5.h?G5.h(f):G5.call(null,f);l=$CLJS.n(l)?G5.h?G5.h(k):G5.call(null,k):l;d=$CLJS.n(l)?Tsa(d,e,f,k):y5(d,e,f,$CLJS.EF,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.cta=function(){function a(d,e,f,k){f=Wsa(d,e,f);return $CLJS.n(f)?$CLJS.LV.l(d,e,Vsa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.w5=function(){function a(d,e,f){try{return E5(d,e,f,function(u,v){return $CLJS.Be($CLJS.t0(function(x){return!($CLJS.E.g($CLJS.TD.h(x),v)||Zsa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.Nl(k)),m=$CLJS.J.g(l,$CLJS.BK),t=$CLJS.J.g(l,$CLJS.Cx);l=$CLJS.J.g(l,$CLJS.GV);if($CLJS.E.g(t,$CLJS.FV))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.H5=function(){function a(d,e,f,k){return null==k?$CLJS.w5.j(d,e,f):E5(d,e,f,function(l,m){return $CLJS.sk.g(function(t){return $CLJS.E.g($CLJS.TD.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();