var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var C7,E7,G7,Ata,I7,J7,Bta;C7=function(a){if("string"===typeof a)return $CLJS.dh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.dh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.Pl(a));};$CLJS.D7=function(a){return $CLJS.Ua(a)?$CLJS.Ly(a,$CLJS.H([$CLJS.li,!0])):a};
E7=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=E7[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=E7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.setting",a);}return a};$CLJS.F7=function(a,b){return E7($CLJS.YZ(a),b)};G7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.Uh("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.Di,"ceil"],null));a=$CLJS.Rd(a);return 1<a?a:1};
Ata=function(a,b,c){var d=C7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.yz(d))/Math.log(10)));return $CLJS.Me(function(f){f*=e;return f>=d?f:null},$CLJS.fsa)};
$CLJS.H7=function(a,b,c){var d=$CLJS.d5(b);if($CLJS.n(d)){var e=$CLJS.AD.h(d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "num-bins":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OM,$CLJS.Xi,$CLJS.vj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.vi);var f=$CLJS.J.g(a,$CLJS.Ij);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.iD);a=Ata(e,f,a);return new $CLJS.h(null,3,[$CLJS.zD,a,$CLJS.DK,c,$CLJS.GK,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.zD),new $CLJS.h(null,
3,[$CLJS.zD,a,$CLJS.DK,c,$CLJS.GK,c+a],null);case "default":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OM,$CLJS.Xi,$CLJS.vj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.vi);f=$CLJS.J.g(d,$CLJS.Ij);a:switch(d=e,e=f,f=$CLJS.Oh instanceof $CLJS.M?$CLJS.Oh.S:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iD,new $CLJS.h(null,2,[$CLJS.iD,null,$CLJS.zD,C7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zD,new $CLJS.h(null,
2,[$CLJS.zD,null,$CLJS.iD,G7(d,e,null)],null)],null);break a;case "default":$CLJS.OZ(b)?(a=$CLJS.F7(a,$CLJS.esa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zD,new $CLJS.h(null,2,[$CLJS.zD,a,$CLJS.iD,G7(d,e,a)],null)],null)):(a=$CLJS.F7(a,$CLJS.dsa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iD,new $CLJS.h(null,2,[$CLJS.iD,a,$CLJS.zD,C7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.zD),new $CLJS.h(null,3,[$CLJS.zD,a,$CLJS.DK,c,$CLJS.GK,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};I7=function(a){var b=$CLJS.dz.h($CLJS.U0(a));b=b.h?b.h($CLJS.A7):b.call(null,$CLJS.A7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.od($CLJS.p4.g(b,-1))&&$CLJS.od($CLJS.N5.g(b,-1))){if(b=$CLJS.tk.j(b,$CLJS.oK,$CLJS.nd),!$CLJS.y($CLJS.oK.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
J7=function(a,b){var c=I7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.uU(b,new $CLJS.h(null,2,[$CLJS.EV,$CLJS.B7,$CLJS.JV,$CLJS.zta],null));var d=$CLJS.Q_,e=d.j,f=a;var k=a;var l=$CLJS.F1(k,-1);k=$CLJS.n(l)?$CLJS.AV(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.R1.v(a,-2,$CLJS.lV(b),d);if($CLJS.n(b))a=$CLJS.tk.j(a,$CLJS.oK,$CLJS.nd);else return null}};
Bta=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.d5(b))?function(){var f=$CLJS.L5(b,null);if(null!=c){var k=$CLJS.H7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.DK);l=$CLJS.J.g(l,$CLJS.GK);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.K5(f,k),$CLJS.J5(f,l)],null)}return null}return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.D1($CLJS.IE,new $CLJS.P(null,1,5,$CLJS.Q,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.B7.h(b);return $CLJS.n(f)?$CLJS.c3(b,f):b}();return new $CLJS.P(null,
1,5,$CLJS.Q,[(0,$CLJS.V2)(e,c)],null)}();return $CLJS.bb(function(e,f){return $CLJS.W2.j(e,-1,f)},a,d)};
$CLJS.K7=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.a6),d=$CLJS.J.g(b,$CLJS.EK);b=$CLJS.LV.l(a,-1,$CLJS.jk,$CLJS.H([$CLJS.XE,$CLJS.WE,$CLJS.JQ,$CLJS.fR,$CLJS.PD]));b=$CLJS.bb(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.Ei);l=$CLJS.J.g(l,$CLJS.jj);return Bta(f,k,l)},b,function(){return function l(k){return new $CLJS.ie(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);$CLJS.pk.g($CLJS.KJ.h($CLJS.Ei.h(A)),
$CLJS.hK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);if($CLJS.pk.g($CLJS.KJ.h($CLJS.Ei.h(v)),$CLJS.hK))return $CLJS.ae(v,l($CLJS.Hc(m)));m=$CLJS.Hc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.ed(c);return $CLJS.n(f)?$CLJS.pG(function(k){return $CLJS.E.g($CLJS.m5(k),f)},$CLJS.p4.g(a,-1)):null}();return $CLJS.bb(function(f,k){return $CLJS.W2.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ed(e)],null);case "metric":return $CLJS.X2.g($CLJS.R.j($CLJS.SX.h((0,$CLJS.xX)($CLJS.C1($CLJS.D7(new $CLJS.h(null,2,[$CLJS.Xi,$CLJS.DE,$CLJS.DE,$CLJS.D7($CLJS.BY.h($CLJS.M3(a,$CLJS.ed(e))))],null)),$CLJS.Xi,$CLJS.DE))),$CLJS.QO,$CLJS.QO.h(a)),-1);default:return null}}():null)};$CLJS.V5.m(null,$CLJS.T6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.N6);b=$CLJS.J.g(b,$CLJS.JY);return new $CLJS.h(null,3,[$CLJS.Xi,$CLJS.T6,$CLJS.N6,a,$CLJS.JY,b],null)});
$CLJS.U5.m(null,$CLJS.T6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.K7(I7(c),$CLJS.tk.j(e,$CLJS.EK,function(f){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.vd(u)){var v=$CLJS.ic(u),x=$CLJS.D(v),A=$CLJS.le(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.hd(v,
G);$CLJS.pe(A,$CLJS.tk.j(K,$CLJS.Ei,function(){return function(S){return J7(c,S)}}(G,K,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.oe($CLJS.qe(A),m($CLJS.jc(u))):$CLJS.oe($CLJS.qe(A),null)}var C=$CLJS.z(u);return $CLJS.ae($CLJS.tk.j(C,$CLJS.Ei,function(){return function(G){return J7(c,G)}}(C,u,t)),m($CLJS.Hc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,f,c)};a.l=b;return a}());