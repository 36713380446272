var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var DU,FU,GU,yma,KU,LU,MU,Ama,NU,OU,PU,RU,SU,TU,UU,VU,WU,YU,ZU,$U,aV,bV,eV,fV,zma;DU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.rk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.he(c)}),a)};
$CLJS.EU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ne.g($CLJS.xB,function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v);$CLJS.pe(t,$CLJS.FD(x,function(){return function(A){return $CLJS.jk.l(DU(A),$CLJS.tA,$CLJS.H([$CLJS.qi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),e($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.FD(u,function(){return function(v){return $CLJS.jk.l(DU(v),$CLJS.tA,$CLJS.H([$CLJS.qi]))}}(u,k,f,b)),e($CLJS.Hc(k)))}return null}},null,null)}(a)}())};
FU=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.md(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.nd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(X,U,da,oa,Oa){return function(rb){var Ra=$CLJS.I(rb,0,null);rb=$CLJS.I(rb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yd.g(Oa,Ra),rb],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Yd.g(c,l)):a=$CLJS.sd(k)?a(k):$CLJS.rd(k)?a($CLJS.Jl($CLJS.Ar,k)):m}else return c}};GU=function(a,b,c){return $CLJS.ud(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Cd(b,$CLJS.J.g(c,2))};$CLJS.HU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.qk.g($CLJS.oD,$CLJS.dd)),$CLJS.VO.h(a));return FU($CLJS.jk.l(a,$CLJS.zM,$CLJS.H([$CLJS.jU])),function(c){return GU($CLJS.qA,b,c)})};
$CLJS.IU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.qk.g($CLJS.wD,$CLJS.dd)),$CLJS.XE.h(a));return FU($CLJS.jk.l(a,$CLJS.zM,$CLJS.H([$CLJS.jU])),function(c){return GU($CLJS.XE,b,c)})};yma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.zM.h(d)]))}function c(d){return $CLJS.ae($CLJS.TD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.oK.h(d)])))}return $CLJS.n($CLJS.JU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
KU=function(a){if($CLJS.rd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.zz(m,yma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ta,function(S,X,U,da,oa,Oa){return function eb(Ra,Wa){try{if($CLJS.ud(Wa)&&3===$CLJS.D(Wa))try{var Ka=$CLJS.F(Wa,0);if($CLJS.ce(Ka,$CLJS.hF))try{var lb=$CLJS.F(Wa,1);if($CLJS.n($CLJS.pO.h(lb)))try{var Ja=$CLJS.F(Wa,1);if(null!=Ja?Ja.C&256||$CLJS.yc===Ja.mf||(Ja.C?0:$CLJS.Xa($CLJS.ub,
Ja)):$CLJS.Xa($CLJS.ub,Ja))try{var bc=$CLJS.J.j(Ja,$CLJS.pO,$CLJS.yU);if($CLJS.n($CLJS.Oe(da)(bc))){var Kd=$CLJS.J.g(Ja,$CLJS.pO);$CLJS.F(Wa,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Oa),": no join named ",$CLJS.xh.l($CLJS.H([Kd]))].join("")],null)}throw $CLJS.Z;}catch(gd){if(gd instanceof Error){var Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;
}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)return $CLJS.vU(eb,Ra,Wa);throw Hd;}throw gd;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.jk.l(C,$CLJS.zM,$CLJS.H([zma]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};LU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);MU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Ama=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);NU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
OU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);PU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.QU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);RU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
SU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);TU=new $CLJS.r(null,"refs","refs",80480079,null);UU=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);VU=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);WU=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.XU=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);YU=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);ZU=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);$U=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);aV=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
bV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.cV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.JU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.dV=new $CLJS.M(null,"filters","filters",974726919);eV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);fV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.gV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);zma=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.Y(UU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.XU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eP,$CLJS.Ta],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,$CLJS.hl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.Br,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bU],null)],null)],null));$CLJS.Y(ZU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null));
$CLJS.Y(LU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),ZU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Breakouts must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.EU},MU,$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.nU,NU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[TU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.EU)?$CLJS.EU.H:null]))],null)],null));
$CLJS.Y($U,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,":fields must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.EU},MU,$CLJS.zg([$CLJS.Ji,$CLJS.T,$CLJS.Pj,$CLJS.fi,$CLJS.Ei,$CLJS.Yi,$CLJS.ak,$CLJS.ei,$CLJS.Jj,$CLJS.Oj],[$CLJS.nU,NU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,
1,5,$CLJS.Q,[TU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.EU)?$CLJS.EU.H:null]))],null)],null));$CLJS.Y(aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.FE],null),$CLJS.Hj,$CLJS.Cj],null)],null));
$CLJS.Y(YU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.vi,1],null),aV],null));
var hV=$CLJS.zz(function(a){a=$CLJS.z($CLJS.HU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.IU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.Y(bV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,2,[$CLJS.us,"Valid references for a single query stage",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.jj);return hV(a)}],null),$CLJS.Oe(hV)],null));
$CLJS.Y($CLJS.gV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.QU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),LU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$U],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dV,new $CLJS.h(null,1,[$CLJS.Br,!0],null),YU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uK],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JU,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TJ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Cd(a,$CLJS.DP)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,
new $CLJS.h(null,1,[$CLJS.us,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.dD($CLJS.aO,$CLJS.JU))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bV],null)],null));$CLJS.Y(OU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null)],null)],null)],null));
$CLJS.Y(PU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TJ],null)],null)],null)],null));$CLJS.Y(SU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PU],null)],null));
$CLJS.Y(VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.zz($CLJS.aO,$CLJS.JU))],null)],null));$CLJS.Y(WU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lj,$CLJS.XU,$CLJS.QU],null));
$CLJS.Y(Ama,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,WU],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.Ai,$CLJS.kj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gV],null)],null)],null)],null));
$CLJS.Y(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,WU],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.Ai,$CLJS.kj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SU],null)],null)],null)],null));$CLJS.Y(RU,VU);
$CLJS.Y(eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,2,[$CLJS.us,"Valid references for all query stages",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.jj);return KU.h?KU.h(a):KU.call(null,a)}],null),$CLJS.Oe(KU)],null));
$CLJS.Y($CLJS.kK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RU],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eV],null)],null));
$CLJS.Y($CLJS.HK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.cV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.UJ,$CLJS.$J],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kK],null)],null)],null),$CLJS.qma],null));