var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var v0,xpa,w0,x0;$CLJS.t0=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.cc(c,d):c},$CLJS.ac($CLJS.xf),b))};v0=function(a){return $CLJS.n(u0)?["(",$CLJS.p.h(a),")"].join(""):a};xpa=function(a,b){var c=$CLJS.tk.v(a,$CLJS.VO,$CLJS.QC($CLJS.Yd,$CLJS.xf),b);return $CLJS.ud($CLJS.PD.h(a))?$CLJS.tk.v(c,$CLJS.PD,$CLJS.Yd,$CLJS.kV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.N,$CLJS.tV(b)],null))):c};
w0=function(a,b,c){var d=$CLJS.tV(c);return $CLJS.R.l($CLJS.e_.j(a,b,c),$CLJS.KJ,$CLJS.AY,$CLJS.H([$CLJS.T,d,$CLJS.kD,d]))};x0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.y0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var z0=function(){function a(d,e,f){var k=$CLJS.AV(d,e);k=$CLJS.pG($CLJS.qk.g($CLJS.Tg([f]),$CLJS.tV),$CLJS.VO.h(k));if($CLJS.n(k))return k;throw $CLJS.Uh($CLJS.fD("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.VQ,f,$CLJS.DE,d,$CLJS.BK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.L_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=z0.j(a,b,c);return $CLJS.M_.j(a,b,c)});$CLJS.N_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.kj,$CLJS.IJ,$CLJS.y0,$CLJS.wD.h(d),$CLJS.T,e,$CLJS.oD,e,$CLJS.kD,$CLJS.g_.j(a,b,c),$CLJS.tA,$CLJS.M_.j(a,b,c),$CLJS.KJ,$CLJS.AY],null)});$CLJS.H_.m(null,$CLJS.lA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.H_.m(null,$CLJS.jA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.H_.m(null,$CLJS.kA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.H_.m(null,$CLJS.yA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.H_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.I_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var u0=!1,ypa=new $CLJS.h(null,4,[$CLJS.Pq,"+",$CLJS.$r,"-",$CLJS.Qq,"×",$CLJS.BF,"÷"],null),A0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pq,$CLJS.$r,$CLJS.BF,$CLJS.Qq],null)),B0=null,C0=0,D0=0;;)if(D0<C0){var zpa=B0.X(null,D0);$CLJS.nE(zpa,x0);D0+=1}else{var E0=$CLJS.y(A0);if(E0){var F0=E0;if($CLJS.vd(F0)){var G0=$CLJS.ic(F0),Apa=$CLJS.jc(F0),Bpa=G0,Cpa=$CLJS.D(G0);A0=Apa;B0=Bpa;C0=Cpa}else{var Dpa=$CLJS.z(F0);$CLJS.nE(Dpa,x0);A0=$CLJS.B(F0);B0=null;C0=0}D0=0}else break}
$CLJS.H_.m(null,x0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(ypa,c);a:{var e=u0;u0=!0;try{var f=$CLJS.is([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Xs($CLJS.g_,a,b),d));break a}finally{u0=e}f=void 0}return v0(f)});$CLJS.I_.m(null,x0,function(){return"expression"});
$CLJS.L_.m(null,$CLJS.wG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Md($CLJS.pE,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.M_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae($CLJS.M_.j(a,
b,u),k($CLJS.Hc(l)))}return null}},null,null)}(d)}())});$CLJS.H_.m(null,$CLJS.bB,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.g_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.n0.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.yz(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=v0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.I_.m(null,$CLJS.bB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.J_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.n0.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.yz(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.H_.m(null,$CLJS.kF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.g_.v(a,b,e,d)});$CLJS.I_.m(null,$CLJS.kF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.J_.j(a,b,d)});
$CLJS.Epa=function(){function a(d,e,f,k){return $CLJS.LV.l(d,$CLJS.n(e)?e:-1,xpa,$CLJS.H([$CLJS.uV($CLJS.mV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Fpa=function(){function a(d,e){var f=$CLJS.Be($CLJS.VO.h($CLJS.AV(d,e)));return null==f?null:$CLJS.sk.g($CLJS.Xs(w0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.H0=function(){function a(d,e){return $CLJS.Be($CLJS.VO.h($CLJS.AV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.jV.m(null,$CLJS.qA,function(a){return a});
$CLJS.Gpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Jl(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tV(t),m],null)},$CLJS.H0.g(d,e))),l=$CLJS.AV(d,e);d=$CLJS.T_.j(d,e,l);return $CLJS.Be($CLJS.t0(function(m){return $CLJS.Sa(f)||$CLJS.pk.g($CLJS.KJ.h(m),$CLJS.AY)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Hpa=function(){function a(d,e,f){return $CLJS.lV(w0(d,e,z0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.I0=$CLJS.kE.h($CLJS.VD);