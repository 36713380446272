var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var A1,Upa,J1,K1,M1,Vpa,Wpa,Xpa,Ypa,Zpa,aqa,bqa,cqa,$pa,P1,Q1;A1=function(a,b){var c=$CLJS.Bd,d=z1;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.z(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};
$CLJS.B1=function(a){return function(b){var c=$CLJS.Ve(-1);return function(){function d(l,m){var t=c.nd(null,c.Lb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
$CLJS.C1=function(a,b,c){return null==c||$CLJS.Cd(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.D1=function(a,b){return $CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.wD,$CLJS.p.h($CLJS.GD())],null)],null),$CLJS.cf.h($CLJS.mV),b)};$CLJS.E1=function(a,b){return $CLJS.sV(a)&&$CLJS.E.g($CLJS.z(a),b)};$CLJS.F1=function(a,b){a=$CLJS.zV(a,b);return 0<a?a-1:null};
$CLJS.G1=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.z(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.z(b)),a,$CLJS.p.h($CLJS.dd(b))].join(""):[$CLJS.is(", ",$CLJS.fs(b)),",",a,$CLJS.p.h($CLJS.ed(b))].join("")}return null};$CLJS.H1=function(a){return $CLJS.JU.h($CLJS.z($CLJS.oK.h(a)))};
$CLJS.I1=function(a,b,c,d){a=$CLJS.yV(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.AV(a,b);e=!($CLJS.y($CLJS.XE.h(e))||$CLJS.y($CLJS.WE.h(e)));c=$CLJS.LV.l(a,b,$CLJS.tk,$CLJS.H([c,function(f){return $CLJS.Yd.g($CLJS.yf(f),$CLJS.mV.h(d))}]));return e?$CLJS.tk.M((0,$CLJS.LV)(c,b,function(f){return $CLJS.OV($CLJS.jk.l(f,$CLJS.JQ,$CLJS.H([$CLJS.PD])),$CLJS.zM,function(k){return $CLJS.sk.g(function(l){return $CLJS.jk.g(l,$CLJS.PD)},k)})}),$CLJS.oK,$CLJS.qk.g(function(f){return $CLJS.Wf.g($CLJS.xf,f)},$CLJS.xk),0,
$CLJS.zV(a,b)+1):c};Upa=function(a,b){b=$CLJS.a_(a,b);return $CLJS.n(b)?$CLJS.l1(a,b):null};J1=function(a){return $CLJS.Wf.j($CLJS.Sg,$CLJS.rk.h(function(b){return $CLJS.he(b)&&$CLJS.pk.g(b,$CLJS.kj)}),$CLJS.Ng(a))};K1=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ml(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.XE.h(a))};
$CLJS.L1=function(a,b,c){var d=$CLJS.Jk.l;b=$CLJS.Sa($CLJS.F1(a,b))?function(){var f=$CLJS.H1(a);return $CLJS.n(f)?(f=Upa(a,f),$CLJS.n(f)?$CLJS.pG(function(k){return $CLJS.E.g($CLJS.Mi.h(k),c)},f):null):null}():null;try{var e=$CLJS.V0(a,c)}catch(f){e=null}return d.call($CLJS.Jk,$CLJS.H([b,e]))};
M1=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.pO);d=$CLJS.J.g(d,$CLJS.AM);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.WJ.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.zz($CLJS.aZ,$CLJS.VY)(b),c)};
Vpa=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Be($CLJS.ff(function(e){var f=$CLJS.E.g($CLJS.GY.h(e),c);return f?M1(a,e):f},b));return $CLJS.n(d)?d:$CLJS.ff(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?M1(a,e):f},b)};
Wpa=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Be($CLJS.ff(function(k){var l=$CLJS.E.g($CLJS.Mi.h(k),e);if(l){if(l=$CLJS.Sa($CLJS.pO.h(d))){l=$CLJS.KJ.h(k);var m=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.KY,null,$CLJS.PY,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:M1(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Be($CLJS.ff(function(k){return $CLJS.E.g($CLJS.Mi.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.xf};
Xpa=function(a){var b=$CLJS.N1.g($CLJS.KJ.h(a),$CLJS.AY);return $CLJS.n(b)?b:$CLJS.oD.h(a)};
Ypa=function(a,b){b=$CLJS.Be($CLJS.rk.g($CLJS.WJ,b));if($CLJS.n(b))if($CLJS.Sa($CLJS.B(b)))a=$CLJS.z(b);else{if($CLJS.n($CLJS.N1.g($CLJS.z(a),$CLJS.hF))){var c=$CLJS.Be($CLJS.rk.g(Xpa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.z(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.Uh("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.xV,b],null)))}else a=null;return a};
Zpa=function(a,b){b=$CLJS.Be($CLJS.rk.g(function(c){var d=$CLJS.zz($CLJS.aZ,$CLJS.VY)(c);$CLJS.n(d)?(c=$CLJS.KJ.h(c),d=new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.KY,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Sa(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Sa($CLJS.B(b))?$CLJS.z(b):Ypa(a,b):null};
aqa=function(a,b){var c=$CLJS.DD(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.pO);return $CLJS.n(d)?(b=$CLJS.Be($CLJS.ff(function(e){return $CLJS.E.g($CLJS.zz($CLJS.aZ,$CLJS.VY)(e),d)},b)),$CLJS.n(b)?$CLJS.Sa($CLJS.B(b))?$CLJS.z(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,$pa,b],null)):null):Zpa(a,b)};bqa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
cqa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.O1=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.dqa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);$pa=new $CLJS.M(null,"matches","matches",635497998);P1=new $CLJS.M(null,"left","left",-399115937);Q1=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.N1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ui,$CLJS.gB],null),$CLJS.Ui,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.XA(f);k=$CLJS.XA(k);return $CLJS.pk.g(f,k)?Q1:f},e,a,b,c,d)}();$CLJS.N1.m(null,Q1,function(){return!1});
$CLJS.N1.m(null,$CLJS.pA,function(a,b){var c=J1(a),d=J1(b);return $CLJS.E.g(c,d)&&$CLJS.Le(function(e){return $CLJS.N1.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});$CLJS.N1.m(null,$CLJS.nA,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.z(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.z(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.z(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.z(a);a=$CLJS.B(a);c=$CLJS.N1.g(c,d);if($CLJS.n(c)){if(c=$CLJS.od(b))return c;c=b}else return c}else return c});
var z1=null;$CLJS.N1.m(null,$CLJS.QU,function(a,b){var c=z1;z1=new $CLJS.h(null,2,[P1,K1(a),$CLJS.uw,K1(b)],null);try{var d=$CLJS.iH($CLJS.N1,$CLJS.pA);return d.g?d.g(a,b):d.call(null,a,b)}finally{z1=c}});
$CLJS.N1.m(null,$CLJS.XE,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=$CLJS.N1.g(d,k),$CLJS.n(d)?$CLJS.n(z1)?$CLJS.E.g(A1(new $CLJS.P(null,2,5,$CLJS.Q,[P1,e],null),cqa),A1(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uw,l],null),bqa)):$CLJS.E.g(e,l):d):c:a});
$CLJS.N1.m(null,$CLJS.Oh,function(a,b){if($CLJS.sd(a)){var c=$CLJS.iH($CLJS.N1,$CLJS.pA);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.rd(a)?(c=$CLJS.iH($CLJS.N1,$CLJS.nA),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.R1=function(){function a(f,k,l,m,t){var u=$CLJS.sV(l)?l:$CLJS.lV(l),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null),A=$CLJS.I(u,2,null),C=e.j?e.j(u,m,t):e.call(null,u,m,t);if($CLJS.n(C))return C;C=$CLJS.n(function(){var K=$CLJS.N1.g(v,$CLJS.XE);return $CLJS.n(K)?$CLJS.xK.h(x):K}())?$CLJS.pG(function(K){var S=$CLJS.N1.g($CLJS.KJ.h(K),$CLJS.hK);return $CLJS.n(S)?$CLJS.N1.g($CLJS.T.h(K),$CLJS.xK.h(x)):S},m):null;if($CLJS.n(C))return C;if($CLJS.n($CLJS.n(f)?"number"===typeof A:f)&&(m=$CLJS.Be($CLJS.rk.g($CLJS.Mi,
m)),$CLJS.n(m))){var G=$CLJS.sV(l)?$CLJS.L1(f,k,A):l;if($CLJS.n(G))return f=$CLJS.FD($CLJS.R.j(u,2,function(){var K=$CLJS.GY.h(G);return $CLJS.n(K)?K:$CLJS.T.h(G)}()),$CLJS.Qe($CLJS.Jk,new $CLJS.h(null,1,[$CLJS.tA,$CLJS.hj],null))),e.j?e.j(f,m,t):e.call(null,f,m,t)}return null}function b(f,k,l,m){return e.M?e.M(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.O1);switch(m instanceof $CLJS.M?
m.S:null){case "aggregation":return $CLJS.pG(function(u){return $CLJS.E.g($CLJS.KJ.h(u),$CLJS.hK)&&$CLJS.E.g($CLJS.y0.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?Vpa(f,k):Wpa(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.z(k);default:return aqa(f,k)}default:throw $CLJS.Uh("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,
f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.M=a;return e}();
$CLJS.eqa=function(){function a(d,e,f,k){return $CLJS.R1.v(d,e,$CLJS.nY.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();