var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var mha,nha,oha,pha,qha,rha,sha;$CLJS.QF=new $CLJS.M(null,"second","second",-444702010);$CLJS.RF=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.SF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.TF=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.UF=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
mha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);nha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.VF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.WF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.XF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);oha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
pha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);qha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);rha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.YF=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.ZF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.$F=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
sha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var aG=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ri,$CLJS.oj,$CLJS.mj,$CLJS.ci,$CLJS.Kj,$CLJS.mi,$CLJS.Wh,$CLJS.UF],null),bG=$CLJS.Wg(aG),tha,uha,vha;$CLJS.Y(qha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid date extraction unit"],null)],null),bG));var cG=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vj,$CLJS.Zi,$CLJS.ej,$CLJS.bi,$CLJS.Wh],null),dG=$CLJS.Wg(cG);
$CLJS.Y(pha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid date truncation unit"],null)],null),dG));$CLJS.eG=$CLJS.Wf.j($CLJS.xf,$CLJS.Kk.o(),$CLJS.bf.g(cG,aG));tha=$CLJS.Wg($CLJS.eG);$CLJS.Y($CLJS.XF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid date bucketing unit"],null)],null),tha));var fG=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.Vi,$CLJS.gi],null),gG=$CLJS.Wg(fG);
$CLJS.Y(oha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid time extraction unit"],null)],null),gG));var hG=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.TF,$CLJS.QF,$CLJS.Fi,$CLJS.Rj],null),iG=$CLJS.Wg(hG);$CLJS.Y(sha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid time truncation unit"],null)],null),iG));$CLJS.jG=$CLJS.Wf.j($CLJS.xf,$CLJS.Kk.o(),$CLJS.bf.g(hG,fG));uha=$CLJS.Wg($CLJS.jG);
$CLJS.Y(nha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid time bucketing unit"],null)],null),uha));$CLJS.kG=$CLJS.Wf.j($CLJS.xf,$CLJS.Kk.o(),$CLJS.bf.l(hG,cG,$CLJS.H([fG,aG])));vha=$CLJS.Wg($CLJS.kG);$CLJS.Y($CLJS.SF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime bucketing unit"],null)],null),$CLJS.kG));var wha=$CLJS.Yd.g(vha,$CLJS.Oh);
$CLJS.Y($CLJS.ZF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid temporal bucketing unit"],null)],null),wha));$CLJS.lG=$CLJS.Ws.g(dG,iG);$CLJS.Y($CLJS.VF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime truncation unit"],null)],null),$CLJS.lG));$CLJS.mG=$CLJS.Ws.g(bG,gG);$CLJS.Y(mha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime extraction unit"],null)],null),$CLJS.mG));
var nG=$CLJS.Yd.g(dG,$CLJS.Wh);$CLJS.Y($CLJS.$F,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid date interval unit"],null)],null),nG));$CLJS.Y($CLJS.WF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid time interval unit"],null)],null),iG));var xha=$CLJS.Ws.g(nG,iG);$CLJS.Y($CLJS.cD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime interval unit"],null)],null),xha));
$CLJS.Y(rha,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.YF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,$CLJS.ZF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null));