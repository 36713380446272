var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var qG,rG,tG,uG,yha,zha,Aha,vG,sG;$CLJS.oG=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.J.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.pG=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
qG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.Ok)?$CLJS.$F:$CLJS.Dz(b,$CLJS.Tk)?$CLJS.WF:$CLJS.Dz(b,$CLJS.Qk)?$CLJS.cD:null;return $CLJS.n(b)?$CLJS.mE(b,a):!0};
rG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.oG(function(d){return $CLJS.Dz($CLJS.KD(d),$CLJS.aC)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.pk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.KD($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(qG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
tG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.us,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.Fs,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.jj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(rG(b))].join("")}],null),$CLJS.Oe(rG)],null)],null)};
uG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nj,new $CLJS.h(null,1,[$CLJS.us,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.vi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)],null)],null)};
yha=function(a){return $CLJS.Md($CLJS.pE,$CLJS.cf.g(function(b){var c=$CLJS.KD(b),d=$CLJS.Dz(c,$CLJS.WD);b=d?$CLJS.mE($CLJS.lF,b):d;return $CLJS.n(b)?$CLJS.vj:c},a))};zha=function(a){a=$CLJS.pG(function(b){return!$CLJS.Dz(b,$CLJS.aC)},$CLJS.cf.g($CLJS.KD,a));return $CLJS.Dz(a,$CLJS.WD)?$CLJS.Aj:a};
Aha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.KD(c),$CLJS.aC)},b))?zha(b):$CLJS.E.g(a,$CLJS.$r)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.KD(c),$CLJS.Ok)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.KD(c),$CLJS.Qk)},b))?$CLJS.aC:yha(b)};vG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.wG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);sG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,sG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.KD(a);return $CLJS.Le(function(d){return qG(d,c)},b)}],null)],null));
$CLJS.Y(vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.vi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)],null));
var Bha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.nj,new $CLJS.h(null,1,[$CLJS.us,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.$r],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)],
null);$CLJS.GF.g($CLJS.Pq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,tG($CLJS.Pq),uG($CLJS.Pq)],null));$CLJS.GF.g($CLJS.$r,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,tG($CLJS.$r),Bha,uG($CLJS.$r)],null));$CLJS.wE($CLJS.Qq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,vG],null)]));$CLJS.wE($CLJS.BF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,vG],null)]));
for(var xG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pq,$CLJS.$r,$CLJS.Qq],null)),yG=null,zG=0,AG=0;;)if(AG<zG){var Cha=yG.X(null,AG);$CLJS.nE(Cha,$CLJS.wG);AG+=1}else{var BG=$CLJS.y(xG);if(BG){var CG=BG;if($CLJS.vd(CG)){var DG=$CLJS.ic(CG),Dha=$CLJS.jc(CG),Eha=DG,Fha=$CLJS.D(DG);xG=Dha;yG=Eha;zG=Fha}else{var Gha=$CLJS.z(CG);$CLJS.nE(Gha,$CLJS.wG);xG=$CLJS.B(CG);yG=null;zG=0}AG=0}else break}
$CLJS.JD.m(null,$CLJS.wG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return Aha(a,b)});$CLJS.uE($CLJS.HE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.nE($CLJS.HE,$CLJS.$D);
for(var EG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.yE,$CLJS.VE],null)),FG=null,GG=0,HG=0;;)if(HG<GG){var Hha=FG.X(null,HG);$CLJS.uE(Hha,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));HG+=1}else{var IG=$CLJS.y(EG);if(IG){var JG=IG;if($CLJS.vd(JG)){var KG=$CLJS.ic(JG),Iha=$CLJS.jc(JG),Jha=KG,Kha=$CLJS.D(KG);EG=Iha;FG=Jha;GG=Kha}else{var Lha=$CLJS.z(JG);$CLJS.uE(Lha,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));EG=$CLJS.B(JG);FG=null;GG=0}HG=0}else break}
for(var LG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,$CLJS.fF,$CLJS.mF],null)),MG=null,NG=0,OG=0;;)if(OG<NG){var Mha=MG.X(null,OG);$CLJS.uE(Mha,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));OG+=1}else{var PG=$CLJS.y(LG);if(PG){var QG=PG;if($CLJS.vd(QG)){var RG=$CLJS.ic(QG),Nha=$CLJS.jc(QG),Oha=RG,Pha=$CLJS.D(RG);LG=Nha;MG=Oha;NG=Pha}else{var Qha=$CLJS.z(QG);$CLJS.uE(Qha,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));LG=$CLJS.B(QG);MG=null;NG=0}OG=0}else break}$CLJS.uE($CLJS.EE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));
$CLJS.JD.m(null,$CLJS.EE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.KD(b),$CLJS.dj)&&$CLJS.Dz($CLJS.KD(a),$CLJS.dj)?$CLJS.dj:$CLJS.LC});