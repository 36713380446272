var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var NH,PH,RH,$H,cI,hI;$CLJS.MH=new $CLJS.M(null,"get-month","get-month",-369374731);NH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.OH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);PH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.QH=new $CLJS.M(null,"iso","iso",-1366207543);RH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.SH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.TH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.UH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.VH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.WH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.XH=new $CLJS.M(null,"us","us",746429226);$CLJS.YH=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.ZH=new $CLJS.M(null,"now","now",-1650525531);
$H=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.aI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.bI=new $CLJS.M(null,"get-day","get-day",127568857);cI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.dI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.eI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.fI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.gI=new $CLJS.M(null,"get-hour","get-hour",622714059);hI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.iI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.jI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.kI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.lI=new $CLJS.M(null,"target","target",253001721);$CLJS.uE($CLJS.ZA,$CLJS.H([$CLJS.$r,$CLJS.aC,$CLJS.ii,$CLJS.cD]));$CLJS.JD.m(null,$CLJS.VH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.KD(a);return $CLJS.qd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Ok,null,$CLJS.Qk,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.nE($CLJS.VH,$CLJS.$D);
for(var mI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bB,$CLJS.eB],null)),nI=null,oI=0,pI=0;;)if(pI<oI){var qI=nI.X(null,pI);$CLJS.uE(qI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null),$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.nE(qI,$CLJS.VH);pI+=1}else{var rI=$CLJS.y(mI);if(rI){var sI=rI;if($CLJS.vd(sI)){var tI=$CLJS.ic(sI),yia=$CLJS.jc(sI),zia=tI,Aia=$CLJS.D(tI);mI=yia;nI=zia;oI=Aia}else{var uI=$CLJS.z(sI);$CLJS.uE(uI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.SD],null),$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.nE(uI,$CLJS.VH);mI=$CLJS.B(sI);nI=null;oI=0}pI=0}else break}
for(var vI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.TH,$CLJS.MH,$CLJS.bI,$CLJS.gI,$CLJS.dI,$CLJS.SH,$CLJS.iI],null)),wI=null,xI=0,yI=0;;)if(yI<xI){var Bia=wI.X(null,yI);$CLJS.uE(Bia,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)]));yI+=1}else{var zI=$CLJS.y(vI);if(zI){var AI=zI;if($CLJS.vd(AI)){var BI=$CLJS.ic(AI),Cia=$CLJS.jc(AI),Dia=BI,Eia=$CLJS.D(BI);vI=Cia;wI=Dia;xI=Eia}else{var Fia=$CLJS.z(AI);$CLJS.uE(Fia,$CLJS.H([$CLJS.$r,
$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)]));vI=$CLJS.B(AI);wI=null;xI=0}yI=0}else break}$CLJS.uE($CLJS.YA,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VF],null)]));
for(var CI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.iI,null,$CLJS.dI,null,$CLJS.gI,null,$CLJS.eI,null,$CLJS.SH,null,$CLJS.MH,null,$CLJS.TH,null,$CLJS.bI,null],null),null)),DI=null,EI=0,FI=0;;)if(FI<EI){var Gia=DI.X(null,FI);$CLJS.uE(Gia,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)]));FI+=1}else{var GI=$CLJS.y(CI);if(GI){var HI=GI;if($CLJS.vd(HI)){var II=$CLJS.ic(HI),Hia=$CLJS.jc(HI),Iia=II,Jia=$CLJS.D(II);
CI=Hia;DI=Iia;EI=Jia}else{var Kia=$CLJS.z(HI);$CLJS.uE(Kia,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)]));CI=$CLJS.B(HI);DI=null;EI=0}FI=0}else break}$CLJS.Y(hI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Lj,$CLJS.QH,$CLJS.XH,$CLJS.aI],null));
$CLJS.wE($CLJS.aB,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ki,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.fI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.pD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.h(null,2,[$CLJS.us,"valid timezone ID",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.jj);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Dd,$CLJS.LH.tz.names())),$CLJS.CH],null)],null));
$CLJS.wE($CLJS.OH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null)],null)],null)],
null)]));$CLJS.nE($CLJS.OH,$CLJS.VH);$CLJS.uE($CLJS.ZH,$CLJS.H([$CLJS.$r,$CLJS.Pk]));$CLJS.Y(RH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,$CLJS.Qk],null))}],null)],null));
$CLJS.Y(NH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RH],null)],null)],null)],null));
$CLJS.GF.g($CLJS.UH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nj,new $CLJS.h(null,1,[$CLJS.us,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.UH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nj,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nj,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SF],null)],null)],null)],null)],null)],null));
$CLJS.JD.m(null,$CLJS.UH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Qk:$CLJS.n($CLJS.mE($CLJS.XF,a))?$CLJS.Ok:$CLJS.Qk:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.wH,b))?$CLJS.Ok:$CLJS.n($CLJS.dh($CLJS.xH,b))?$CLJS.Ok:null:null;if($CLJS.n(a))return a;b=$CLJS.KD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.Aj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(cI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Qu],null),$CLJS.ii],null));
$CLJS.wE($CLJS.$A,$CLJS.H([$CLJS.$r,$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)],null)],null)],null)]));
$CLJS.uE($CLJS.Fx,$CLJS.H([$CLJS.$r,$CLJS.Tk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WF],null)]));$CLJS.Y(PH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Lj,$CLJS.UF,$CLJS.mi,$CLJS.Kj,$CLJS.WH,$CLJS.jI,$CLJS.kI,$CLJS.oj,$CLJS.ri,$CLJS.gi,$CLJS.Vi,$CLJS.RF],null));$CLJS.Y($H,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Lj,$CLJS.QH,$CLJS.XH,$CLJS.aI],null));
$CLJS.wE($CLJS.cB,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ki,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$H],null)],null)],
null)],null)]));